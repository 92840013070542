import { Component } from "@angular/core";
import { OnInit } from "../../../ng";

@Component({
  selector: "main-menu",
  templateUrl: "./main-menu.component.html",
  styleUrls: ["./main-menu.component.scss"],
})
export class MainMenuComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
