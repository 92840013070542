import { Component } from "@angular/core";
import {
  ActionLinksPanelService,
  ActionLink,
  ClientSessionService,
} from "../../../services";

@Component({
  selector: "action-links-panel",
  templateUrl: "./action-links-panel.component.html",
  styleUrls: ["./action-links-panel.component.scss"],
})
export class ActionLinksPanelComponent {
  constructor(
    public actionLinksPanelService: ActionLinksPanelService,
    private clientSessionService: ClientSessionService,
  ) {}

  hideActionLinks() {
    this.actionLinksPanelService.reset();
  }

  onLinkClicked($index: number) {
    var item = this.actionLinksPanelService.actionLinks[$index];
    if (item.onClick !== undefined) {
      item.onClick();
    }
  }

  shouldUseReallyClick($index: number) {
    var item = this.actionLinksPanelService.actionLinks[$index];
    var hasButtons = item.reallyClickButtons != null;
    var hasAction = item.reallyClickAction != null;
    var hasCaller = item.reallyClickCaller != null;
    var hasMessage = item.reallyClickMessage != null;

    return hasMessage || hasAction;
  }
}
