import { Component } from "@angular/core";
import { OnInit } from "../../../ng";
import { ControllerWithCommandServiceBase } from "../controller-with-command-service-base";
import { PageContainerService } from "../../../services";
import {
  AuthenticationType,
  AuthenticationTypeDisplayMap,
} from "../../../data/CommandServiceDtos.generated";
import { ObjectUtility } from "../../../utilities/objects/object.utility";
import * as config from "../../../../app-config.json";

@Component({
  selector: "header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  releaseId: string = "";
  releaseUrl: string;
  constructor(public pcs: PageContainerService) {}

  async ngOnInit() {
    let baseReleaseUrl: string = "TEMPURL-LOCAL-SPARKITECTURE-TEST";
    let response = await this.pcs.apiService.GetReleaseNumber({}, false, false);
    this.releaseId = response.ReleaseId;
    let releaseIdLastPeriodIndex: number = this.releaseId.lastIndexOf(".");
    this.releaseUrl =
      releaseIdLastPeriodIndex !== -1
        ? baseReleaseUrl + this.releaseId.substr(releaseIdLastPeriodIndex + 1)
        : null;
  }

  get loggedIn(): boolean {
    var result = this.pcs.clientSessionService.loggedIn;
    return result;
  }

  navigateHome() {
    this.pcs.router.navigate(["dashboard"]);
  }

  logout() {
    this.pcs.apiService
      .Logout({}, true)
      .then((response) => this.onLogoutSucceeded());
  }

  onLogoutSucceeded() {
    this.pcs.clientSessionService.reset();
    if (
      config.security.authenticationType ===
        AuthenticationTypeDisplayMap[AuthenticationType.LoginActiveDirectory] ||
      config.security.authenticationType ===
        AuthenticationTypeDisplayMap[AuthenticationType.Database]
    ) {
      this.pcs.router.navigate(["login"]);
    } else if (
      config.security.authenticationType ===
      AuthenticationTypeDisplayMap[AuthenticationType.AzureActiveDirectory]
    ) {
      var guid = ObjectUtility.generateUUID();
      var encodedRedirect = encodeURIComponent(config.security.redirectUri);
      var redirectUri =
        "https://login.microsoftonline.com/336411f2-bdde-4ad6-bc95-f51feae5a2cf/oauth2/v2.0/authorize?response_type=code+id_token&redirect_uri=" +
        encodedRedirect +
        "&client_id=" +
        config.security.clientId +
        "&scope=openid+profile+email&response_mode=form_post&nonce=" +
        guid +
        "&state=redir%3D%252Flogin&sso_reload=true";
      var logoutUri =
        "https://login.microsoftonline.com/common/oauth2/logout?post_logout_redirect_uri=" +
        encodeURIComponent(redirectUri);
      window.location.href = logoutUri;
    }
  }

  get showLogout(): boolean {
    return (
      config.security.authenticationType !==
      AuthenticationTypeDisplayMap[AuthenticationType.AutoActiveDirectory]
    );
  }

  changePassword() {
    var id = +this.pcs.clientSessionService.userId;
    if (id > 0) this.pcs.router.navigate(["/change-password", id]);
  }
}
