import { Component, OnInit, Input } from "@angular/core";
import { CountWidgetDto } from "../../../../data/CommandServiceDtos.generated";
import { PageContainerService } from "../../../../services";

@Component({
  selector: "count-widget",
  templateUrl: "./count-widget.component.html",
  styleUrls: ["./count-widget.component.scss"],
})
export class CountWidgetComponent implements OnInit {
  @Input() model: CountWidgetDto;
  @Input() showSelect: boolean = false;

  constructor(public pcs: PageContainerService) {}

  ngOnInit() {}

  navigateToList() {
    this.pcs.router.navigate([this.model.Link]);
  }
}
