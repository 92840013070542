import { Component, ViewChild, AfterViewInit } from "@angular/core";
import { PageContainerService } from "../../../../services";
import { ControllerForViewBase } from "../../../framework/controller-for-view-base";
import {
  ExampleSearchRow,
  DeleteExampleCommandRequest,
} from "../../../../data/CommandServiceDtos.generated";
import { ILink } from "../../../../data";
import { SpkSearchGridComponent } from "../../../framework/spk/controls/spk-search-grid/spk-search-grid.component";

@Component({
  selector: "examples",
  templateUrl: "./examples.component.html",
  styleUrls: ["./examples.component.scss"],
})
export class ExamplesComponent
  extends ControllerForViewBase
  implements AfterViewInit
{
  @ViewChild("grid", { static: true }) grid: SpkSearchGridComponent;
  leftLinks: ILink[];
  rightLinks: ILink[];
  headerLinks: ILink[];

  constructor(public pcs: PageContainerService) {
    super(pcs);
    let links: ILink[] = [
      {
        text: "",
        action: (row) => this.openRow(row),
        title: "Open",
        icon: "folder-open",
        class: "btn-primary",
      },
      {
        text: "",
        action: (row) => this.openRow(row),
        reallyClickMessage: "Are you sure you want to delete the example?",
        reallyClickAction: "delete",
        reallyClickCaller: this,
        title: "Delete",
        icon: "trash",
        class: "btn-danger",
      },
    ];
    this.leftLinks = links;
    this.rightLinks = links;

    this.headerLinks = [
      {
        text: "Create",
        action: () => this.create(),
        icon: "plus",
        class: "btn-success",
      },
    ];

    this.pcs.actionLinksPanelService.addBackButton();
    this.pcs.actionLinksPanelService.addActionLinks([
      {
        text: "Create ",
        fontAwesomeIcon: "plus",
        onClick: () => {
          this.create();
        },
      },
    ]);
  }

  create(): void {
    this.pcs.router.navigate(["/example", 0]);
  }

  openRow(row: ExampleSearchRow): void {
    this.pcs.router.navigate(["/example", row.Id]);
  }

  async delete(row: ExampleSearchRow): Promise<void> {
    let deleteRequest: DeleteExampleCommandRequest =
      new DeleteExampleCommandRequest();
    deleteRequest.Id = row.Id;
    await this.pcs.apiService.DeleteExample(deleteRequest, undefined, true);
    this.grid.load();
  }
  ngAfterViewInit(): void {}

  ngOnInit() {}
}
