import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
  OnChanges,
  SimpleChanges,
  Optional,
} from "@angular/core";
import { PageContainerService } from "../../../services";
import {
  FilterSpecification,
  SearchCommandRequest,
  SortSpecification,
} from "../../../data/framework/searchObjects";
import * as _ from "lodash";
import * as $ from "jquery";
import { isNullOrUndefined } from "util";
import { ModalLookupComponent } from "../modal-lookup/modal-lookup.component";
import { Search, ISearchGridFormat, ILink } from "../../../data";
import { NgForm } from "../../../ng";
import { ControlContainer } from "@angular/forms";
import { ControlContainerFactory } from "../../../factories/control-container.factory";
import { SearchGridLoadStartedEventArgs } from "../../../data/framework/searchGridLoadStartedEventArgs";
import { SpkTextComponent } from "../spk/inputs/spk-text/spk-text.component";

@Component({
  selector: "auto-lookup-with-modal",
  templateUrl: "./auto-lookup-with-modal.component.html",
  styleUrls: ["./auto-lookup-with-modal.component.scss"],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: ControlContainerFactory,
      deps: [[new Optional(), NgForm]],
    },
  ],
})
export class AutoLookupWithModalComponent implements OnChanges {
  @Input() name: string;
  @Input() class: string = "";
  @Input() label: string;
  @Input() inputFormat: AutoLookupWithModalInputFormat[];
  @Input() list: string = "";
  @Input() title: string = "";
  @Input() sort: string = "";
  @Input() command: string = "";
  @Input() filter: FilterSpecification;
  @Input() rowFormats: ISearchGridFormat[] = [];
  @Input() leftLinks: ILink[] = [];
  @Input() showSearch: boolean = true;
  @Input() focusInputId: string = "";
  @Input() customSearchHasBeenApplied: boolean = true;
  @Input() autofocus: boolean = false;
  @Input() autoload: boolean = false;

  @Output() mousedOverChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() public loadStarting: EventEmitter<SearchGridLoadStartedEventArgs> =
    new EventEmitter<SearchGridLoadStartedEventArgs>();
  @Output() prepareForShowingModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() getCustomFilterAndSortRequested: EventEmitter<any> =
    new EventEmitter<any>();

  placeholder: string = "";
  inputValue: string = "";
  displayValue: string = "";
  searchValue: string = "";
  showingModal: boolean = false;

  @ViewChild("input") input: SpkTextComponent;
  @ViewChild("modalLookup") modalLookup: ModalLookupComponent;

  @Input() selectedResult: any;
  @Output() selectedResultChange: EventEmitter<any> = new EventEmitter<any>();

  container: ControlContainer;

  constructor(
    public pcs: PageContainerService,
    container: ControlContainer,
  ) {
    this.container = container;
  }

  ngOnChanges(changes: SimpleChanges) {
    for (let propName in changes) {
      if (propName === "selectedResult") {
        this.calculateDisplayValue();
      }
    }
  }

  async inputKeydown(value): Promise<boolean> {
    let result = true;
    if (
      value.keyCode === 45 ||
      (value.keyCode === 13 &&
        !this.inputValue &&
        !value.altKey &&
        !value.shiftKey &&
        !value.ctrlKey)
    ) {
      // 45 = insert key, 13 = enter key
      this.searchValue = this.inputValue;
      this.inputValue = "";
      result = false;
      setTimeout(() => {
        this.loadModalAndShow(this.searchValue.length > 0);
      });
    } else if (value.keyCode === 46) {
      // delete key
      this.selectedResult = null;
      this.selectedResultChange.emit(this.selectedResult);
      this.calculateDisplayValue();
    } else if (this.shouldOpenModalOnEnterOrTabKey(value)) {
      console.log("tabblur");
      this.searchValue = this.inputValue;
      result = await this.handleInput();
      if (result === true) {
        setTimeout(() => {
          let keyboardEvent = document.createEvent("KeyboardEvent");
          let initMethod =
            typeof keyboardEvent.initEvent !== "undefined"
              ? "initKeyboardEvent"
              : "initKeyEvent";

          keyboardEvent[initMethod](
            "keydown", // event type: keydown, keyup, keypress
            true, // bubbles
            true, // cancelable
            window, // view: should be window
            false, // ctrlKey
            false, // altKey
            false, // shiftKey
            false, // metaKey
            9, // keyCode: unsigned long - the virtual key code, else 0
            0, // charCode: unsigned long - the Unicode character associated with the depressed key, else 0
          );
          document.dispatchEvent(keyboardEvent);
        }, 200);
      }
      this.onBlur();
    }
    return result;
  }

  shouldOpenModalOnEnterOrTabKey(value) {
    let result = false;
    if (value.keyCode === 9 && this.inputValue) {
      // 9 = tab key, and we must have an input value
      result = true;
    }
    if (value.keyCode === 13) {
      // 13 = enter key
      result = true;
    }
    if (value.altKey || value.shiftKey || value.ctrlKey) {
      // no modifier key should be pressed
      result = false;
    }
    return result;
  }

  isWrapped() {
    return this.label !== undefined && this.label !== null && this.label !== "";
  }

  onFocus() {
    this.placeholder = this.displayValue;
    this.inputValue = "";
  }

  onBlur() {
    console.log("blur");
  }

  async handleInput(): Promise<boolean> {
    let keystrokeWasHandled = false;
    if (this.searchValue) {
      let adjustedSearchFilter = this.showSearch ? this.searchValue : null;
      let customSearchDetails = {
        customFilter: null,
        customSort: null,
      };
      this.getCustomFilterAndSortRequested.emit(customSearchDetails);
      let localFilter: FilterSpecification = this.filter
        ? this.filter
        : customSearchDetails.customFilter
        ? customSearchDetails.customFilter
        : new FilterSpecification();
      let localSort: SortSpecification = customSearchDetails.customSort
        ? customSearchDetails.customSort
        : { SortString: this.sort };
      let payload: SearchCommandRequest = {
        SearchType: this.list,
        SearchCriteria: {
          FilterSpecification: localFilter,
          SearchText: adjustedSearchFilter,
          PaginationSpecification: {
            PageIndex: 1,
            PageSize: 2,
          },
          SortSpecification: localSort,
        },
      };

      let response = await this.pcs.apiService.Search(payload, true, false);
      if (response.PagedResult.Data.length == 1) {
        this.resultSelected(response.PagedResult.Data[0]);
      } else if (!this.showingModal) {
        keystrokeWasHandled = true;
        this.loadModalAndShow(true);
      }
    } else {
      this.inputValue = this.displayValue;
    }
    return !keystrokeWasHandled;
  }

  loadModalAndShow(shouldLoad: boolean) {
    this.prepareForShowingModal.emit();
    this.showingModal = true;
    console.log("modalshow");
    if (shouldLoad) {
      this.modalLookup.load();
    }
    this.modalLookup.show();
  }

  resultSelected(row: any) {
    this.selectedResult = row;
    this.selectedResultChange.emit(this.selectedResult);
    this.calculateDisplayValue();
    this.modalClosed();
    if (this.container) this.container.control.markAsDirty();
  }

  calculateDisplayValue() {
    if (this.selectedResult) {
      let result = "";
      _.forEach(this.inputFormat, (element: AutoLookupWithModalInputFormat) => {
        if (!isNullOrUndefined(element.stringLiteralElement)) {
          result += element.stringLiteralElement;
        } else if (
          !isNullOrUndefined(element.columnNameElement) &&
          this.selectedResult.hasOwnProperty(element.columnNameElement) &&
          this.selectedResult[element.columnNameElement] != undefined &&
          this.selectedResult[element.columnNameElement] != null
        ) {
          result += this.selectedResult[element.columnNameElement].toString();
        } else if (!isNullOrUndefined(element.manualFormatter)) {
          result += element.manualFormatter(this.selectedResult);
          //result = "";
          //return false;
        } else {
          //result = "";
          //return false;
        }
      });
      this.displayValue = result;
      this.placeholder = result;
      this.inputValue = "";
    } else {
      this.displayValue = "";
      this.placeholder = "";
      this.inputValue = "";
    }
  }

  modalClosed() {
    this.showingModal = false;
    if (this.input) {
      this.focusSearchText();
    }
  }

  clearInput() {
    this.inputValue = "";
    this.placeholder = "";
  }

  clearSearch() {
    this.searchValue = "";
    this.modalLookup.resetModal();
  }

  focusSearchText() {
    this.input.input.nativeElement.focus();
  }

  onLoadStarting($event: SearchGridLoadStartedEventArgs) {
    this.loadStarting.emit($event);
  }

  public load() {
    this.modalLookup.load();
  }
}

export interface AutoLookupWithModalInputFormat {
  stringLiteralElement?: string;
  columnNameElement?: string;
  manualFormatter?: Function;
}
