import {
  Component,
  Input,
  Output,
  EventEmitter,
  Optional,
} from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";
import { ControlContainerFactory } from "../../../../../factories/control-container.factory";

@Component({
  selector: "spk-textarea",
  templateUrl: "./spk-textarea.component.html",
  styleUrls: ["./spk-textarea.component.scss"],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: ControlContainerFactory,
      deps: [[new Optional(), NgForm]],
    },
  ],
})
export class SpkTextareaComponent {
  @Input() model: string;
  @Input() wrapped: boolean = true;
  @Input() label: string;
  @Input() disabled: boolean;
  @Input() maxlength: number;
  @Input() rows: number;
  @Input() class: string;
  @Input() wrappedClass: string;
  @Input() cols: number;
  @Input() name: string;
  @Input() placeholder: string;
  @Input() uppercase: boolean;
  @Input() help: string;
  @Output() modelChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() focus: EventEmitter<any> = new EventEmitter<any>();
  @Output() blur: EventEmitter<any> = new EventEmitter<any>();
  constructor() {}

  modelChanged() {
    this.modelChange.emit(this.model);
  }

  focused($event) {
    this.focus.emit($event);
  }

  lostFocus($event) {
    if (
      this.model != null &&
      this.model != undefined &&
      this.uppercase === true
    ) {
      this.model = this.model.toUpperCase();
      this.modelChange.emit(this.model);
    }
    this.blur.emit($event);
  }
}
