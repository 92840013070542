import { Component, OnInit } from "@angular/core";
import { PageContainerService } from "../../../services";

@Component({
  selector: "exception-example",
  templateUrl: "./exception-example.component.html",
  styleUrls: ["./exception-example.component.scss"],
})
export class ExceptionExampleComponent implements OnInit {
  constructor(public pcs: PageContainerService) {}

  ngOnInit() {}

  async triggerException() {
    let response = await this.pcs.apiService.TriggerServerException({});
  }
}
