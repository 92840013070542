import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnChanges,
  SimpleChanges,
  Optional,
} from "@angular/core";
import { PageContainerService } from "../../../../../services";
import {
  FilterSpecification,
  SearchCommandRequest,
} from "../../../../../data/framework/searchObjects";
import * as _ from "lodash";
import { isNullOrUndefined } from "util";
import { SpkModalLookupComponent } from "../spk-modal-lookup/spk-modal-lookup.component";
import { NgForm } from "../../../../../ng";
import { ControlContainer } from "@angular/forms";
import { ControlContainerFactory } from "../../../../../factories/control-container.factory";
import { SpkTextComponent } from "../../inputs/spk-text/spk-text.component";

@Component({
  selector: "spk-lookup",
  templateUrl: "./spk-lookup.component.html",
  styleUrls: ["./spk-lookup.component.scss"],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: ControlContainerFactory,
      deps: [[new Optional(), NgForm]],
    },
  ],
})
export class SpkLookupComponent implements OnChanges {
  @Input() label: string;
  @Input() wrappedClass: string = "";
  @Input() inputFormat: SpkLookupFormat[];
  @Input() disabled: boolean = false;
  placeholder: string = "";
  inputValue: string = "";
  displayValue: string = "";
  searchValue: string = "";
  showingModal: boolean = false;

  @ViewChild("input", { static: true }) input: SpkTextComponent;
  @ViewChild("modalLookup", { static: false })
  modalLookup: SpkModalLookupComponent;

  @Input() selectedResult: any;
  @Output() selectedResultChange: EventEmitter<any> = new EventEmitter<any>();

  //Modal Lookup Bindings to Pass through
  @Input() id: string = "";
  @Input() list: string = "";
  @Input() title: string = "";
  @Input() sort: string = "";
  @Input() command: string = "";
  @Input() filter: FilterSpecification;
  autoload: boolean = false;

  container: ControlContainer;

  constructor(
    public pcs: PageContainerService,
    container: ControlContainer,
  ) {
    this.container = container;
  }

  ngOnChanges(changes: SimpleChanges) {
    for (let propName in changes) {
      if (propName == "selectedResult") {
        this.calculateDisplayValue();
      }
    }
  }

  inputKeydown(value) {
    if (value.keyCode == 13) {
      this.searchValue = this.inputValue;
      this.inputValue = "";
      setTimeout(() => {
        this.loadModalAndShow();
      });
    } else if (value.keyCode == 46) {
      this.selectedResult = null;
      this.selectedResultChange.emit(this.selectedResult);
      this.calculateDisplayValue();
    } else if (value.keyCode == 9 && this.inputValue) {
      this.onBlur();
      return false;
    }
  }

  isWrapped() {
    return this.label != undefined && this.label != null && this.label != "";
  }

  onFocus() {
    this.placeholder = this.displayValue;
    this.inputValue = "";
  }

  onBlur() {
    this.searchValue = this.inputValue;
    this.handleInput();
  }

  async handleInput() {
    if (this.searchValue) {
      var localFilter = this.filter ? this.filter : new FilterSpecification();
      var payload: SearchCommandRequest = {
        SearchType: this.list,
        SearchCriteria: {
          FilterSpecification: localFilter,
          SearchText: this.searchValue,
          PaginationSpecification: {
            PageIndex: 1,
            PageSize: 2,
          },
          SortSpecification: {
            SortString: "",
          },
        },
      };

      var response = await this.pcs.apiService.Search(payload, false, false);
      if (response.PagedResult.Data.length == 1) {
        this.resultSelected(response.PagedResult.Data[0]);
      } else if (!this.showingModal) {
        this.loadModalAndShow();
      }
    } else {
      this.inputValue = this.displayValue;
    }
  }

  loadModalAndShow() {
    this.showingModal = true;
    this.modalLookup.load();
    this.modalLookup.show();
  }

  resultSelected(row: any) {
    this.selectedResult = row;
    this.selectedResultChange.emit(this.selectedResult);
    this.calculateDisplayValue();
    this.modalClosed();
    if (this.container) this.container.control.markAsDirty();
  }

  calculateDisplayValue() {
    if (this.selectedResult) {
      var result = "";
      _.forEach(this.inputFormat, (element: SpkLookupFormat) => {
        if (!isNullOrUndefined(element.stringLiteralElement)) {
          result += element.stringLiteralElement;
        } else if (
          !isNullOrUndefined(element.columnNameElement) &&
          this.selectedResult.hasOwnProperty(element.columnNameElement) &&
          this.selectedResult[element.columnNameElement] != undefined &&
          this.selectedResult[element.columnNameElement] != null
        ) {
          result += this.selectedResult[element.columnNameElement].toString();
        } else if (!isNullOrUndefined(element.manualFormatter)) {
          result += element.manualFormatter(this.selectedResult);
          //result = "";
          //return false;
        } else {
          //result = "";
          //return false;
        }
      });
      this.displayValue = result;
      this.placeholder = result;
      this.inputValue = "";
    } else {
      this.displayValue = "";
      this.placeholder = "";
      this.inputValue = "";
    }
  }

  modalClosed() {
    this.showingModal = false;
    if (this.input) {
      this.input.input.nativeElement.focus();
    }
  }
}

export interface SpkLookupFormat {
  stringLiteralElement?: string;
  columnNameElement?: string;
  manualFormatter?: Function;
}
