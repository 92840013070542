import { Injectable } from "@angular/core";
import { FilterSpecification } from "../data/framework/searchObjects";
import * as _ from "lodash";

@Injectable()
export class SearchGridCustomFilterService {
  constructor() {}

  private customFilters: ICustomFilter[] = [];

  public pushFilter(customFilter: ICustomFilter) {
    var filterIndex = this.getFilterIndex(customFilter.key);
    if (filterIndex < 0) {
      this.customFilters.push(customFilter);
    } else {
      this.customFilters[filterIndex] = customFilter;
    }
  }

  private getFilterIndex(key: string): number {
    var filterIndex = _.findIndex(
      this.customFilters,
      (filter: ICustomFilter) => {
        return filter.key == key;
      },
    );
    return filterIndex;
  }

  public getFilter(key: string): ICustomFilter {
    var resultFilter = _.find(this.customFilters, (filter: ICustomFilter) => {
      return filter.key == key;
    });
    return resultFilter;
  }

  public removeFilter(key: string) {
    var resultIndex = this.getFilterIndex(key);
    this.customFilters.splice(resultIndex, 1);
  }
}

export interface ICustomFilter {
  key: string;
  filter: FilterSpecification;
  filterBackingValues: any;
}
