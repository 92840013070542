import { Component, OnInit, ViewChild } from "@angular/core";
import {
  GetSubCategoryTypeCommandRequest,
  SaveSubCategoryTypeCommandRequest,
  SubCategoryTypeDto,
} from "../../../../data/CommandServiceDtos.generated";
import { ActivatedRoute, NgForm, Params } from "../../../../ng";
import { PageContainerService } from "../../../../services";
import { CanComponentDeactivate } from "../../../../services/can-deactivate-guard.service";
import { ControllerForViewBase } from "../../../framework/controller-for-view-base";

@Component({
  selector: "subcategory-type",
  templateUrl: "./subcategory-type.component.html",
  styleUrls: ["./subcategory-type.component.css"],
})
export class SubcategoryTypeComponent
  extends ControllerForViewBase
  implements OnInit, CanComponentDeactivate
{
  public model: SubCategoryTypeDto = new SubCategoryTypeDto();
  @ViewChild("form", { static: true }) form: NgForm;

  constructor(
    public pcs: PageContainerService,
    public activatedRoute: ActivatedRoute,
  ) {
    super(pcs);
    this.pcs.actionLinksPanelService.addBackButton();
    this.pcs.actionLinksPanelService.addActionLinks([
      {
        text: "Save ",
        fontAwesomeIcon: "save",
        onClick: () => {
          this.submit();
        },
      },
    ]);
    this.pcs.actionLinksPanelService.addActionLinks([
      {
        text: "Save and Close",
        fontAwesomeIcon: "save",
        onClick: () => {
          this.submit(true);
        },
      },
    ]);
  }

  getActionText = () => (this.model.Id > 0 ? "Update" : "Create");

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.load(+params["id"]);
    });
  }

  async load(id: number) {
    var payload: GetSubCategoryTypeCommandRequest = {
      Id: id,
    };
    var response = await this.pcs.apiService.GetSubCategoryType(
      payload,
      true,
      false,
    );
    this.model = response.SubCategoryType;
  }

  async submit(navigateaway: boolean = false) {
    var payload: SaveSubCategoryTypeCommandRequest = {
      SubCategoryType: this.model,
    };
    var response = await this.pcs.apiService.SaveSubCategoryType(
      payload,
      true,
      false,
    );
    this.form.form.markAsPristine();
    this.updateUrlForNewEntity(["/subcategory-type", response.Id]);
    if (navigateaway) {
      window.history.back();
    } else {
      this.load(response.Id);
    }
  }

  canDeactivate(): Promise<boolean> {
    var promise = Promise.resolve(true);
    if (this.form.dirty) return this.pcs.showUnsavedChangesDialog();
    return promise;
  }
}
