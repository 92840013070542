import {
  Component,
  Input,
  Output,
  EventEmitter,
  Optional,
} from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";
import { ControlContainerFactory } from "../../../../../factories/control-container.factory";

@Component({
  selector: "spk-checkbox",
  templateUrl: "./spk-checkbox.component.html",
  styleUrls: ["./spk-checkbox.component.scss"],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: ControlContainerFactory,
      deps: [[new Optional(), NgForm]],
    },
  ],
})
export class SpkCheckboxComponent {
  @Input() model: boolean;
  @Input() label: string;
  @Input() wrapped: boolean = true;
  @Input() class: string;
  @Input() wrappedClass: string;
  @Input() disabled: boolean;
  @Input() name: string;
  @Input() help: string;
  @Output() modelChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor() {}

  modelChanged() {
    this.modelChange.emit(this.model);
  }
}
