import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import * as $ from "jquery";
import {
  EditFileCommandRequest,
  GetFileCommandResponse,
} from "../../../../../data/CommandServiceDtos.generated";

@Component({
  selector: "spk-file-edit-modal",
  templateUrl: "./spk-file-edit-modal.component.html",
  styleUrls: ["./spk-file-edit-modal.component.scss"],
})
export class SpkFileEditModalComponent implements OnInit {
  model: EditFileCommandRequest = new EditFileCommandRequest();
  @Output() submitEmitter: EventEmitter<EditFileCommandRequest> =
    new EventEmitter<EditFileCommandRequest>();

  constructor() {}

  ngOnInit() {}

  setModel(response: GetFileCommandResponse) {
    this.model.FileId = response.Id;
    this.model.DisplayName = response.DisplayName;
    this.model.Description = response.Description;
  }

  show() {
    $("#spk-file-edit-modal").modal("show");
  }

  submit() {
    this.submitEmitter.emit(this.model);
  }
}
