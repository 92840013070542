import {
  AfterContentInit,
  AfterViewInit,
  ApplicationRef,
  ChangeDetectorRef,
  Component,
  Directive,
  DoCheck,
  EventEmitter,
  HostListener,
  Injectable,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { Location, LocationStrategy } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { NgForm } from "@angular/forms";
import {
  ActivatedRoute,
  CanDeactivate,
  Params,
  Router,
  RouterModule,
  Routes,
} from "@angular/router";
import { DomSanitizer, SafeUrl, SafeHtml } from "@angular/platform-browser";
import { Observable, Subject } from "rxjs";

export {
  ActivatedRoute,
  AfterContentInit,
  AfterViewInit,
  ApplicationRef,
  CanDeactivate,
  ChangeDetectorRef,
  Component,
  Directive,
  DoCheck,
  DomSanitizer,
  EventEmitter,
  HostListener,
  Injectable,
  Input,
  Location,
  LocationStrategy,
  NgForm,
  NgModule,
  Observable,
  Subject,
  OnChanges,
  OnInit,
  Output,
  Params,
  Router,
  RouterModule,
  Routes,
  SafeHtml,
  SafeUrl,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
  HttpClient,
};
