import { Component, OnInit, ViewChild } from "@angular/core";
import { ControllerForViewBase } from "../../controller-for-view-base";
import { CanComponentDeactivate } from "../../../../services/can-deactivate-guard.service";
import { NgForm } from "@angular/forms";
import {
  SaveApplicationSettingCommandRequest,
  GetApplicationSettingCommandRequest,
  GetApplicationSettingCommandResponse,
} from "../../../../data/CommandServiceDtos.generated";
import { ActivatedRoute, Params } from "../../../../ng";
import { PageContainerService } from "../../../../services";

@Component({
  selector: "application-setting",
  templateUrl: "./application-setting.component.html",
  styleUrls: ["./application-setting.component.scss"],
})
export class ApplicationSettingComponent
  extends ControllerForViewBase
  implements OnInit, CanComponentDeactivate
{
  @ViewChild("form", { static: true }) form: NgForm;
  model: SaveApplicationSettingCommandRequest =
    new SaveApplicationSettingCommandRequest();
  getActionText = () => (this.model.Id > 0 ? "Update" : "Create");
  paramName: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    pcs: PageContainerService,
  ) {
    super(pcs);
    this.pcs.actionLinksPanelService.addBackButton();
    this.pcs.actionLinksPanelService.addActionLinks([
      {
        text: "Save ",
        fontAwesomeIcon: "save",
        onClick: () => {
          this.submit();
        },
      },
    ]);
    this.pcs.actionLinksPanelService.addActionLinks([
      {
        text: "Save and Close",
        fontAwesomeIcon: "save",
        onClick: () => {
          this.submit(true);
        },
      },
    ]);
  }

  async ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.paramName = params["name"];
      this.load(this.paramName);
    });
  }

  private async load(name: string) {
    let getCommandRequest: GetApplicationSettingCommandRequest =
      new GetApplicationSettingCommandRequest();
    getCommandRequest.SettingName = name;
    let getCommandResponse =
      await this.pcs.apiService.GetApplicationSetting(getCommandRequest);
    this.mapResponseToModel(getCommandResponse);
  }

  mapResponseToModel(
    getCommandResponse: GetApplicationSettingCommandResponse,
  ): void {
    this.model.Id = getCommandResponse.Id;
    this.model.Name = getCommandResponse.Name;
    this.model.Value = getCommandResponse.Value;
  }

  async submit(navigateaway: boolean = false): Promise<void> {
    let saveResponse = await this.pcs.apiService.SaveApplicationSetting(
      this.model,
      undefined,
      true,
    );
    if (saveResponse.Id !== 0) {
      this.form.form.markAsPristine();
      if (navigateaway) {
        window.history.back();
      } else {
        this.load(this.paramName);
      }
    }
  }

  canDeactivate(): Promise<boolean> {
    var promise = Promise.resolve(true);
    if (this.form.dirty) return this.pcs.showUnsavedChangesDialog();
    return promise;
  }
}
