import { Component, OnInit } from "@angular/core";

@Component({
  selector: "delete-user-data-success",
  templateUrl: "./delete-user-data-success.component.html",
  styleUrls: ["./delete-user-data-success.component.css"],
})
export class DeleteUserDataSuccessComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
