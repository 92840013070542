import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "spk-quantity",
  templateUrl: "./spk-quantity.component.html",
  styleUrls: ["./spk-quantity.component.scss"],
})
export class SpkQuantityComponent implements OnInit {
  @Input() name: string = "quantitySelector";
  @Input() wrapped: boolean = true;
  @Input() label: string;
  @Input() disabled: boolean;
  @Input() wrappedClass: string;
  @Input() min: number = 0;
  @Input() max: number = 1000000;
  @Input() model: number;
  @Input() nullable: boolean = true;
  @Input() help: string;
  @Output() modelChange: EventEmitter<number> = new EventEmitter<number>();

  constructor() {}

  ngOnInit() {}

  changeQuantitySelected() {
    if (this.model < this.min) {
      this.model = this.min;
    } else if (this.model > this.max) {
      this.model = this.max;
    }
    this.modelChanged();
  }
  setMinQuantity() {
    this.model = this.min;
    this.modelChanged();
  }
  setMaxQuantity() {
    this.model = this.max;
    this.modelChanged();
  }
  incrementQuantity() {
    if (this.model < this.max) {
      this.model++;
      this.modelChanged();
    }
  }
  decrementQuantity() {
    if (this.model > this.min) {
      this.model--;
      this.modelChanged();
    }
  }

  modelChanged() {
    if (typeof this.model === "string") {
      this.model = parseInt(this.model);
    }
    this.modelChange.emit(this.model);
  }
}
