import { Component, OnInit } from "@angular/core";
import { ControllerForViewBase } from "../../framework/controller-for-view-base";
import { PageContainerService } from "../../../services";
import {
  GetDashboardCommandRequest,
  DashboardDto,
  SaveUserWidgetOrderCommandRequest,
  WidgetDto,
} from "../../../data/CommandServiceDtos.generated";
import * as _ from "lodash";
@Component({
  selector: "dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent
  extends ControllerForViewBase
  implements OnInit
{
  model: DashboardDto = new DashboardDto();

  constructor(public pcs: PageContainerService) {
    super(pcs);
  }

  async ngOnInit() {
    this.pcs.actionLinksPanelService.reset();
    this.pcs.actionLinksPanelService.addActionLinks([
      {
        text: "Manage Dashboard Widgets",
        fontAwesomeIcon: "cogs",
        onClick: () => {
          this.manageWidgets();
        },
      },
    ]);
    await this.load();
  }

  manageWidgets() {
    this.pcs.router.navigate(["user-widget-selection"]);
  }

  async load() {
    let payload: GetDashboardCommandRequest = {};
    let response = await this.pcs.apiService.GetDashboard(payload, true, false);
    this.model = response.Dashboard;
  }

  async sortWidgets() {
    var widgetIds = _.map(this.model.Widgets, (value: WidgetDto) => {
      return value.Id;
    });
    var payload: SaveUserWidgetOrderCommandRequest = {
      WidgetIds: widgetIds,
    };

    await this.pcs.apiService.SaveUserWidgetOrder(payload, true, true);
  }
}
