import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ControllerForViewBase } from "../../framework/controller-for-view-base";
import { PageContainerService } from "../../../services";
import { DeleteUserDataCommandRequest } from "../../../data/CommandServiceDtos.generated";

@Component({
  selector: "delete-user-data",
  templateUrl: "./delete-user-data.component.html",
  styleUrls: ["./delete-user-data.component.css"],
})
export class DeleteUserDataComponent
  extends ControllerForViewBase
  implements OnInit
{
  @ViewChild("form", { static: true }) form: NgForm;

  username: string;
  password: string;

  constructor(public pcs: PageContainerService) {
    super(pcs);
  }

  ngOnInit(): void {}

  async submit(): Promise<void> {
    var payload: DeleteUserDataCommandRequest = {
      Username: this.username,
      Password: this.password,
    };
    await this.pcs.apiService.DeleteUserData(payload, undefined, true);
    this.pcs.router.navigate(["delete-user-data-success"]);
  }
}
