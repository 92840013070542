import {
  Component,
  Input,
  OnChanges,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
  SimpleChanges,
  AfterViewInit,
  Optional,
} from "@angular/core";
import * as $ from "jquery";
import { DateDto } from "../../../../../data/CommandServiceDtos.generated";
import { DateDtoUtility } from "../../../../../utilities/dates/date-dto.utility";
import { NgForm, ControlContainer } from "@angular/forms";
import { ControlContainerFactory } from "../../../../../factories/control-container.factory";

@Component({
  selector: "spk-date",
  templateUrl: "./spk-date.component.html",
  styleUrls: ["./spk-date.component.scss"],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: ControlContainerFactory,
      deps: [[new Optional(), NgForm]],
    },
  ],
})
export class SpkDateComponent implements AfterViewInit, OnChanges {
  @Input() model: DateDto = new DateDto();
  dateString: string;
  @Input() minDate: DateDto = null;
  @Input() maxDate: DateDto = null;
  @Input() wrapped: boolean = true;
  @Input() class: string;
  @Input() wrappedClass: string;
  @Input() label: string;
  @Input() name: string;
  @Input() placeholder: string;
  @Input() disabled: boolean = false;
  @Input() orientation: string = "auto";
  @Input() help: string;
  @Output() modelChange: EventEmitter<DateDto> = new EventEmitter<DateDto>();

  @ViewChild("calendarInput", { static: false }) calendarInput: ElementRef;
  @ViewChild("calendarButton", { static: false }) calendarButton: ElementRef;

  container: ControlContainer;

  constructor(container: ControlContainer) {
    this.container = container;
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.calendarInput.nativeElement.onkeydown = (evt) => this.keyDown(evt);
    this.initializeDatePicker();
    this.setInputValue();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes["model"] &&
      !DateDtoUtility.datesAreTheSame(
        changes["model"].previousValue,
        changes["model"].currentValue,
      )
    ) {
      this.setInputValue();
    }
    if (
      (changes["minDate"] &&
        !DateDtoUtility.datesAreTheSame(
          changes["minDate"].previousValue,
          changes["minDate"].currentValue,
        )) ||
      (changes["maxDate"] &&
        !DateDtoUtility.datesAreTheSame(
          changes["maxDate"].previousValue,
          changes["maxDate"].currentValue,
        ))
    ) {
      this.setMinAndMaxDates();
    }
  }

  initializeDatePicker() {
    let that = this;
    $(`#${that.name}`)
      .datepicker({
        orientation: `${this.orientation}`,
        todayHighlight: true,
        autoclose: true,
        startDate: DateDtoUtility.formatDateStringMMDDYYYY(this.minDate),
        endDate: DateDtoUtility.formatDateStringMMDDYYYY(this.maxDate),
      })
      .on("changeDate", function (e) {
        that.setModel($(`#${that.name}`).val());
      })
      .on("clearDate", function (e) {
        that.setModel($(`#${that.name}`).val());
      });
  }

  setInputValue() {
    if (DateDtoUtility.isDateDtoValid(this.model)) {
      this.dateString = DateDtoUtility.formatDateStringMMDDYYYY(this.model);
      $(`#${this.name}`).datepicker("update", this.dateString);
    } else {
      $(`#${this.name}`).datepicker("update", "");
    }
  }

  setModel(value: string) {
    if (DateDtoUtility.isDateStringValid(value)) {
      let dateParts = value.split("/");
      let dateDto: DateDto = {
        Month: <any>dateParts[0],
        Day: <any>dateParts[1],
        Year: <any>dateParts[2],
      };
      if (!DateDtoUtility.datesAreTheSame(dateDto, this.model)) {
        this.model = dateDto;
      }
    } else {
      if (this.model != null) {
        this.model = null;
      }
    }
    this.modelChange.emit(this.model);
    this.markControlDirty();
  }

  setMinAndMaxDates() {
    this.initializeDatePicker();
  }

  keyDown(value) {
    if (value.keyCode === 68) {
      this.model = DateDtoUtility.today();
      this.setInputValue();
      this.modelChange.emit(this.model);
      this.markControlDirty();
      return false;
    }
  }

  calendarButtonClicked() {
    this.calendarInput.nativeElement.focus();
  }

  markControlDirty() {
    if (this.container != null && this.container.control != null) {
      this.container.control.markAsDirty();
    }
  }
}
