import {
  Component,
  Input,
  Output,
  EventEmitter,
  Optional,
} from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";
import { ControlContainerFactory } from "../../../../../factories/control-container.factory";

@Component({
  selector: "spk-select",
  templateUrl: "./spk-select.component.html",
  styleUrls: ["./spk-select.component.scss"],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: ControlContainerFactory,
      deps: [[new Optional(), NgForm]],
    },
  ],
})
export class SpkSelectComponent {
  @Input() model: any;
  @Input() label: string;
  @Input() wrapped: boolean = true;
  @Input() disabled: boolean = false;
  @Input() options: any = {};
  @Input() optionTextProperty: string;
  @Input() optionValueProperty: string;
  @Input() nullable: boolean = false;
  @Input() class: string;
  @Input() wrappedClass: string;
  @Input() name: string;
  @Input() nullableValue: any = null;
  @Input() uppercase: boolean;
  @Input() help: string;
  @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  modelChanged(value: any) {
    this.modelChange.emit(this.model);
  }

  get format() {
    return !this.uppercase;
  }
}
