import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { SendEventNotificationCommandRequest } from "../../../data/CommandServiceDtos.generated";
import { ClientSessionService, PageContainerService } from "../../../services";
import { ControllerForViewBase } from "../../framework/controller-for-view-base";

@Component({
  selector: "event-notifications",
  templateUrl: "./event-notifications.component.html",
  styleUrls: ["./event-notifications.component.scss"],
})
export class EventNotificationsComponent
  extends ControllerForViewBase
  implements OnInit
{
  model: SendEventNotificationCommandRequest =
    new SendEventNotificationCommandRequest();
  clientSessionService: ClientSessionService = new ClientSessionService();

  constructor(
    private activatedRoute: ActivatedRoute,
    pcs: PageContainerService,
  ) {
    super(pcs);
  }

  setActionLinks() {
    this.pcs.actionLinksPanelService.addBackButton();
  }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.setActionLinks();
  }

  async sendNotification(): Promise<void> {
    this.model.UserId = this.clientSessionService.userId;
    let response = await this.pcs.apiService.SendEventNotification(
      this.model,
      false,
      true,
    );
    if (response.Succeeded == true) {
      this.model.EventNotification = null;
      this.model.UserId = null;
    }
  }
}
