import { Component, OnInit } from "@angular/core";
import { ICellLink } from "../../../data";

@Component({
  selector: "app-search-grid-format-example",
  templateUrl: "./search-grid-format-example.component.html",
  styleUrls: ["./search-grid-format-example.component.scss"],
})
export class SearchGridFormatExampleComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    this.cellLinks.push({
      columnName: "Name",
      route: "/search-grid-link/:id",
    });
  }

  cellLinks: ICellLink[] = [];
}
