import { Component, OnInit, Input } from "@angular/core";
import { ValueTextSelectedDto } from "../../../../../data/CommandServiceDtos.generated";

@Component({
  selector: "spk-value-text-checkboxlist",
  templateUrl: "./spk-value-text-checkboxlist.component.html",
  styleUrls: ["./spk-value-text-checkboxlist.component.scss"],
})
export class SpkValueTextCheckboxListComponent implements OnInit {
  @Input() model: ValueTextSelectedDto[] = [];

  constructor() {}

  ngOnInit() {}
}
