import { Component, OnInit } from "@angular/core";
import {
  UserFilesDto,
  GetRecentUserFilesCommandRequest,
  UserFileDto,
  UserFileStatus,
} from "../../../../data/CommandServiceDtos.generated";
import { PageContainerService } from "../../../../services";
import * as _ from "lodash";
import { UserFileService } from "../../../../services/user-file.service";

@Component({
  selector: "user-recent-files",
  templateUrl: "./user-recent-files.component.html",
  styleUrls: ["./user-recent-files.component.scss"],
})
export class UserRecentFilesComponent implements OnInit {
  model: UserFilesDto = new UserFilesDto();

  constructor(
    public pcs: PageContainerService,
    public userFileService: UserFileService,
  ) {}

  async ngOnInit() {
    let userFiles = await this.getRecentFilesList();
    this.model = userFiles;
    this.addPendingFilesInModelToPendingFileService();
    setInterval(() => this.checkForPendingFiles(), 1500);
  }

  async getRecentFilesList(): Promise<UserFilesDto> {
    let payload: GetRecentUserFilesCommandRequest = {};
    let response = await this.pcs.apiService.GetRecentUserFiles(
      payload,
      false,
      false,
      false,
    );
    return response.UserFiles;
  }

  addPendingFilesInModelToPendingFileService() {
    let pendingUserFiles = this.getPendingUserFiles();
    if (pendingUserFiles.length > 0) {
      let pendingUserFileIds = _.map(
        pendingUserFiles,
        (userFile: UserFileDto) => {
          return userFile.Id;
        },
      );
      this.userFileService.addPendingUserFileIds(pendingUserFileIds);
    }
  }

  getPendingUserFiles(): UserFileDto[] {
    let pendingUserFiles = _.filter(
      this.model.UserFiles,
      (userFile: UserFileDto) => {
        return userFile.StatusEnum == UserFileStatus.Pending;
      },
    );
    return pendingUserFiles;
  }

  async checkForPendingFiles() {
    if (this.userFileService.shouldPollForPendingFiles()) {
      let userFiles = await this.getRecentFilesList();
      this.compareFilesAndTriggerNotifications(userFiles);
      this.model = userFiles;
      _.forEach(this.model.UserFiles, (userFile: UserFileDto) => {
        if (userFile.StatusEnum != UserFileStatus.Pending) {
          this.userFileService.removeUserFileId(userFile.Id);
        }
      });
    }
  }

  compareFilesAndTriggerNotifications(userFiles: UserFilesDto) {
    let filesHaveCompleted = _.some(
      userFiles.UserFiles,
      (userFile: UserFileDto) => {
        let innerResult = _.some(
          this.model.UserFiles,
          (modelFile: UserFileDto) => {
            return (
              userFile.Id == modelFile.Id &&
              userFile.StatusEnum == UserFileStatus.Complete &&
              modelFile.StatusEnum == UserFileStatus.Pending
            );
          },
        );
        return innerResult;
      },
    );

    if (filesHaveCompleted) {
      this.pcs.notificationService.success("File(s) ready.", "", {
        timeOut: 0,
      });
    }
  }

  isPending(userFile: UserFileDto): boolean {
    let result = userFile.StatusEnum == UserFileStatus.Pending;
    return result;
  }

  isCompleted(userFile: UserFileDto): boolean {
    let result = userFile.StatusEnum == UserFileStatus.Complete;
    return result;
  }

  isFailed(userFile: UserFileDto): boolean {
    let result = userFile.StatusEnum == UserFileStatus.Failed;
    return result;
  }

  downloadFile(userFile: UserFileDto) {
    if (userFile.StatusEnum == UserFileStatus.Complete) {
      this.pcs.executeCommandService.executeFileEndpoint(
        "Download",
        { id: userFile.FileId, displayName: userFile.FileName },
        true,
        false,
        false,
      );
    }
  }
}
