import { Component, OnInit, ViewChild } from "@angular/core";
import { ControllerForViewBase } from "../../framework/controller-for-view-base";
import { PageContainerService } from "../../../services";
import { ActivatedRoute } from "../../../ng";
import {
  ValueTextSelectedDto,
  SaveUserWidgetSelectionsCommandRequest,
  GetUserWidgetSelectionsCommandRequest,
  WidgetDto,
} from "../../../data/CommandServiceDtos.generated";
import { NgForm } from "@angular/forms";
import * as _ from "lodash";

@Component({
  selector: "user-widget-selection",
  templateUrl: "./user-widget-selection.component.html",
  styleUrls: ["./user-widget-selection.component.scss"],
})
export class UserWidgetSelectionComponent
  extends ControllerForViewBase
  implements OnInit
{
  @ViewChild("form", { static: true }) form: NgForm;
  model: WidgetDto[] = [];

  constructor(
    public pcs: PageContainerService,
    public activatedRoute: ActivatedRoute,
  ) {
    super(pcs);
  }

  async ngOnInit() {
    await this.load();
    this.setActionLinks();
  }

  setActionLinks() {
    this.pcs.actionLinksPanelService.reset();
    this.pcs.actionLinksPanelService.addBackButton();
    this.pcs.actionLinksPanelService.addActionLinks([
      {
        text: "Save ",
        fontAwesomeIcon: "save",
        onClick: () => {
          this.submit();
        },
      },
    ]);
    this.pcs.actionLinksPanelService.addActionLinks([
      {
        text: "Save and Close",
        fontAwesomeIcon: "save",
        onClick: () => {
          this.submit(true);
        },
      },
    ]);
  }

  async load() {
    let payload: GetUserWidgetSelectionsCommandRequest = {};
    let response = await this.pcs.apiService.GetUserWidgetSelections(
      payload,
      true,
      false,
    );
    this.model = response.Widgets;
  }

  async submit(navigateaway: boolean = false): Promise<void> {
    var widgetIds = _.map(
      _.filter(this.model, (widget: WidgetDto) => {
        return widget.Selected;
      }),
      (value: WidgetDto) => {
        return value.Id;
      },
    );
    var payload: SaveUserWidgetSelectionsCommandRequest = {
      SelectedWidgetIds: widgetIds,
    };
    let response = await this.pcs.apiService.SaveUserWidgetSelections(
      payload,
      true,
      true,
    );
    this.form.form.markAsPristine();
    this.updateUrlForNewEntity(["/dashboard"]);
    if (navigateaway) {
      window.history.back();
    } else {
      this.load();
    }
  }
}
