import { Params } from "@angular/router";
import { ControllerForViewBase } from "./controller-for-view-base";
import { PageContainerService } from "../../services";
import { AfterViewInit } from "../../ng";

export class ControllerForTabBase extends ControllerForViewBase {
  constructor(public pcs: PageContainerService) {
    super(pcs);
  }

  protected handleTabAndModalQueryParams(queryParams: Params): any {
    this.pcs.tabService.handleTabAndModalQueryParams(queryParams);
  }

  public updateTab(tab: string) {
    this.pcs.tabService.updateTab(tab);
  }
}
