import { Component, OnInit } from "@angular/core";
import { ControllerForViewBase } from "../../../framework/controller-for-view-base";
import { PageContainerService } from "../../../../services";
import {
  FilterSpecification,
  LogicalOperator,
  ComparisonOperator,
  FilterDataType,
} from "../../../../data/framework/searchObjects";
import { UserSearchRow } from "../../../../data/CommandServiceDtos.generated";
import { ILink } from "../../../../data";

@Component({
  selector: "users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.scss"],
})
export class UsersComponent extends ControllerForViewBase {
  filter: FilterSpecification;
  showInactive: boolean = false;

  leftLinks: ILink[];
  constructor(public pcs: PageContainerService) {
    super(pcs);
    let links: ILink[] = [
      {
        text: "",
        action: (row) => this.openRow(row),
        title: "Open",
        icon: "folder-open",
        class: "btn-primary",
      },
    ];
    this.leftLinks = links;

    this.pcs.actionLinksPanelService.addBackButton();
    this.pcs.actionLinksPanelService.addActionLinks([
      {
        text: "Create User",
        fontAwesomeIcon: "plus",
        onClick: () => this.pcs.router.navigate(["/create-account"]),
      },
    ]);
    this.setFilterSpecification();
  }

  setFilterSpecification() {
    this.filter = new FilterSpecification();
    this.filter.Filters = [];
    this.filter.JoinType = LogicalOperator.And;

    if (!this.showInactive) {
      this.filter.Parameters = [
        {
          Comparison: ComparisonOperator.Equals,
          DataType: FilterDataType.Boolean,
          FieldName: "Active",
          Filter: "true",
        },
      ];
    }
  }

  openRow(row: UserSearchRow): void {
    this.pcs.router.navigate(["/user", row.Id]);
  }
}
