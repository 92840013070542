import {
  Component,
  Input,
  Output,
  EventEmitter,
  Optional,
} from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";
import { ControlContainerFactory } from "../../../../../factories/control-container.factory";

@Component({
  selector: "spk-password",
  templateUrl: "./spk-password.component.html",
  styleUrls: ["./spk-password.component.scss"],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: ControlContainerFactory,
      deps: [[new Optional(), NgForm]],
    },
  ],
})
export class SpkPasswordComponent {
  @Input() model: string;
  @Input() label: string;
  @Input() wrapped: boolean = true;
  @Input() disabled: boolean;
  @Input() maxlength: number;
  @Input() class: string;
  @Input() wrappedClass: string;
  @Input() placeholder: string;
  @Input() name: string;
  @Input() autoComplete: string = "off";
  @Input() help: string;
  @Output() modelChange: EventEmitter<string> = new EventEmitter<string>();
  constructor() {}

  modelChanged() {
    this.modelChange.emit(this.model);
  }
}
