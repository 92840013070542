import { Component, OnInit, ViewChild } from "@angular/core";
import {
  UserFileStatus,
  UserFileSearchRow,
  UserFileStatusDisplayMap,
} from "../../../../data/CommandServiceDtos.generated";
import { PageContainerService } from "../../../../services";
import { ControllerForViewBase } from "../../controller-for-view-base";
import { ILink } from "../../../../data";
import { SpkSearchGridComponent } from "../../spk/controls/spk-search-grid/spk-search-grid.component";

@Component({
  selector: "user-files",
  templateUrl: "./user-files.component.html",
  styleUrls: ["./user-files.component.scss"],
})
export class UserFilesComponent
  extends ControllerForViewBase
  implements OnInit
{
  @ViewChild("grid", { static: false }) grid: SpkSearchGridComponent;
  leftLinks: ILink[];
  customFormats: any = {
    Status: (column) => this.formatStatus(column),
  };

  constructor(public pcs: PageContainerService) {
    super(pcs);
    this.setActionlinks();
    this.leftLinks = [
      {
        text: "",
        action: (row) => this.downloadFile(row),
        title: "Download",
        icon: "download",
        class: "btn-primary",
      },
    ];
  }

  setActionlinks() {
    this.pcs.actionLinksPanelService.addBackButton();
  }

  formatStatus(value: any): string {
    let result: string;
    switch (value) {
      case UserFileStatus[UserFileStatus.Complete]: {
        result =
          '<i title="Completed Successfully" class="fa fa-check text-success"></i>';
        break;
      }
      case UserFileStatus[UserFileStatus.Pending]: {
        result = '<i title="Pending" class="fa fa-clock-o text-warning"></i>';
        break;
      }
      case UserFileStatus[UserFileStatus.Failed]: {
        result = '<i title="Failed" class="fa fa-times text-danger"></i>';
        break;
      }
      default: {
        result = "";
        break;
      }
    }
    return result;
  }

  downloadFile(file: UserFileSearchRow) {
    if (file.Status == UserFileStatusDisplayMap[UserFileStatus.Complete]) {
      this.pcs.executeCommandService.executeFileEndpoint(
        "Download",
        { id: file.FileId, displayName: file.FileName },
        true,
        false,
      );
    }
  }

  test() {
    this.pcs.notificationService.success("File(s) ready.", "", {
      timeOut: 0,
      positionClass: "toast-top-right",
    });
  }
}
