import { Component, OnInit, ViewChild } from "@angular/core";
import { ILink, ISearchGridFormat } from "../../../data";
import { PageContainerService } from "../../../services";
import { ControllerForViewBase } from "../../framework/controller-for-view-base";
import {
  FilterSpecification,
  LogicalOperator,
  ComparisonOperator,
  FilterDataType,
} from "../../../data/framework/searchObjects";
import { DateDto } from "../../../data/CommandServiceDtos.generated";
import { SpkSearchGridComponent } from "../../framework/spk/controls/spk-search-grid/spk-search-grid.component";
import { DateDtoUtility } from "../../../utilities/dates/date-dto.utility";
import { Moment } from "moment";
import { SpkTextComponent } from "../../framework/spk/inputs/spk-text/spk-text.component";
import { SpkDateComponent } from "../../framework/spk/inputs/spk-date/spk-date.component";

@Component({
  selector: "message-transcripts",
  templateUrl: "./message-transcripts.component.html",
  styleUrls: ["./message-transcripts.component.css"],
})
export class MessageTranscriptsComponent
  extends ControllerForViewBase
  implements OnInit
{
  filter: FilterSpecification;
  youthName: string;
  adminName: string;
  startDate: DateDto;
  endDate: DateDto;

  leftLinks: ILink[] = [];

  thFormats: ISearchGridFormat[] = [
    { value: "YouthName", columnName: "YouthName", cssClass: "w-px-200" },
    { value: "AdminName", columnName: "AdminName", cssClass: "w-px-200" },
    {
      value: "SentDateTime",
      columnName: "SentDateTime",
      cssClass: "text-center w-px-200",
    },
    {
      value: "SenderIsYouth",
      columnName: "SenderIsYouth",
      cssClass: "text-center w-px-125",
    },
    {
      value: "ReadByRecipient",
      columnName: "ReadByRecipient",
      cssClass: "text-center w-px-125",
    },
  ];

  tdFormats: ISearchGridFormat[] = [
    {
      value: "SentDateTime",
      columnName: "SentDateTime",
      cssClass: "text-center",
    },
  ];

  @ViewChild("grid", { static: false })
  public gridComponent: SpkSearchGridComponent;
  @ViewChild("youthNameSearchControl", { static: false })
  public youthNameSearchControl: SpkTextComponent;
  @ViewChild("adminNameSearchControl", { static: false })
  public adminNameSearchControl: SpkTextComponent;
  @ViewChild("startDateRangeSearchControl", { static: false })
  public startDateRangeSearchControl: SpkDateComponent;
  @ViewChild("endDateRangeSearchControl", { static: false })
  public endDateRangeSearchControl: SpkDateComponent;

  constructor(public pcs: PageContainerService) {
    super(pcs);
    this.pcs.actionLinksPanelService.addBackButton();
    this.pcs.actionLinksPanelService.addActionLinks([]);
    this.setFilterSpecification();
  }

  ngOnInit() {
    this.leftLinks = [];
  }

  keyDown(value) {
    if (value.keyCode == 13) {
      this.search();
      return false;
    }
    return true;
  }

  dateChanged(value) {
    if (value) {
      this.search();
    }
  }

  search() {
    this.setFilterSpecification();
    this.gridComponent.filter = this.filter;
    this.gridComponent.load();
  }

  ngAfterViewInit(): void {
    this.youthNameSearchControl.input.nativeElement.onkeydown = (evt) =>
      this.keyDown(evt);
    this.adminNameSearchControl.input.nativeElement.onkeydown = (evt) =>
      this.keyDown(evt);
    this.startDateRangeSearchControl.calendarInput.nativeElement.onchange = (
      evt,
    ) => this.dateChanged(evt);
    this.endDateRangeSearchControl.calendarInput.nativeElement.onchange = (
      evt,
    ) => this.dateChanged(evt);
  }

  setFilterSpecification() {
    this.filter = new FilterSpecification();
    this.filter.Filters = [];
    this.filter.JoinType = LogicalOperator.And;

    this.filter.Parameters = [];

    if (this.youthName) {
      this.filter.Parameters.push({
        Comparison: ComparisonOperator.Like,
        DataType: FilterDataType.String,
        FieldName: "YouthName",
        Filter: this.youthName,
      });
    }

    if (this.adminName) {
      this.filter.Parameters.push({
        Comparison: ComparisonOperator.Like,
        DataType: FilterDataType.String,
        FieldName: "AdminName",
        Filter: this.adminName,
      });
    }

    if (this.startDate) {
      this.filter.Parameters.push({
        Comparison: ComparisonOperator.GreaterThanEquals,
        DataType: FilterDataType.DateTime,
        FieldName: "SentDateTime",
        Filter: DateDtoUtility.formatDateStringMMDDYYYY(this.startDate),
      });
    }

    if (this.endDate) {
      this.filter.Parameters.push({
        Comparison: ComparisonOperator.LessThanEquals,
        DataType: FilterDataType.DateTime,
        FieldName: "SentDateTime",
        Filter: DateDtoUtility.formatDateStringMMDDYYYY(
          this.getDateDtoWithUpdatedDateValues(this.endDate, 1),
        ),
      });
    }
  }

  onSearch() {
    this.setFilterSpecification();
    this.gridComponent.filter = this.filter;
    this.gridComponent.load();
  }

  getcsv() {
    this.gridComponent.getcsv();
  }

  getDateDtoWithUpdatedDateValues(
    dateDto: DateDto,
    dayIncrementAmount: number = 0,
    monthIncrementAmount: number = 0,
    yearIncrementAmount: number = 0,
  ): DateDto {
    let date: DateDto = new DateDto();

    if (DateDtoUtility.isDateDtoValid(dateDto)) {
      var moment: Moment = DateDtoUtility.getMoment(dateDto);

      if (dayIncrementAmount > 0) moment.add("d", dayIncrementAmount);

      if (dayIncrementAmount > 0) moment.add("M", monthIncrementAmount);

      if (dayIncrementAmount > 0) moment.add("y", yearIncrementAmount);

      date.Day = +moment.format("D");
      date.Month = +moment.format("M");
      date.Year = +moment.format("YYYY");
    }
    return date;
  }
}
