import { Component, ViewChild } from "@angular/core";
import { LinkFileDto } from "../../../data/CommandServiceDtos.generated";
import {
  FilterSpecification,
  LogicalOperator,
  ComparisonOperator,
  FilterDataType,
} from "../../../data/framework/searchObjects";
import { PageContainerService } from "../../../services";
import { ControllerForViewBase } from "../../framework/controller-for-view-base";
import { SpkFilesComponent } from "../../framework/spk/controls/spk-files/spk-files.component";

@Component({
  selector: "upload-files",
  templateUrl: "./upload-files.component.html",
  styleUrls: ["./upload-files.component.scss"],
})
export class UploadFilesComponent extends ControllerForViewBase {
  fileLinkType: string = "ExampleLink";
  linkFileDto: LinkFileDto;
  lockboxLinkFileDto: LinkFileDto;
  filter: FilterSpecification;
  generalFilter: FilterSpecification;

  @ViewChild("files", { static: false }) files: SpkFilesComponent;

  constructor(public pcs: PageContainerService) {
    super(pcs);
    this.linkFileDto = {
      LinkFileType: this.fileLinkType,
      LinkFileTypeKey: this.pcs.clientSessionService.userId,
      UserId: null,
      FileTypeId: null,
      FileId: null,
      RecordRefId: null,
      IsLockbox: false,
    };
    this.lockboxLinkFileDto = {
      LinkFileType: this.fileLinkType,
      LinkFileTypeKey: this.pcs.clientSessionService.userId,
      UserId: null,
      FileTypeId: null,
      FileId: null,
      RecordRefId: null,
      IsLockbox: true,
    };
    this.setFilterSpecification();
    this.setActionLinks();
    this.pcs.tabService.findAndSelectTab("General");
  }

  setActionLinks() {
    this.pcs.actionLinksPanelService.reset();
    this.pcs.actionLinksPanelService.addBackButton();
  }

  setFilterSpecification() {
    this.filter = new FilterSpecification();
    this.filter.Filters = [];
    this.filter.JoinType = LogicalOperator.And;

    if (this.pcs.clientSessionService.userId) {
      this.filter.Parameters = [
        {
          Comparison: ComparisonOperator.Equals,
          DataType: FilterDataType.String,
          FieldName: "LinkType",
          Filter: this.fileLinkType,
        },
        {
          Comparison: ComparisonOperator.Equals,
          DataType: FilterDataType.Numeric,
          FieldName: "LinkId",
          Filter: this.pcs.clientSessionService.userId.toString(),
        },
      ];
    }

    this.generalFilter = new FilterSpecification();
    this.generalFilter.Filters = [];
    this.generalFilter.JoinType = LogicalOperator.And;
    this.generalFilter.Parameters = [
      {
        Comparison: ComparisonOperator.Equals,
        DataType: FilterDataType.String,
        FieldName: "FileType",
        Filter: "",
      },
    ];
  }

  handleFileUploaded() {
    this.files.grid.load();
  }
}
