import { Component, OnInit, ViewChild } from "@angular/core";
import { ILink } from "../../../data";
import { PageContainerService } from "../../../services";
import { ControllerForViewBase } from "../../framework/controller-for-view-base";
import {
  SecurityCheckpointSearchRow,
  DeleteCheckpointCommandRequest,
} from "../../../data/CommandServiceDtos.generated";
import { SpkSearchGridComponent } from "../../framework/spk/controls/spk-search-grid/spk-search-grid.component";

@Component({
  selector: "checkpoints",
  templateUrl: "./checkpoints.component.html",
  styleUrls: ["./checkpoints.component.scss"],
})
export class CheckpointsComponent
  extends ControllerForViewBase
  implements OnInit
{
  leftLinks: ILink[] = [];
  @ViewChild("grid", { static: true }) grid: SpkSearchGridComponent;
  constructor(public pcs: PageContainerService) {
    super(pcs);
    this.pcs.actionLinksPanelService.addBackButton();
    this.pcs.actionLinksPanelService.addActionLinks([
      {
        text: "Create ",
        fontAwesomeIcon: "plus",
        onClick: () => {
          this.createCheckpoint();
        },
      },
    ]);
  }

  ngOnInit() {
    this.leftLinks = [
      {
        text: "",
        action: this.editCheckpoint,
        icon: "edit",
        class: "btn-primary",
      },
      {
        text: "",
        reallyClickAction: "deleteCheckpoint",
        reallyClickCaller: this,
        reallyClickMessage: "Are you sure you want to delete this checkpoint?",
        action: this.deleteCheckpoint,
        icon: "trash",
        class: "btn-danger",
      },
    ];
  }

  editCheckpoint = (row: SecurityCheckpointSearchRow) => {
    this.pcs.router.navigate(["checkpoint", row.Id]);
  };
  deleteCheckpoint = (row: SecurityCheckpointSearchRow) => {
    this.pcs.apiService
      .DeleteCheckpoint({ CommandSecurityRoleId: row.Id }, true, true)
      .then((response) => this.refreshGrid());
  };
  createCheckpoint = () => {
    this.pcs.router.navigate(["checkpoint", 0]);
  };

  refreshGrid() {
    this.grid.load();
  }
}
