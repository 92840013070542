import { Component } from "@angular/core";
import {
  SendForgotPasswordEmailCommandRequest,
  SendForgotPasswordEmailCommandResponse,
} from "../../../data/CommandServiceDtos.generated";
import { PageContainerService } from "../../../services/page-container.service";
import { ControllerForViewBase } from "../../framework/controller-for-view-base";
import { log } from "console";

@Component({
  selector: "forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent extends ControllerForViewBase {
  model: SendForgotPasswordEmailCommandRequest = { Email: "" };
  constructor(public pcs: PageContainerService) {
    super(pcs);
    this.setActionlinks();
  }

  setActionlinks() {
    this.pcs.actionLinksPanelService.addBackButton();
  }

  async sendForgotPasswordEmail() {
    await this.pcs.apiService
      .SendForgotPasswordEmail(this.model, true, false)
      .then((response: any) => {
        if (response.Success)
          this.pcs.notificationService.success("Email sent!");
        else this.pcs.notificationService.danger("Failed to send email.");
      });
  }
}
