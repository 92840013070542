import { Component, OnInit } from "@angular/core";
import { UserSearchRow } from "../../../data/CommandServiceDtos.generated";

@Component({
  selector: "spk-lookup-example",
  templateUrl: "./spk-lookup-example.component.html",
  styleUrls: ["./spk-lookup-example.component.scss"],
})
export class SpkLookupExampleComponent implements OnInit {
  label: string = "Lookup Label";

  disabled: boolean = false;

  selectedResult: UserSearchRow;

  constructor() {}

  ngOnInit() {}
}
