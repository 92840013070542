import * as $ from "jquery";
import { Injectable } from "@angular/core";

export class CustomReallyClickButton {
  public method: string = "";
  public caller: any = null;
  public arguments: any[] = [];
  public text: string = "";
}

@Injectable()
export class ReallyClickService {
  public hasCustomButtons: boolean = false;
  public buttons: CustomReallyClickButton[];
  public message: string = "";
  public isVisible: boolean = false;
  public method: string = "";
  public caller: any = null;
  public arguments: any[] = [];
  constructor() {}

  public show(
    message: string,
    method: string,
    caller: any,
    args: any[],
    buttons: CustomReallyClickButton[],
  ) {
    this.isVisible = true;
    this.caller = caller;
    this.message = message;
    this.method = method;
    this.arguments = args;
    this.buttons = buttons;
    this.hasCustomButtons = buttons != null && buttons.length > 0;
    $("#really-click-modal").modal("show");
  }

  public accept() {
    this.caller[this.method].apply(this.caller, this.arguments);
    this.hide();
  }
  invoke(button: CustomReallyClickButton) {
    button.caller[button.method].apply(button.caller, button.arguments);
    this.hide();
  }
  public decline() {
    this.hide();
  }

  private hide() {
    this.isVisible = false;
    this.message = "";
    this.method = "";
    this.caller = null;
    this.arguments = [];
    $("#really-click-modal").modal("hide");
  }
}
