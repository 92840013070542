import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  GetRoleCommandRequest,
  GetCalendarEventCommandRequest,
  SaveCalendarEventCommandRequest,
  ValueTextSelectedDto,
  CalendarEventDTO,
  DateDto,
  LinkFileDto,
} from "../../../data/CommandServiceDtos.generated";
import { ControllerForViewBase } from "../../framework/controller-for-view-base";
import { NotificationService, PageContainerService } from "../../../services";
import { ActivatedRoute, Params } from "@angular/router";
import { CanComponentDeactivate } from "../../../services/can-deactivate-guard.service";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { NgForm } from "../../../ng";
import { DateDtoUtility } from "../../../utilities/dates/date-dto.utility";
import * as moment from 'moment';
import 'moment-timezone';
import { NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";

;

@Component({
  selector: "event",
  templateUrl: "./event.component.html",
  styleUrls: ["./event.component.scss"],
})
export class CalendarEventComponent
  extends ControllerForViewBase
  implements OnInit, CanComponentDeactivate {
  @ViewChild("form", { static: true }) form: NgForm;
  @ViewChild("fileInput", { static: false }) fileInput: ElementRef;

  model: CalendarEventDTO = new CalendarEventDTO();
  dateModel: DateDto;
  eventDateModel: DateDto;
  eventDateToModel: DateDto;

  filesToUpload: File[] = [];
  tempFilesToUpload: File[] = [];
  imageUrl: SafeUrl = "";
  linkFileDto: LinkFileDto = {
    IsLockbox: false,
    LinkFileType: "8",
    LinkFileTypeKey: this.pcs.clientSessionService.userId,
    FileTypeId: 8,
    UserId: this.pcs.clientSessionService.userId,
    FileId: null,
    RecordRefId: null,
  };

  startTime: NgbTimeStruct = { hour: this.getTime().hour, minute: this.getTime().minute, second: 0 };
  endTime: NgbTimeStruct = { hour: this.getTime().hour, minute: this.getTime().minute, second: 0 };

  maxChars = 3500;
  currentChars = 0;

  multiple: boolean = this.model.IsMultipleDate;

  constructor(
    public pcs: PageContainerService,
    private sanitizer: DomSanitizer,
    private notificationService: NotificationService,
    public activatedRoute: ActivatedRoute
  ) {
    super(pcs);
    this.pcs.actionLinksPanelService.addBackButton();
    this.pcs.actionLinksPanelService.addActionLinks([
      {
        text: "Save ",
        fontAwesomeIcon: "save",
        onClick: () => {
          this.submit();
        },
      },
    ]);
    this.pcs.actionLinksPanelService.addActionLinks([
      {
        text: "Save and Close",
        fontAwesomeIcon: "save",
        onClick: () => {
          this.submit(true);
        },
      },
    ]);
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.load(+params["id"]);
    });
  }

  async load(id: number) {
    var payload: GetCalendarEventCommandRequest = {
      Id: id,
    };

    let response = await this.pcs.apiService.GetCalendarEvent(
      payload,
      true,
      false,
    );
    this.model = response;
    this.multiple = response.IsMultipleDate;
    if (id === 0) this.model.IsActive = true;

    //Set Start and End Time'
    this.startTime = {
      hour: this.getTime(this.model.StartTime).hour,
      minute: this.getTime(this.model.StartTime).minute,
      second: 0,
    };

    this.endTime = {
      hour: this.getTime(this.model.EndTime).hour,
      minute: this.getTime(this.model.EndTime).minute,
      second: 0,
    };


    this.dateModel = DateDtoUtility.createDateDtoFromMoment(
      moment(response.PublishDate),
    );
     
    this.eventDateModel = DateDtoUtility.createDateDtoFromMoment(
      moment(response.EventDate),
    );

    this.eventDateToModel = DateDtoUtility.createDateDtoFromMoment(
      moment(response.EventDateTo),
    );

    if (this.model.Id > 0 && this.model.FileId > 0) {
      //Lets use our fileId that we have, to just grab the imageURL from azure
      var image = await this.pcs.executeCommandService.executeFileEndpoint(
        "GetFileUrl",
        { id: this.model.FileId, displayName: "event" },
        true,
        false,
      );
      this.imageUrl = image.url;
    }
  }

  //When a user goes to add a file using the file picker, this method gets called
  handleFileInput(files: FileList) {
    this.filesToUpload = [];
    for (let i = 0; i < files.length; i++) {
      this.filesToUpload.push(files[i]);
    }
    this.tempFilesToUpload = this.filesToUpload;
    this.uploadFile();
  }

  handleEventEditorLoad(e: any) {
    try {
      e?.editor?.setContent(this.model.Description);
      this.currentChars =
        e?.editor?.plugins?.wordcount?.body?.getCharacterCount();
    } catch (error) {
      setTimeout(() => {
        e?.editor?.setContent(this.model.Description);
        this.currentChars =
          e?.editor?.plugins?.wordcount?.body?.getCharacterCount();
      }, 1000);
    }
  }

  handleEventEditorChange(e: any) {
    const value = e.event?.level?.content || e.event?.currentTarget?.innerHTML;
    if (value?.replace(/(<([^>]+)>)/gi, "")?.length > this.maxChars) {
      this.notificationService.warning(
        `The entered text is too long. It exceeds the allowed limit of ${this.maxChars} characters`,
      );
      setTimeout(this.notificationService.removePersistents, 5000);
      e.editor?.undoManager?.undo();
    } else {
      this.model.Description = value;
      const count = e?.editor?.plugins?.wordcount?.body?.getCharacterCount();
      this.currentChars = count;
    }
  }

  handleEventEditorKeyUp(e: any) {
    const count = e?.editor?.plugins?.wordcount?.body?.getCharacterCount();

    if (
      count >= this.maxChars &&
      !["KeyA", "Backspace", "KeyV"].includes(e.event.code)
    ) {
      e.event.stopPropagation();
      e.event.preventDefault();
    }
  }

  async uploadFile() {
    //this returns a response with the fileLinkId which we can use to then grab the file ID from the file table.
    var response = await this.pcs.executeCommandService.executeFileEndpoint(
      "Upload",
      { files: this.filesToUpload, linkFileDto: this.linkFileDto },
      true,
      true,
    );
    var fileLinkId = response.Payload[0];

    var fileInfo = await this.pcs.apiService.CheckIfFileExists({
      LinkFileDto: null,
      FileLinkId: fileLinkId,
      FileTypeId: this.linkFileDto.FileTypeId,
    });

    this.model.FileId = fileInfo.FileId;
    this.imageUrl = fileInfo.ImageUrl;

    if (this.model.Id === 0) this.tempFilesToUpload = this.filesToUpload;
    this.filesToUpload = [];
    this.fileInput.nativeElement.value = null;
  }

  async submit(navigateaway: boolean = false): Promise<void> {
    this.setStartAndEndTimes();

    var payload: SaveCalendarEventCommandRequest = {
      Event: this.model,
    };
    let saveResponse = await this.pcs.apiService.SaveCalendarEvent(
      payload,
      undefined,
      true,
    );
    if (saveResponse.Id !== 0) {
      this.form.form.markAsPristine();
      this.updateUrlForNewEntity(["/calendar-event", saveResponse.Id]);
      if (navigateaway) {
        this.pcs.router.navigate(["calendar-events"]);
      } else {
        this.load(saveResponse.Id);
      }
    }
  }

  canDeactivate(): Promise<boolean> {
    var promise = Promise.resolve(true);
    if (this.form.dirty) {
      return this.pcs.showUnsavedChangesDialog();
    }
    return promise;
  }

  setStartAndEndTimes() {
    if (this.dateModel) {
      var day = this.dateModel.Day;
      var month = this.dateModel.Month;
      var year = this.dateModel.Year;
      this.model.PublishDate = new Date(Date.UTC(year, month - 1, day));
    }

    if (this.multiple && this.eventDateToModel) {
      var day = this.eventDateToModel.Day;
      var month = this.eventDateToModel.Month;
      var year = this.eventDateToModel.Year;
      this.model.EventDateTo = new Date(Date.UTC(year, month - 1, day));
    }

    if (this.eventDateModel) {
      var day = this.eventDateModel.Day;
      var month = this.eventDateModel.Month;
      var year = this.eventDateModel.Year;
      this.model.EventDate = new Date(Date.UTC(year, month - 1, day));
      this.model.StartTime = new Date(Date.UTC(
        
          year,
          month - 1,
          day,
          this.startTime.hour,
          this.startTime.minute,
        this.startTime.second,)
      ).toUTCString();

      this.model.EndTime = new Date(
        Date.UTC(
          year,
          month - 1,
          day,
          this.endTime.hour,
          this.endTime.minute,
          this.endTime.second,)
      ).toUTCString();
    }
  }

  getTime(val?: string): NgbTimeStruct {

    const defaultTime = { hour: 12, minute: 30, second: 0 };

    if (val !== null) {
      const momentDate = moment(val, 'MM/DD/YYYY h:mm:ss a');
      const formattedDate = momentDate.format('YYYY-MM-DD HH:mm:ss');

      const time = {
        hour: new Date(formattedDate).getHours(),
        minute: new Date(formattedDate).getMinutes(),
        second: 0,
      };

      return time;
    }

    return defaultTime;
  }

  resetEndHr() {
    if (this.endTime.hour < this.startTime.hour || this.endTime.minute < this.startTime.minute || (this.endTime.hour === 23 && this.startTime.hour === 12)) {
      this.endTime = {
        hour: this.startTime.hour,
        minute: this.startTime.minute,
        second: 0,
      }
    }

  }

  multipleDays(event: boolean) {
    this.multiple = event;

    if (!event) {
      this.eventDateToModel = null;
      this.model.EventDateTo = null;
    }
  }

  eventToDateHandler(event: DateDto) {
    if (event) {
      var day = event.Day;
      var month = event.Month;
      var year = event.Year;
      this.model.EventDateTo = new Date(year, month - 1, day);

    }

  }

}
