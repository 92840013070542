import * as _ from "lodash";
import { Injectable } from "@angular/core";

@Injectable()
export class ValidationResultsService {
  constructor() {}

  public successMessages: string[] = [];
  public errorMessages: string[] = [];
  public warningMessages: string[] = [];
  public infoMessages: string[] = [];

  setSuccessMessages(messages: string[]) {
    this.successMessages = messages;
    var message = _.join(this.successMessages, "</b>");
  }

  hideSuccessMessages() {
    this.successMessages = [];
  }

  setErrorMessages(messages: string[]) {
    this.errorMessages = messages;
  }

  hideErrorMessages() {
    this.errorMessages = [];
  }

  setWarningMessages(messages: string[]) {
    this.warningMessages = messages;
  }

  hideWarningMessages() {
    this.warningMessages = [];
  }

  setInfoMessages(messages: string[]) {
    this.infoMessages = messages;
  }

  reset() {
    this.successMessages = [];
    this.errorMessages = [];
    this.warningMessages = [];
    this.infoMessages = [];
  }
}
