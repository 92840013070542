import { OnInit } from "../ng";
import * as Data from "../data";
import {
  ValueTextDto,
  ValueTextSelectedDto,
  SecurityCheckpointAccessDto,
  AuthenticationType,
} from "../data/CommandServiceDtos.generated";
import * as _ from "lodash";
import { Injectable } from "@angular/core";

@Injectable()
export class ClientSessionService implements OnInit {
  private loginSubscribers: Function[] = [];
  private resetSubscribers: Function[] = [];
  private emptyUserInfo: Data.Web.UserInfoDto = {
    Id: 0,
    UserName: "",
    FirstName: "",
    LastName: "",
    Roles: [],
    EmailAddress: "",
    LogCommands: false,
    Checkpoints: [],
    PhoneNumber: "",
    UserAccountTypeId: 0,
    AccountType: null,
    HasOnboarded: false,
    SocialId: "",
    PendingMobileUser: true,
  };
  private hasLoginBeenCalled: boolean = false;

  subscribeToLogin(callback: Function) {
    this.loginSubscribers.push(callback);
  }
  subscribeToReset(callback: Function) {
    this.resetSubscribers.push(callback);
  }

  get userInfo(): Data.Web.UserInfoDto {
    var result = this.emptyUserInfo;
    var jsonData = window.localStorage.getItem("userInfo");
    if (jsonData != null) {
      result = JSON.parse(jsonData);
    }

    return result;
  }
  set userInfo(value: Data.Web.UserInfoDto) {
    var jsonData = JSON.stringify(value);
    window.localStorage.setItem("userInfo", jsonData);
  }

  loginUser(userInfoDto: Data.Web.UserInfoDto) {
    this.hasLoginBeenCalled = true;
    this.userInfo = userInfoDto;
    for (let i = 0; i < this.loginSubscribers.length; i++) {
      this.loginSubscribers[i]();
    }
  }

  constructor() {}

  get loggedIn() {
    var result = this.userInfo.Id > 0;
    return result;
  }

  public reset() {
    this.hasLoginBeenCalled = false;
    window.localStorage.removeItem("userInfo");
    for (let i = 0; i < this.resetSubscribers.length; i++) {
      this.resetSubscribers[i]();
    }
  }

  ngOnInit() {}

  get userName(): string {
    if (this.userInfo) return this.userInfo.UserName;
    else return "";
  }

  get userId(): number {
    if (this.userInfo) return this.userInfo.Id;
    else return 0;
  }

  get roles(): ValueTextDto[] {
    if (this.userInfo) return this.userInfo.Roles;
    else return [];
  }

  get isAdmin(): Boolean {
    var result =
      this.roles.filter((item) => {
        return item.Text == "Admin";
      }).length > 0;
    return result;
  }

  public isInRoleName(role: string): boolean {
    var result =
      this.roles.filter((item) => {
        return item.Text == role;
      }).length > 0;
    return result;
  }

  public isInRoleId(roleId: number): boolean {
    var result =
      this.roles.filter((item) => {
        return item.Value == roleId;
      }).length > 0;
    return result;
  }

  get firstName(): string {
    if (this.userInfo) return this.userInfo.FirstName;
    else return "";
  }

  get lastName(): string {
    if (this.userInfo) return this.userInfo.LastName;
    else return "";
  }

  checkpoint(name: string): boolean {
    let result: boolean = false;
    let checkpointExists: boolean = false;
    for (let i = 0; i < this.userInfo.Checkpoints.length; i++) {
      const checkpoint = this.userInfo.Checkpoints[i];
      if (checkpoint.Name === name) {
        checkpointExists = true;
        result = checkpoint.HasAccess;
        break;
      }
    }
    if (!checkpointExists) result = true;
    return result;
  }
}
