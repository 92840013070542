import { Component, OnInit, ViewChild } from "@angular/core";
import { ILink } from "../../../data";
import { PageContainerService } from "../../../services";
import { ControllerForViewBase } from "../../framework/controller-for-view-base";
import {
  NewsArticleSearchRow,
  DeleteNewsArticleCommandRequest,
} from "../../../data/CommandServiceDtos.generated";
import { SpkSearchGridComponent } from "../../framework/spk/controls/spk-search-grid/spk-search-grid.component";
import {
  ComparisonOperator,
  FilterDataType,
  FilterSpecification,
  LogicalOperator,
} from "../../../data/framework/searchObjects";

@Component({
  selector: "articles",
  templateUrl: "./articles.component.html",
  styleUrls: ["./articles.component.scss"],
})
export class NewsArticlesComponent
  extends ControllerForViewBase
  implements OnInit
{
  filter: FilterSpecification;
  showInactive: boolean = false;
  leftLinks: ILink[] = [];
  @ViewChild("grid", { static: true }) grid: SpkSearchGridComponent;

  customFormats: any = {
    Start: (cellValue) => this.formatAMPM(new Date(cellValue)),
    End: (cellValue) => this.formatAMPM(new Date(cellValue)),
  };

  constructor(public pcs: PageContainerService) {
    super(pcs);
    this.pcs.actionLinksPanelService.addBackButton();
    this.pcs.actionLinksPanelService.addActionLinks([
      {
        text: "Create ",
        fontAwesomeIcon: "plus",
        onClick: () => {
          this.createArticle();
        },
      },
    ]);
    this.setFilterSpecification();
  }

  ngOnInit() {
    this.leftLinks = [
      {
        text: "",
        action: this.editArticle,
        icon: "edit",
        class: "btn-primary",
      },
      {
        text: "",
        reallyClickAction: "deleteArticle",
        reallyClickCaller: this,
        reallyClickMessage: "Are you sure you want to delete this article?",
        action: this.deleteArticle,
        icon: "trash",
        class: "btn-danger",
      },
    ];
  }

  formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'  minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime =
      hours + ":" + minutes.toString().padStart(2, "0") + " " + ampm;
    return strTime;
  }

  setFilterSpecification() {
    this.filter = new FilterSpecification();
    this.filter.Filters = [];
    this.filter.JoinType = LogicalOperator.And;

    if (!this.showInactive) {
      this.filter.Parameters = [
        {
          Comparison: ComparisonOperator.Equals,
          DataType: FilterDataType.Boolean,
          FieldName: "IsActive",
          Filter: "true",
        },
      ];
    }
  }

  editArticle = (row: NewsArticleSearchRow) => {
    this.pcs.router.navigate(["news-article", row.Id]);
  };
  deleteArticle = (row: NewsArticleSearchRow) => {
    var payload: DeleteNewsArticleCommandRequest = {
      Id: row.Id,
    };
    this.pcs.apiService
      .DeleteNewsArticle(payload, true, true)
      .then((response) => this.refreshGrid());
  };
  createArticle = () => {
    this.pcs.router.navigate(["news-article", 0]);
  };

  refreshGrid() {
    this.grid.load();
  }
}
