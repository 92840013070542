import { DateDto } from "../../data/CommandServiceDtos.generated";
import * as moment from "moment";

export class DateDtoUtility {
  public static differenceInDays(startDate: DateDto, endDate: DateDto): number {
    var startDateMoment = this.getMoment(startDate);
    var endDateMoment = this.getMoment(endDate);
    var result = endDateMoment.diff(startDateMoment, "days");
    return result;
  }

  public static createDateDtoFromString(date: string): DateDto {
    var dateDto: DateDto;
    if (this.isDateStringValid(date)) {
      var dateParts = date.split("/");
      dateDto = {
        Month: <any>dateParts[0],
        Day: <any>dateParts[1],
        Year: <any>dateParts[2],
      };
    } else {
      dateDto = null;
    }
    return dateDto;
  }
  public static createDateDtoFromMoment(moment: moment.Moment): DateDto {
    var result = this.createDateDtoFromParts(
      moment.year(),
      moment.month() + 1,
      moment.date(),
    );
    return result;
  }
  public static createDateDtoFromParts(
    year: number,
    month: number,
    day: number,
  ): DateDto {
    var result: DateDto = {
      Day: day,
      Month: month,
      Year: year,
    };
    return result;
  }
  public static isDateStringValid(date: string) {
    if (date == undefined || date == null || date == "") {
      return false;
    }
    var dateParts = date.split("/");
    if (dateParts.length != 3) {
      return false;
    }
    if (
      isNaN(<any>dateParts[0]) ||
      isNaN(<any>dateParts[1]) ||
      isNaN(<any>dateParts[2])
    ) {
      return false;
    }
    return true;
  }
  public static isDateDtoValid(date: DateDto) {
    if (date == undefined || date == null) {
      return false;
    }
    if (isNaN(date.Year) || isNaN(date.Month) || isNaN(date.Day)) {
      return false;
    }
    return true;
  }
  public static getMoment(date): moment.Moment {
    var formattedDate = this.formatDateStringForMoment(date);
    var result = moment(formattedDate);
    return result;
  }
  public static formatDateStringForMoment(date: DateDto): string {
    var result = "";
    if (this.isDateDtoValid(date)) {
      result = `${date.Year.toString().padStart(
        4,
        "0",
      )}-${date.Month.toString().padStart(
        2,
        "0",
      )}-${date.Day.toString().padStart(2, "0")}T00:00:00`;
    } else {
      result = "";
    }
    return result;
  }
  public static formatDateStringMMDDYYYY(date: DateDto): string {
    var result = "";
    if (this.isDateDtoValid(date)) {
      result = `${date.Month.toString().padStart(
        2,
        "0",
      )}/${date.Day.toString().padStart(2, "0")}/${date.Year.toString()}`;
    } else {
      result = "";
    }
    return result;
  }
  public static today(): DateDto {
    var todayMoment = moment();
    var result = this.createDateDtoFromMoment(todayMoment);
    return result;
  }
  public static datesAreTheSame(date1: DateDto, date2: DateDto): boolean {
    var result = false;
    if (date1 && date2) {
      var result =
        date1.Year == date2.Year &&
        date1.Month == date2.Month &&
        date1.Day == date2.Day;
    }
    return result;
  }
  public static addDays(date: DateDto, days: number): DateDto {
    var dateMoment = this.getMoment(date);
    var newMoment = dateMoment.add("days", days);
    var result = this.createDateDtoFromMoment(newMoment);
    return result;
  }
  public static compareDates(date1: DateDto, date2: DateDto): CompareResult {
    var date1Moment = this.getMoment(date1);
    var date2Moment = this.getMoment(date2);
    if (date1Moment > date2Moment) {
      return CompareResult.GreaterThan;
    } else if (date1Moment < date2Moment) {
      return CompareResult.LessThan;
    } else {
      return CompareResult.Same;
    }
  }
}

export enum CompareResult {
  Same = 1,
  GreaterThan = 2,
  LessThan = 3,
}
