import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Observable } from "../ng";
import { ClientSessionService } from "./client-session.service";

@Injectable()
export class CanActivateGuard implements CanActivate {
  constructor(
    private router: Router,
    private clientSessionService: ClientSessionService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | Observable<boolean> | Promise<boolean> {
    if (route.url && route.url[0] && route.url[0].path) {
      let baseRoutePath = route.url[0].path;
      if (!this.isUserLoggedIn()) {
        this.router.navigateByUrl(`/login?redirectRoute=${baseRoutePath}`);
        return false;
      } else if (!this.userIsAuthorizedForRoute(baseRoutePath)) {
        this.router.navigate(["/not-authorized"], { replaceUrl: true });
        return false;
      }
    }
    return true;
  }
  isUserLoggedIn(): boolean {
    return this.clientSessionService.loggedIn;
  }
  userIsAuthorizedForRoute(url: string): boolean {
    return this.clientSessionService.checkpoint(url);
  }
}
