import { Component, OnInit } from "@angular/core";
import { PageContainerService } from "../../../services";
import { ControllerForViewBase } from "../../framework/controller-for-view-base";

@Component({
  selector: "admin-type-links",
  templateUrl: "./admin-type-links.component.html",
  styleUrls: ["./admin-type-links.component.scss"],
})
export class AdminTypeLinksComponent
  extends ControllerForViewBase
  implements OnInit
{
  constructor(public pcs: PageContainerService) {
    super(pcs);
    this.pcs.actionLinksPanelService.addBackButton();
  }

  ngOnInit() {}
}
