import { Component, OnInit, Output, Input, EventEmitter } from "@angular/core";
import * as Paging from "../../../../../data/framework/searchObjects";
@Component({
  selector: "spk-pager",
  templateUrl: "./spk-pager.component.html",
  styleUrls: ["./spk-pager.component.scss"],
})
export class SpkPagerComponent implements OnInit {
  @Input() public pagedResult: Paging.IPagedResult<any>;
  @Input() hasLoaded: boolean;
  private oldPagedResult: Paging.IPagedResult<any>;
  @Output() change = new EventEmitter();
  pageBlock: any[];
  isLastBlock: boolean = false;
  isFirstBlock: boolean = false;
  isFirstPage: boolean = false;
  isLastPage: boolean = false;
  hasRecords: boolean = false;
  totalPages: number = 0;
  blockSize: number = 10;
  nextBlockPage: number;
  prevBlockPage: number;
  recordsVerbiage: string;

  constructor() {
    this.pageBlock = [];
    this.isLastBlock = false;
    this.isFirstBlock = false;
    this.isFirstPage = false;
    this.isLastPage = false;
    this.hasRecords = false;
    this.totalPages = 0;
    this.blockSize = 10;
    this.hasRecords = false;
  }

  ngOnInit() {}
  public gridStateChanged(newValue, oldValue) {
    if (newValue != null) {
      this.setup();
    }
  }

  public resetPagingIfNeeded = () => {
    if (this.pagedResult != null) this.pagedResult.PaginationInfo.PageIndex = 1;
  };
  ngDoCheck() {
    if (this.pagedResult.Data.length == 0) {
      this.pagedResult.PaginationInfo.PageIndex = 1;
      this.pagedResult.TotalCount = 0;
      this.hasRecords = false;
      return;
    } else {
      this.setup();
    }
  }
  public setup = () => {
    if (this.pagedResult.TotalCount == undefined) {
      this.hasRecords = false;
      return;
    }
    this.hasRecords = this.pagedResult.TotalCount !== 0;

    this.totalPages = Math.ceil(
      <number>this.pagedResult.TotalCount /
        <number>this.pagedResult.PaginationInfo.PageSize,
    );
    var blocks = [];
    var blockNumber =
      Math.ceil(
        <number>this.pagedResult.PaginationInfo.PageIndex /
          <number>this.blockSize,
      ) - 1;
    var blockStart = blockNumber * this.blockSize;
    var min = blockStart + 1;
    var max = blockStart + this.blockSize;

    for (let i = 1; i < this.blockSize + 1; i++) {
      if (blockStart + i < this.totalPages + 1)
        blocks.push({
          page: blockStart + i,
          isActive:
            blockStart + i === this.pagedResult.PaginationInfo.PageIndex,
        });
    }
    // check whether the blocks are the same
    let stillChecking = true;
    let length = Math.max(blocks.length, this.pageBlock.length);
    for (let i = 0; stillChecking && i < length; i++) {
      var b1 = blocks[i];
      var b2 = this.pageBlock[i];
      // if any difference then go ahead and update the blocks.
      if (!b1 || !b2 || b1.page !== b2.page || b1.isActive !== b2.isActive) {
        this.pageBlock = blocks;
        stillChecking = false;
      }
    }

    var pageNumber: number = +this.pagedResult.PaginationInfo.PageIndex;
    this.prevBlockPage = min - 1;
    this.nextBlockPage = max + 1;

    var pageSize = +this.pagedResult.PaginationInfo.PageSize;
    var totalCount = +this.pagedResult.TotalCount;

    this.isLastBlock = this.pageBlock.length > 0 && max >= this.totalPages;
    this.isFirstBlock = min === 1;
    this.isFirstPage = pageNumber === 1;
    this.isLastPage = this.totalPages === pageNumber;
    var startRow: number = (pageNumber - 1) * pageSize + 1;

    var endRow: number = startRow + pageSize - 1;
    if (this.isLastPage) {
      endRow = totalCount;
    }
    this.recordsVerbiage =
      "Showing [" +
      startRow +
      "-" +
      endRow +
      "] of [ " +
      totalCount +
      " ] total results";
  };
  public changePageSize() {
    this.pagedResult.PaginationInfo.PageIndex = 1;
    this.update();
  }
  public update = () => {
    this.page(<number>this.pagedResult.PaginationInfo.PageIndex);
  };
  public page = (number: number) => {
    this.pagedResult.PaginationInfo.PageIndex = number;
    this.change.emit({});
  };
  public firstPage = () => {
    return this.page(1);
  };
  public prevBlock = () => {
    return this.page(this.prevBlockPage);
  };
  public prevPage = () => {
    return this.page(<number>this.pagedResult.PaginationInfo.PageIndex - 1);
  };
  public nextPage = () => {
    return this.page(<number>this.pagedResult.PaginationInfo.PageIndex + 1);
  };
  public nextBlock = () => {
    return this.page(this.nextBlockPage);
  };
  public lastPage = () => {
    return this.page(this.totalPages);
  };
}
