import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { NgForm } from "../../../ng";
import { PageContainerService } from "../../../services";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "import-checkpoints",
  templateUrl: "./import-checkpoints.component.html",
  styleUrls: ["./import-checkpoints.component.scss"],
})
export class ImportCheckpointsComponent implements OnInit {
  @ViewChild("form", { static: true }) form: NgForm;
  @ViewChild("fileInput", { static: false }) fileInput: ElementRef;

  filesToUpload: File[] = [];

  constructor(
    public pcs: PageContainerService,
    public http: HttpClient,
  ) {}

  ngOnInit() {}

  handleFileInput(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      this.filesToUpload.push(files[i]);
    }
  }

  async importFile() {
    await this.pcs.executeCommandService.executeFileEndpoint(
      "ImportCsvSecurityCheckpoints",
      { files: this.filesToUpload },
      true,
      true,
    );
    this.filesToUpload = [];
    this.fileInput.nativeElement.value = null;
  }

  exportSecurityCheckpoints() {
    this.pcs.validationResultsService.reset();
    this.pcs.notificationService.removePersistents();

    var promise = this.http
      .get(`SecurityCheckpointCsvExport`, { responseType: "blob" })
      .toPromise<any>();
    promise.then((response) => {
      let blob = response.blob();
      let blobUrl = window.URL.createObjectURL(blob);
      var link = document.getElementById("downloadLink");
      var date = new Date().toLocaleString();
      link.setAttribute("href", blobUrl);
      link.setAttribute("download", `SecurityCheckpointExport-${date}.csv`);
      link.click();
    });
    this.pcs.notificationService.success("CSV Download Queued");
  }
}
