import { Component, OnInit } from "@angular/core";
import { ControllerForViewBase } from "../../controller-for-view-base";
import { ILink } from "../../../../data";
import { PageContainerService } from "../../../../services";
import { ErrorLogSearchRow } from "../../../../data/CommandServiceDtos.generated";

@Component({
  selector: "error-logs",
  templateUrl: "./error-logs.component.html",
  styleUrls: ["./error-logs.component.scss"],
})
export class ErrorLogsComponent extends ControllerForViewBase {
  leftLinks: ILink[];
  constructor(public pcs: PageContainerService) {
    super(pcs);
    this.pcs.actionLinksPanelService.addBackButton();
    this.leftLinks = [
      {
        text: "",
        action: (row) => this.openErrorLog(row),
        icon: "folder-open",
        class: "btn-primary",
      },
    ];
  }

  openErrorLog(row: ErrorLogSearchRow) {
    this.pcs.router.navigate(["/error-log", row.Id]);
  }

  ngOnInit() {}
}
