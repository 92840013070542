import { Component } from "@angular/core";
import { ControllerForViewBase } from "../controller-for-view-base";
import { PageContainerService } from "../../../services";

@Component({
  selector: "not-authorized",
  templateUrl: "./not-authorized.component.html",
  styleUrls: ["./not-authorized.component.scss"],
})
export class NotAuthorizedComponent extends ControllerForViewBase {
  constructor(public pcs: PageContainerService) {
    super(pcs);
    this.setActionlinks();
  }

  setActionlinks() {
    this.pcs.actionLinksPanelService.addBackButton();
  }
}
