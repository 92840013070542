import { Component } from "@angular/core";
import { ControllerForViewBase } from "../../framework/controller-for-view-base";
import { OnInit } from "../../../ng";
import { PageContainerService } from "../../../services";
import * as Data from "../../../data";
import { ILink } from "../../../data";

@Component({
  selector: "search-tests",
  templateUrl: "./search-tests.component.html",
  styleUrls: ["./search-tests.component.scss"],
})
export class SearchTestsComponent
  extends ControllerForViewBase
  implements OnInit
{
  leftLinks: ILink[];
  rightLinks: ILink[];
  headerLinks: ILink[];
  public filter: Data.Search.FilterSpecification;

  constructor(protected pcs: PageContainerService) {
    super(pcs);

    this.leftLinks = [
      { text: "", action: this.actionPlaceholder, icon: "edit" },
      { text: "", action: this.actionPlaceholder, icon: "plus" },
    ];

    this.rightLinks = [
      { text: "", action: this.actionPlaceholder, icon: "times" },
      { text: "", action: this.actionPlaceholder, icon: "search" },
    ];

    this.headerLinks = [
      {
        text: "Success Message",
        action: () => this.setSuccessMessage(),
        icon: "envelope",
        class: "btn-success",
      },
      {
        text: "Warning Message",
        action: () => this.setWarningMessage(),
        icon: "envelope",
        class: "btn-warning",
      },
      {
        text: "Error Message",
        action: () => this.setErrorMessage(),
        icon: "envelope",
        class: "btn-danger",
      },
      {
        text: "Info Message",
        action: () => this.setInfoMessage(),
        icon: "envelope",
        class: "btn-info",
      },
    ];

    this.pcs.actionLinksPanelService.addBackButton();
    this.pcs.actionLinksPanelService.addActionLinks([
      {
        text: "Create Search Template",
        fontAwesomeIcon: "plus",
        onClick: () => {},
      },
    ]);
  }

  ngOnInit() {}

  actionPlaceholder() {}

  setSuccessMessage() {
    this.pcs.validationResultsService.setSuccessMessages([
      "Test Message 1",
      "Test Message 2",
    ]);
  }

  setWarningMessage() {
    this.pcs.validationResultsService.setWarningMessages([
      "Test Message 1",
      "Test Message 2",
    ]);
  }

  setErrorMessage() {
    this.pcs.validationResultsService.setErrorMessages([
      "Test Message 1",
      "Test Message 2",
    ]);
  }

  setInfoMessage() {
    this.pcs.validationResultsService.setInfoMessages([
      "Test Message 1",
      "Test Message 2",
    ]);
  }
}
