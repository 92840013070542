import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  GetRoleCommandRequest,
  SaveResourceCommandRequest,
  ResourceDTO,
  DateDto,
  ValueTextDto,
  GetResourceCategoryCommandRequest,
  GetSubCategoryTypesCommandRequest,
  ValueTextDescriptionDto,
} from "../../../data/CommandServiceDtos.generated";
import { ControllerForViewBase } from "../../framework/controller-for-view-base";
import { PageContainerService } from "../../../services";
import { ActivatedRoute, Params } from "@angular/router";
import { CanComponentDeactivate } from "../../../services/can-deactivate-guard.service";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { NgForm } from "../../../ng";
import { DateDtoUtility } from "../../../utilities/dates/date-dto.utility";
import moment = require("moment");

@Component({
  selector: "resource",
  templateUrl: "./resource.component.html",
  styleUrls: ["./resource.component.scss"],
})
export class ResourceComponent
  extends ControllerForViewBase
  implements OnInit, CanComponentDeactivate
{
  @ViewChild("form", { static: true }) form: NgForm;
  @ViewChild("fileInput", { static: false }) fileInput: ElementRef;
  model: ResourceDTO = new ResourceDTO();
  dateModel: DateDto;
  eventDateModel: DateDto;
  filesToUpload: File[] = [];
  tempFilesToUpload: File[] = [];
  imageUrl: SafeUrl = "";
  categoryList: ValueTextDto[] = [];
  subCategoryList: ValueTextDescriptionDto[] = [];
  filteredSubCategoryList: ValueTextDescriptionDto[] = [];

  constructor(
    public pcs: PageContainerService,
    private sanitizer: DomSanitizer,
    public activatedRoute: ActivatedRoute,
  ) {
    super(pcs);
    this.pcs.actionLinksPanelService.addBackButton();
    this.pcs.actionLinksPanelService.addActionLinks([
      {
        text: "Save ",
        fontAwesomeIcon: "save",
        onClick: () => {
          this.submit();
        },
      },
    ]);
    this.pcs.actionLinksPanelService.addActionLinks([
      {
        text: "Save and Close",
        fontAwesomeIcon: "save",
        onClick: () => {
          this.submit(true);
        },
      },
    ]);
  }

  async ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.load(+params["id"]);
    });
    let request: GetResourceCategoryCommandRequest = {};
    var response = await this.pcs.apiService.GetResourceCategory(
      request,
      true,
      false,
    );
    this.categoryList = response.FileTypeList;

    let request2: GetSubCategoryTypesCommandRequest = {};
    var response2 = await this.pcs.apiService.GetSubCategoryTypes(
      request2,
      true,
      false,
    );
    this.subCategoryList = response2.SubCategoryTypeDescriptionList;
  }

  async load(id: number) {
    var payload: GetRoleCommandRequest = {
      Id: id,
    };

    let request2: GetSubCategoryTypesCommandRequest = {};
    var response2 = await this.pcs.apiService.GetSubCategoryTypes(
      request2,
      true,
      false,
    );
    this.subCategoryList = response2.SubCategoryTypeDescriptionList;

    let response = await this.pcs.apiService.GetResource(payload, true, false);
    this.model = response;
    if (id === 0) this.model.IsActive = true;

    this.categorySelected(this.model.CategoryId);

    this.dateModel = DateDtoUtility.createDateDtoFromMoment(
      moment(response.PublishDate),
    );
  }

  async submit(navigateaway: boolean = false): Promise<void> {
    if (this.dateModel != null || this.dateModel != undefined) {
      var day = this.dateModel.Day;
      var month = this.dateModel.Month;
      var year = this.dateModel.Year;
      this.model.PublishDate = new Date(Date.UTC(year, month - 1, day));

    } else {
      this.model.PublishDate = null;
    }

    var payload: SaveResourceCommandRequest = {
      Resource: this.model,
    };
    let saveResponse = await this.pcs.apiService.SaveResource(
      payload,
      undefined,
      true,
    );
    if (saveResponse.Id !== 0) {
      this.form.form.markAsPristine();
      this.updateUrlForNewEntity(["/resource", saveResponse.Id]);
      if (navigateaway) {
        window.history.back();
      } else {
        this.load(saveResponse.Id);
      }
    }
  }

  canDeactivate(): Promise<boolean> {
    var promise = Promise.resolve(true);
    if (this.form.dirty) {
      return this.pcs.showUnsavedChangesDialog();
    }
    return promise;
  }

  public categorySelected(selectedCategory: number, reset: boolean = false) {
    if (reset) {
      this.model.SubCategoryTypeId = null;
    }

    if (selectedCategory !== null) {
      this.filteredSubCategoryList = this.subCategoryList.filter(
        (m) => m.Description == selectedCategory.toString(),
      );
    }
  }
}
