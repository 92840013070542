import { Component, OnInit, ViewChild } from "@angular/core";
import {
  RoleDto,
  GetRoleCommandRequest,
  SaveRoleCommandRequest,
  ValueTextSelectedDto,
} from "../../../data/CommandServiceDtos.generated";
import { ControllerForViewBase } from "../../framework/controller-for-view-base";
import { PageContainerService } from "../../../services";
import { ActivatedRoute, Params } from "@angular/router";
import { CanComponentDeactivate } from "../../../services/can-deactivate-guard.service";
import { NgForm } from "../../../ng";

@Component({
  selector: "role",
  templateUrl: "./role.component.html",
  styleUrls: ["./role.component.scss"],
})
export class RoleComponent
  extends ControllerForViewBase
  implements OnInit, CanComponentDeactivate
{
  @ViewChild("form", { static: true }) form: NgForm;
  model: RoleDto = new RoleDto();
  widgets: ValueTextSelectedDto[] = [];

  constructor(
    public pcs: PageContainerService,
    public activatedRoute: ActivatedRoute,
  ) {
    super(pcs);
    this.pcs.actionLinksPanelService.addBackButton();
    this.pcs.actionLinksPanelService.addActionLinks([
      {
        text: "Save ",
        fontAwesomeIcon: "save",
        onClick: () => {
          this.submit();
        },
      },
    ]);
    this.pcs.actionLinksPanelService.addActionLinks([
      {
        text: "Save and Close",
        fontAwesomeIcon: "save",
        onClick: () => {
          this.submit(true);
        },
      },
    ]);
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.load(+params["id"]);
    });
  }

  async load(id: number) {
    var payload: GetRoleCommandRequest = {
      Id: id,
    };

    let response = await this.pcs.apiService.GetRole(payload, true, false);
    this.model = response.Role;
    this.widgets = response.RoleWidgets;
  }

  async submit(navigateaway: boolean = false): Promise<void> {
    var payload: SaveRoleCommandRequest = {
      Role: this.model,
      RoleWidgets: this.widgets,
    };
    let saveResponse = await this.pcs.apiService.SaveRole(
      payload,
      undefined,
      true,
    );
    if (saveResponse.Id !== 0) {
      this.form.form.markAsPristine();
      this.updateUrlForNewEntity(["/role", saveResponse.Id]);
      if (navigateaway) {
        window.history.back();
      } else {
        this.load(saveResponse.Id);
      }
    }
  }

  canDeactivate(): Promise<boolean> {
    var promise = Promise.resolve(true);
    if (this.form.dirty) {
      return this.pcs.showUnsavedChangesDialog();
    }
    return promise;
  }
}
