import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "../../../ng";
import { ControllerForViewBase } from "../../framework/controller-for-view-base";
import { PageContainerService } from "../../../services";
import { CanComponentDeactivate } from "../../../services/can-deactivate-guard.service";
import { ActivatedRoute, Params } from "@angular/router";
import {
  SecurityCheckpointDto,
  ValueTextSelectedDto,
  ValueTextDto,
} from "../../../data/CommandServiceDtos.generated";
import * as $ from "jquery";
import { SpkLookupFormat } from "../../framework/spk/controls/spk-lookup/spk-lookup.component";
import { SearchUtility } from "../../../utilities/search/search.utility";
import {
  LogicalOperator,
  ComparisonOperator,
  FilterDataType,
  FilterSpecification,
} from "../../../data/framework/searchObjects";

@Component({
  selector: "checkpoint",
  templateUrl: "./checkpoint.component.html",
  styleUrls: ["./checkpoint.component.scss"],
})
export class CheckpointComponent
  extends ControllerForViewBase
  implements OnInit, CanComponentDeactivate
{
  @ViewChild("form", { static: true }) form: NgForm;
  public model: SecurityCheckpointDto = new SecurityCheckpointDto();
  public roles: ValueTextSelectedDto[] = [];
  public availableCommands: ValueTextDto[] = [];

  commandNameFilter: FilterSpecification;

  commandNameInputFormat: SpkLookupFormat[] = [
    {
      columnNameElement: "Command",
      stringLiteralElement: null,
    },
  ];

  constructor(
    public pcs: PageContainerService,
    public activatedRoute: ActivatedRoute,
  ) {
    super(pcs);
    this.pcs.actionLinksPanelService.addBackButton();
    this.pcs.actionLinksPanelService.addActionLinks([
      {
        text: "Save ",
        fontAwesomeIcon: "save",
        onClick: () => {
          this.submit();
        },
      },
    ]);
    this.pcs.actionLinksPanelService.addActionLinks([
      {
        text: "Save and Close",
        fontAwesomeIcon: "save",
        onClick: () => {
          this.submit(true);
        },
      },
    ]);
  }
  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.load(+params["id"]);
    });
  }

  async load(id: number) {
    let response = await this.pcs.apiService.GetCheckpoint(
      { SecurityCheckpointId: id },
      true,
      false,
    );
    this.model = response.SecurityCheckpoint;
    this.roles = response.Roles;
    this.availableCommands = response.AvailableCommands;
  }

  showCheckpointLookup() {
    $("#checkpointLookup").modal("show");
  }

  checkpointSelected(checkpoint: SecurityCheckpointDto) {
    this.model = checkpoint;
  }

  canDeactivate(): Promise<boolean> {
    var promise = Promise.resolve(true);
    if (this.form.dirty) {
      return this.pcs.showUnsavedChangesDialog();
    }
    return promise;
  }

  async submit(navigateaway: boolean = false): Promise<void> {
    let saveResponse = await this.pcs.apiService.SaveCheckpoint(
      {
        Roles: this.roles,
        SecurityCheckpoint: this.model,
      },
      undefined,
      true,
    );
    if (saveResponse.Id !== 0) {
      this.form.form.markAsPristine();
      this.updateUrlForNewEntity(["/checkpoint", saveResponse.Id]);
      if (navigateaway) {
        window.history.back();
      } else {
        this.load(saveResponse.Id);
      }
    }
  }

  setCommandFilter() {
    this.commandNameFilter = SearchUtility.getIsActiveFilterForSearch();
    this.commandNameFilter.JoinType = LogicalOperator.Or;
    this.commandNameFilter.Parameters.push({
      Comparison: ComparisonOperator.Equals,
      DataType: FilterDataType.Numeric,
      FieldName: "Id",
      Filter: this.model.Name.toString(),
    });
  }
}
