import { Component } from "@angular/core";
import { OnInit, ActivatedRoute, Params } from "../../../ng";

@Component({
  selector: "app-search-grid-link-destination-example",
  templateUrl: "./search-grid-link-destination-example.component.html",
  styleUrls: ["./search-grid-link-destination-example.component.scss"],
})
export class SearchGridLinkDestinationExampleComponent implements OnInit {
  id: number;
  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.id = +params["id"];
    });
  }
}
