import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "spk-tab-pane",
  templateUrl: "./spk-tab-pane.component.html",
  styleUrls: ["./spk-tab-pane.component.scss"],
})
export class SpkTabPaneComponent implements OnInit {
  @Input() name: string;
  @Input() active: boolean;
  @Input() class: string;
  @Input() subTab: boolean = false;

  constructor() {}

  ngOnInit() {}

  get tabClass(): string {
    if (this.subTab) {
      return "spk-sub-tab-pane";
    } else {
      return "spk-tab-pane";
    }
  }
}
