import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit,
  ChangeDetectorRef,
  Optional,
} from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";
import { ControlContainerFactory } from "../../../../../factories/control-container.factory";

@Component({
  selector: "spk-number",
  templateUrl: "./spk-number.component.html",
  styleUrls: ["./spk-number.component.scss"],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: ControlContainerFactory,
      deps: [[new Optional(), NgForm]],
    },
  ],
})
export class SpkNumberComponent implements AfterViewInit, OnChanges {
  @Input() model: number;
  @Input() disabled: boolean;
  @Input() maxlength: number;
  @Input() wrapped: boolean = true;
  @Input() class: string;
  @Input() wrappedClass: string;
  @Input() placeholder: string;
  @Input() label: string;
  @Input() name: string;
  @Input() nullable: boolean = true;
  @Input() allowPartialNumbers: boolean = true;
  @Input() help: string;
  display: string;

  @Output() modelChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() onEnterKeypress: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild("input", { static: false }) input: ElementRef;

  private validPartialNumberKeys: number[] = [
    48, //0
    49, //1
    50, //2
    51, //3
    52, //4
    53, //5
    54, //6
    55, //7
    56, //8
    57, //9
    96, //0
    97, //1
    98, //2
    99, //3
    100, //4
    101, //5
    102, //6
    103, //7
    104, //8
    105, //9
    110, //.
    190, //.
    189, //-
    109, //-
    35, //end
    36, //home
    37, //left
    39, //right
    46, //delete
    8, //backspace
    9, //tab
  ];

  private validWholeNumberKeys: number[] = [
    48, //0
    49, //1
    50, //2
    51, //3
    52, //4
    53, //5
    54, //6
    55, //7
    56, //8
    57, //9
    96, //0
    97, //1
    98, //2
    99, //3
    100, //4
    101, //5
    102, //6
    103, //7
    104, //8
    105, //9
    189, //-
    109, //-
    35, //end
    36, //home
    37, //left
    39, //right
    46, //delete
    8, //backspace
    9, //tab
  ];

  constructor(private cdr: ChangeDetectorRef) {}
  ngAfterViewInit() {
    this.input.nativeElement.onkeydown = (evt) => this.keyDown(evt);
  }

  ngOnChanges() {
    this.setDisplay();
  }

  private setDisplay() {
    if (this.model != null && this.model != undefined) {
      this.formatDisplay();
    } else {
      this.display = "";
    }
  }

  formatDisplay() {
    let result: string = "";
    if (isNaN(this.model)) {
      if (this.nullable) {
        this.model = null;
      } else {
        this.model = 0;
      }
    } else if (this.model != null && this.model != undefined) {
      if (this.model < 0) result = "-";
      result += `${Math.abs(this.model)}`;
    }
    this.display = result;
    this.cdr.detectChanges();
  }

  parseDisplay() {
    if (this.display) {
      var isNegative = this.display.indexOf("-") > -1;
      var numberString = this.display.replace(/[-,]/g, "");
      this.model = isNegative
        ? parseFloat(`-${numberString}`)
        : parseFloat(`${numberString}`);
      //this.formatDisplay();
    } else {
      if (this.nullable) {
        this.model = null;
      } else {
        this.model = 0;
      }
    }
    this.modelChange.emit(this.model);
  }

  displayChanged(value: string) {
    this.parseDisplay();
  }

  keyDown(value) {
    let isValidKeyCode: boolean = false;
    if (this.allowPartialNumbers) {
      for (let i = 0; i < this.validPartialNumberKeys.length; i++) {
        if (value.keyCode == this.validPartialNumberKeys[i]) {
          isValidKeyCode = true;
          break;
        }
      }
    } else {
      for (let i = 0; i < this.validWholeNumberKeys.length; i++) {
        if (value.keyCode == this.validWholeNumberKeys[i]) {
          isValidKeyCode = true;
          break;
        }
      }
    }
    if (this.onEnterKeypress && value.keyCode === 13)
      this.onEnterKeypress.emit(value);
    return isValidKeyCode;
  }

  onBlur() {
    this.parseDisplay();
    this.formatDisplay();
  }
}
