import { Component, OnInit, ViewChild, Input, ElementRef } from "@angular/core";
import { NgForm } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { PageContainerService } from "../../../../../services";
import {
  FileDto,
  GetFileTypesCommandRequest,
  LinkFileDto,
  UserDto,
  ValueTextDto,
} from "../../../../../data/CommandServiceDtos.generated";
import { FilterSpecification } from "../../../../../data/framework/searchObjects";
import { AutoLookupWithModalInputFormat } from "../../../auto-lookup-with-modal/auto-lookup-with-modal.component";

@Component({
  selector: "spk-file-upload-overwrite",
  templateUrl: "./spk-file-upload-overwrite.component.html",
  styleUrls: ["./spk-file-upload-overwrite.component.scss"],
})
export class SpkFileUploadOverwriteComponent implements OnInit {
  @Input() showList: boolean = true;
  @Input() defaultSortProperty: string;
  @Input() list: string = "FileLinkSearchRow";
  @Input() useQueryString: boolean = true;
  @Input() linkFileDto: LinkFileDto;
  @Input() filter: FilterSpecification;
  @ViewChild("form", { static: true }) form: NgForm;
  @ViewChild("fileInput", { static: false }) fileInput: ElementRef;
  userInputFormat: AutoLookupWithModalInputFormat[] = [
    {
      columnNameElement: null,
      stringLiteralElement: null,
      manualFormatter: function (selectedResult) {
        return selectedResult.FirstName + " " + selectedResult.LastName;
      },
    },
  ];

  userFilter: FilterSpecification = null;
  filesToUpload: File[] = [];
  fileTypeList: ValueTextDto[] = [];
  selectedFileTypeId: number;
  selectedUser: UserDto;
  showFileWarning: boolean = false;
  userFile: FileDto;
  fileId: number;

  constructor(
    public pcs: PageContainerService,
    private http: HttpClient,
  ) {}

  async ngOnInit() {
    let request: GetFileTypesCommandRequest = {};
    var response = await this.pcs.apiService.GetFileTypes(request, true, false);
    this.fileTypeList = response.FileTypeList;
  }

  handleFileInput(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      console.log('fds', this.linkFileDto);
      this.filesToUpload = []; 
      this.filesToUpload.push(files[i]);
    }
  }

  async checkIfFileExists() {
    this.linkFileDto.UserId = this.selectedUser.Id;
    this.linkFileDto.FileTypeId = this.selectedFileTypeId;

    let fileId = await this.pcs.executeCommandService.executeCommand(
      "CheckIfFileAlreadyExists",
      { LinkFileDto: this.linkFileDto },
      false,
      false,
      true,
    );
    console.log(fileId);
    if (fileId.Payload.FileId != null && fileId.Payload.FileId != 0) {
      this.fileId = fileId.Payload.FileId;
      this.showFileWarning = true;
      this.userFile = fileId.Payload.FileId;
    } else {
      this.fileId = fileId.Payload.FileId;
      this.showFileWarning = false;
    }
  }

  async uploadFile() {
    this.linkFileDto.FileId = this.fileId;
    let response = await this.pcs.executeCommandService.executeFileEndpoint(
      "LockboxUpload",
      { files: this.filesToUpload, linkFileDto: this.linkFileDto },
      true,
      true,
    );
    console.log(response);

    this.filesToUpload = [];
    this.fileInput.nativeElement.value = null;
    if (response.Succeeded) {
      this.pcs.notificationService.success(
        "File uploaded successfully",
        undefined,
        { timeOut: 0, extendedTimeOut: 0, closeButton: true },
      );
      // this.pcs.router.navigate(['lockbox']);
      this.pcs.router.navigate(["upload-files"]);
    } else
      this.pcs.notificationService.danger(
        "There was a problem uploading this file",
        undefined,
        { timeOut: 0, extendedTimeOut: 0, closeButton: true },
      );
  }

  hideAlert() {
    this.showFileWarning = false;
    this.selectedUser = null;
    this.selectedFileTypeId = null;
  }

  navigateToLockbox() {
     this.pcs.router.navigate(['lockbox']);
  }
}
