import { Component, OnInit } from "@angular/core";
import { PageContainerService } from "../../../services";

@Component({
  selector: "dev-help",
  templateUrl: "./dev-help.component.html",
  styleUrls: ["./dev-help.component.scss"],
})
export class DevHelpComponent implements OnInit {
  constructor(private pcs: PageContainerService) {}

  ngOnInit() {}

  get loggedIn(): boolean {
    var result = this.pcs.clientSessionService.loggedIn;
    return result;
  }
}
