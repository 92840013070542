import { Pipe } from "@angular/core";
import { DateDto } from "../../data/CommandServiceDtos.generated";

@Pipe({
  name: "datedto",
})
export class DateDtoPipe {
  transform(dateDto: DateDto, args) {
    var result = "";
    if (dateDto == null) {
      return "";
    } else if (dateDto.Month && dateDto.Day && dateDto.Year) {
      result = `${dateDto.Month.toString()}/${dateDto.Day.toString()}/${dateDto.Year.toString()}`;
    }
    return result;
  }
}
