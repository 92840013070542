import { Component } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import {
  ChangeSecurityPinCommandRequest,
  GetUserCommandResponse,
  UserDto,
} from "../../../data/CommandServiceDtos.generated";
import { PageContainerService } from "../../../services";
import { ControllerForViewBase } from "../../framework/controller-for-view-base";
import { Location } from "@angular/common";

@Component({
  selector: "change-security-pin",
  templateUrl: "./change-security-pin.component.html",
  styleUrls: ["./change-security-pin.component.css"],
})
export class ChangeSecurityPinComponent extends ControllerForViewBase {
  model: UserDto;
  newSecurityPin: string;
  confirmSecurityPin: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    public pcs: PageContainerService,
    private location: Location,
  ) {
    super(pcs);
  }

  ngOnInit(): void {
    let userId: number;
    this.activatedRoute.params.subscribe((params: Params) => {
      userId = +params["id"];
      this.load(userId);
    });
    if (userId == 0) this.location.back();
  }
  async load(id: number) {
    let getCommandResponse = await this.pcs.apiService.GetUser({ Id: id });
    this.mapResponseToModel(getCommandResponse);
  }

  mapResponseToModel(getCommandResponse: GetUserCommandResponse): void {
    this.model = getCommandResponse.UserDto;
  }

  changeSecurityPin() {
    if (this.newSecurityPin.length > 0 && this.confirmSecurityPin.length > 0) {
      if (this.newSecurityPin == this.confirmSecurityPin) {
        var request: ChangeSecurityPinCommandRequest = {
          NewSecurityPin: this.newSecurityPin,
          UserId: this.model.Id,
        };
        this.pcs.apiService.ChangeSecurityPin(request, true, true);
      } else this.pcs.notificationService.warning("Passwords must match");
    } else
      this.pcs.notificationService.warning("Both fields must contain a value");
  }
}
