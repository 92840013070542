import { ErrorHandler, Injectable, Injector } from "@angular/core";
import { ApiService } from "../../data/ApiService.generated";
import { LogTypeScriptErrorCommandRequest } from "../../data/CommandServiceDtos.generated";
import { HandledException } from "..";

@Injectable()
export class GlobalExceptionHandler extends ErrorHandler {
  constructor(private injector: Injector) {
    super();
  }

  handleError(error) {
    if (
      error &&
      error.rejection &&
      error.rejection instanceof HandledException
    ) {
      // squash the exception.
    } else {
      var apiService: ApiService = this.injector.get(ApiService);
      var request: LogTypeScriptErrorCommandRequest =
        new LogTypeScriptErrorCommandRequest();
      request.Message = error.message ? error.message : error.toString();
      request.Type = "TypeScript";
      request.Stack = error.stack;
      request.Url = window.location.href;

      apiService.LogTypeScriptError(request, false);
      super.handleError(error);
    }
  }
}
