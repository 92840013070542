import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";
import { FlatpickrOptions, Ng2FlatpickrComponent } from "ng2-flatpickr";
import { ILink } from "../../../data";
import {
  AdminSearchRow,
  CommandNames,
  DeleteUserScheduleCommandRequest,
  GetUserScheduleCommandRequest,
  SaveUserScheduleCommandRequest,
  UserDto,
  UserScheduleDto,
} from "../../../data/CommandServiceDtos.generated";
import {
  ComparisonOperator,
  FilterDataType,
  FilterSpecification,
  LogicalOperator,
} from "../../../data/framework/searchObjects";
import { PageContainerService } from "../../../services/page-container.service";
import { TimeUtility } from "../../../utilities/dates/time.utility";
import { nameof } from "../../../utilities/objects/object.utility";
import { AutoLookupWithModalInputFormat } from "../../framework/auto-lookup-with-modal/auto-lookup-with-modal.component";
import { ControllerForViewBase } from "../../framework/controller-for-view-base";
import { SpkModalLookupComponent } from "../../framework/spk/controls/spk-modal-lookup/spk-modal-lookup.component";
import { SpkSearchGridComponent } from "../../framework/spk/controls/spk-search-grid/spk-search-grid.component";

type KeyValuePair = {
  text: string;
  value: string;
};

@Component({
  selector: "admin-scheduler",
  templateUrl: "./admin-scheduler.component.html",
  styleUrls: ["./admin-scheduler.component.scss"],
})
export class AdminSchedulerComponent
  extends ControllerForViewBase
  implements OnInit
{
  @ViewChild("selectUserModal") selectUserModal: SpkModalLookupComponent;
  @ViewChild("form", { static: false }) form: NgForm;
  @ViewChild("grid", { static: false })
  public adminSearchGrid: SpkSearchGridComponent;
  public model: UserScheduleDto = new UserScheduleDto();

  startTime: NgbTimeStruct;
  endTime: NgbTimeStruct;

  timeList = TimeUtility.generate24HTimeList();

  selectedUserId: number;
  userFilter: FilterSpecification;

  days: KeyValuePair[] = [
    { text: "Sun", value: "Sunday" },
    { text: "Mon", value: "Monday" },
    { text: "Tue", value: "Tuesday" },
    { text: "Wed", value: "Wednesday" },
    { text: "Thu", value: "Thursday" },
    { text: "Fri", value: "Friday" },
    { text: "Sat", value: "Saturday" },
  ];

  leftLinks: ILink[];
  headerLinks: ILink[];

  showInfo = false;

  constructor(public pcs: PageContainerService) {
    super(pcs);
  }

  updateFilters(): void {
    const newFilterValue =
      this.adminSearchGrid.rows.map((o) => o.Id).toString() || undefined;

    this.userFilter = new FilterSpecification();
    if (newFilterValue) {
      this.userFilter.JoinType = LogicalOperator.And;
      this.userFilter.Parameters = [
        {
          Comparison: ComparisonOperator.NotIn,
          DataType: FilterDataType.Numeric,
          FieldName: "Id",
          Filter: newFilterValue,
        },
        {
          Comparison: ComparisonOperator.Equals,
          DataType: FilterDataType.Boolean,
          FieldName: "Active",
          Filter: "true",
        },
      ];
    } else {
      this.userFilter.Parameters = [
        {
          Comparison: ComparisonOperator.Equals,
          DataType: FilterDataType.Boolean,
          FieldName: "Active",
          Filter: "true",
        },
      ];
    }
  }

  ngOnInit(): void {
    this.setActionLinks();

    let leftLinks: ILink[] = [
      {
        text: "",
        action: (row) => this.showDeleteConfirmModal(row),
        title: "Delete",
        icon: "times",
        class: "btn-danger",
      },
      {
        text: "",
        action: (row) => this.openRow(row),
        title: "Open",
        icon: "hand-pointer-o",
        class: "btn-info",
      },
    ];
    this.leftLinks = leftLinks;

    let headerLinks: ILink[] = [
      {
        icon: "plus",
        action: () => this.showSelectUserModal(),
        text: "Add New User Schedule",
      },
    ];
    this.headerLinks = headerLinks;
  }

  openRow(row: AdminSearchRow): void {
    this.selectedUserId = row.Id;
    this.load();
  }

  async showDeleteConfirmModal(row: AdminSearchRow): Promise<void> {
    let message = `Are you sure you want to delete the schedule for ${row.FirstName} ${row.LastName}?`;
    this.pcs.reallyClickService.show(
      message,
      nameof<AdminSchedulerComponent>("deleteRow"),
      this,
      [row],
      null,
    );
  }

  async deleteRow(row: AdminSearchRow): Promise<void> {
    var payload: DeleteUserScheduleCommandRequest = {
      UserId: row.Id,
    };

    await this.executeCommand(
      CommandNames.DeleteUserSchedule,
      payload,
      () => {
        this.adminSearchGrid.load();
      },
      true,
    );
  }

  onStartTimeChange(time: string) {
    this.model.StartTime = time;
  }

  onEndTimeChange(time: string) {
    this.model.EndTime = time;
  }

  setActionLinks() {
    this.pcs.actionLinksPanelService.reset();
    this.pcs.actionLinksPanelService.addBackButton();
  }

  showSelectUserModal() {
    this.selectUserModal.show();
    this.selectUserModal.load();
  }

  selectedUserChanged(event: UserDto) {
    this.selectedUserId = event.Id;
    this.load();
  }

  toggleDay(day: string) {
    this.model[day] = !this.model[day];
  }

  async load() {
    var payload: GetUserScheduleCommandRequest = {
      Id: this.selectedUserId,
    };
    var response = await this.pcs.apiService.GetUserSchedule(
      payload,
      true,
      false,
    );
    if (response.UserSchedule) {
      this.model = response.UserSchedule;

      this.startTime = {
        hour: +this.model.StartTime.substring(0, 2),
        minute: +this.model.StartTime.substring(3, 5),
        second: 0,
      };

      this.endTime = {
        hour: +this.model.EndTime.substring(0, 2),
        minute: +this.model.EndTime.substring(3, 5),
        second: 0,
      };
    } else {
      this.model = new UserScheduleDto();
      this.model.UserId = this.selectedUserId;

      this.startTime = {
        hour: 12,
        minute: 0,
        second: 0,
      };

      this.endTime = {
        hour: 12,
        minute: 0,
        second: 0,
      };
    }
  }

  format24hTo12h(time: string) {
    var hour = +time.substring(0, 2);
    var minutes = time.substring(3, 5);
    var suffix = hour >= 12 ? "PM" : "AM";
    var formattedTime = `${((hour + 11) % 12) + 1}:${minutes} ${suffix}`;
    return formattedTime;
  }

  async submit(navigateaway: boolean = false) {
    this.setStartAndEndTimes();
    this.model.UserId = this.selectedUserId;
    var payload: SaveUserScheduleCommandRequest = {
      UserSchedule: this.model,
    };
    var response = await this.pcs.apiService.SaveUserSchedule(
      payload,
      true,
      true,
    );
    this.selectedUserId = null;
    this.model = new UserScheduleDto();
    this.startTime = undefined;
    this.endTime = undefined;
    this.adminSearchGrid.load();
  }

  setStartAndEndTimes() {
    this.model.StartTime = `${this.startTime.hour
      .toString()
      .padStart(2, "0")}:${this.startTime.minute.toString().padStart(2, "0")}`;
    this.model.EndTime = `${this.endTime.hour
      .toString()
      .padStart(2, "0")}:${this.endTime.minute.toString().padStart(2, "0")}`;
  }
}
