import { Component, OnInit } from "@angular/core";
import { PageContainerService } from "../../../services";

@Component({
  selector: "footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  constructor(private pcs: PageContainerService) {}

  async ngOnInit() {}
}
