import {
  Component,
  Input,
  ElementRef,
  ViewChild,
  EventEmitter,
  Output,
  OnChanges,
  AfterViewInit,
  Optional,
  ChangeDetectorRef,
} from "@angular/core";
import * as _ from "lodash";
import { NgForm, ControlContainer } from "@angular/forms";
import { ControlContainerFactory } from "../../../../../factories/control-container.factory";

@Component({
  selector: "spk-currency",
  templateUrl: "./spk-currency.component.html",
  styleUrls: ["./spk-currency.component.scss"],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: ControlContainerFactory,
      deps: [[new Optional(), NgForm]],
    },
  ],
})
export class SpkCurrencyComponent implements AfterViewInit, OnChanges {
  @Input() model: number;
  @Input() disabled: boolean;
  @Input() wrapped: boolean = true;
  @Input() label: string;
  @Input() name: string;
  @Input() class: string;
  @Input() wrappedClass: string;
  @Input() placeholder: string;
  @Input() nullable: boolean = true;
  @Input() help: string;
  display: string;
  @Output() modelChange: EventEmitter<number> = new EventEmitter<number>();
  @ViewChild("input", { static: false }) input: ElementRef;

  private validKeys: number[] = [
    48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 110, 190, 96, 97, 98, 99, 100, 101,
    102, 103, 104, 105, 189, 109, 13, 8, 46, 36, 35, 16, 9,
  ];

  constructor(public cdr: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.input.nativeElement.onkeydown = (evt) => this.keyDown(evt);
  }

  ngOnChanges() {
    this.setDisplay();
  }

  private setDisplay() {
    if (this.model != null && this.model != undefined) {
      this.formatDisplay();
    } else {
      this.display = "";
    }
  }

  formatDisplay() {
    let result: string = "";
    if (isNaN(this.model)) {
      if (this.nullable) {
        this.model = null;
      } else {
        this.model = 0;
      }
    } else if (this.model != null && this.model != undefined) {
      if (this.model < 0) {
        result = `-$${Math.abs(this.model)}`;
      } else {
        result = `$${Math.abs(this.model)}`;
      }
    } else {
      result = "";
    }
    this.display = result;
    this.cdr.detectChanges();
  }

  parseDisplay() {
    if (this.display) {
      var isNegative = this.display.indexOf("-") > -1;
      var numberString = this.display.replace("$", "").replace("-", "");
      if (isNegative) {
        this.model = this.toFixedDown(parseFloat(`-${numberString}`), 2);
      } else {
        this.model = this.toFixedDown(parseFloat(`${numberString}`), 2);
      }
      this.modelChange.emit(this.model);
      //this.formatDisplay();
    } else {
      if (this.nullable) {
        this.model = null;
      } else {
        this.model = 0;
      }
    }
  }

  modelChanged(value: string) {
    this.parseDisplay();
    this.modelChange.emit(this.model);
  }

  keyDown(value) {
    if (
      !_.some(this.validKeys, (keyCode: number) => {
        return value.keyCode == keyCode;
      })
    ) {
      return false;
    }
  }

  onFocus() {
    this.display = this.display.replace("$", "");
  }

  onBlur() {
    this.parseDisplay();
    this.formatDisplay();
  }

  toFixedDown(value: number, digits: number) {
    let re = new RegExp("(\\d+\\.\\d{" + digits + "})(\\d)"),
      m = value.toString().match(re);
    let result = m ? parseFloat(m[1]) : value.valueOf();
    return result;
  }
}
