import { Component, OnInit, ViewChild } from "@angular/core";
import { PageContainerService } from "../../../../services";
import { ActivatedRoute, Params } from "../../../../ng";
import {
  GetExampleCommandResponse,
  SaveExampleCommandRequest,
  ValueLabelDto,
} from "../../../../data/CommandServiceDtos.generated";
import { NgForm } from "@angular/forms";
import { ControllerForViewBase } from "../../../framework/controller-for-view-base";
import { CanComponentDeactivate } from "../../../../services/can-deactivate-guard.service";

@Component({
  selector: "example",
  templateUrl: "./example.component.html",
  styleUrls: ["./example.component.scss"],
})
export class ExampleComponent
  extends ControllerForViewBase
  implements OnInit, CanComponentDeactivate
{
  @ViewChild("form", { static: true }) form: NgForm;
  model: SaveExampleCommandRequest = new SaveExampleCommandRequest();
  exampleLookupOptions: ValueLabelDto[];
  getActionText = () => (this.model.Id > 0 ? "Update" : "Create");

  constructor(
    private activatedRoute: ActivatedRoute,
    pcs: PageContainerService,
  ) {
    super(pcs);
  }

  setActionLinks() {
    this.pcs.actionLinksPanelService.reset();
    this.pcs.actionLinksPanelService.addBackButton();
    this.pcs.actionLinksPanelService.addActionLinks([
      {
        text: "Save ",
        fontAwesomeIcon: "save",
        onClick: () => {
          this.submit();
        },
      },
    ]);
    this.pcs.actionLinksPanelService.addActionLinks([
      {
        text: "Save and Close",
        fontAwesomeIcon: "save",
        onClick: () => {
          this.submit(true);
        },
      },
    ]);
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.load(+params["id"]);
    });
  }

  async load(Id: number) {
    let getCommandResponse = await this.pcs.apiService.GetExample({ Id: Id });
    this.mapResponseToModel(getCommandResponse);
    this.setActionLinks();
  }

  mapResponseToModel(getCommandResponse: GetExampleCommandResponse): void {
    this.model.Id = getCommandResponse.Id;
    this.model.DisplayText = getCommandResponse.DisplayText;
    this.model.Value = getCommandResponse.Value;
    this.model.ExampleLookupId = getCommandResponse.ExampleLookupId;
    this.exampleLookupOptions = getCommandResponse.ExampleLookups;
  }

  async submit(navigateaway: boolean = false): Promise<void> {
    let response = await this.pcs.apiService.SaveExample(
      this.model,
      undefined,
      true,
    );
    this.form.form.markAsPristine();
    this.updateUrlForNewEntity(["/example", response.Id]);
    if (navigateaway) {
      window.history.back();
    } else {
      this.load(response.Id);
    }
  }

  canDeactivate(): Promise<boolean> {
    var promise = Promise.resolve(true);
    if (this.form.dirty) return this.pcs.showUnsavedChangesDialog();
    return promise;
  }
}
