import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, ErrorHandler } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HttpClientModule, HttpClientXsrfModule } from "@angular/common/http";
import { ReactiveFormsModule } from "@angular/forms";
import { DndModule } from "ng2-dnd";
import {
  AppComponent,
  ActionLinksPanelComponent,
  DataEntryExampleComponent,
  FooterComponent,
  HeaderComponent,
  LoginComponent,
  MainMenuComponent,
  ReallyClickModalComponent,
  SearchTestsComponent,
  UsersComponent,
  ValidationResultPanelComponent,
  ExceptionExampleComponent,
  ErrorLogsComponent,
  ErrorLogComponent,
  ExamplesComponent,
  ExampleComponent,
  NotificationExampleComponent,
  SearchGridLinkDestinationExampleComponent,
  SearchGridFormatExampleComponent,
  UnsavedChangesDialogComponent,
  CreateAccountComponent,
  DevHelpComponent,
  ApplicationSettingsComponent,
  ApplicationSettingComponent,
  AddressComponent,
  CheckpointsComponent,
  CheckpointComponent,
  ImportCheckpointsComponent,
  AdminTypeLinksComponent,
  CalendarEventsComponent,
  NewsArticlesComponent,
  RoleComponent,
  CalendarEventComponent,
  NewsArticleComponent,
  RolesComponent,
  UserComponent,
  CountryComponent,
  CountriesComponent,
  DashboardComponent,
  WidgetComponent,
  QueuesComponent,
  FileUploadComponent,
  FileEditModalComponent,
  UserFilesComponent,
  UserRecentFilesComponent,
  NotAuthorizedComponent,
  UserWidgetSelectionComponent,
  CountWidgetComponent,
  SpkCurrencyComponent,
  SpkDateComponent,
  SpkSelectComponent,
  SpkTextComponent,
  SpkCheckboxComponent,
  SpkTextareaComponent,
  SpkPasswordComponent,
  SpkNumberComponent,
  SpkCardComponent,
  SpkTabPanesComponent,
  SpkTabPaneComponent,
  SpkTabsComponent,
  SpkTabComponent,
  SpkPercentComponent,
  SpkModalLookupComponent,
  SpkLookupComponent,
  SpkQuantityComponent,
  SpkSearchGridComponent,
  SpkPagerComponent,
  SpkValueTextCheckboxListComponent,
  SpkControlsExampleComponent,
  FileUploadExampleComponent,
  SpkFilesComponent,
  SpkViewImageModalComponent,
  SpkLookupExampleComponent,
  SpkInputExamplesComponent,
  SearchGridComprehensiveExampleComponent,
  UploadFilesComponent,
  LockboxComponent,
  FileTypeComponent,
  FileTypesComponent,
  AutoLookupWithModalComponent,
  ModalLookupComponent,
  AdminSchedulerComponent,
  MessageTranscriptsComponent,
  ForgotPasswordComponent,
  ChangePasswordComponent,
  ChangeSecurityPinComponent,
  EventNotificationsComponent,
  ResourcesComponent,
  ResourceComponent,
} from "./components";
import {
  ActionLinksPanelService,
  BusyService,
  CanDeactivateGuard,
  ClientSessionService,
  ExecuteCommandService,
  PageContainerService,
  ReallyClickService,
  ValidationResultsService,
  UnsavedChangesDialogService,
  NotificationService,
  SearchGridService,
  BrowserNavigateService,
  TabService,
  SearchGridCustomFilterService,
  CanActivateGuard,
  UserFileService,
} from "./services";
import {
  Notifier,
  ToastrNotifier,
  DateDtoPipe,
  StringSpacerPipe,
  UppercasePipe,
  NegativeParenthesesPipe,
} from "./utilities";
import { GlobalExceptionHandler } from "./utilities/exceptions/GlobalExceptionHandler";
import { ReallyClickDirective } from "./directives";
import { AppRoutingModule } from "./app-routing.module";
import { ApiService } from "./data/ApiService.generated";
// import { getCurrentDebugContext } from '@angular/core/src/view/services';
import { PercentPipe, JsonPipe } from "@angular/common";
import { Ng2FlatpickrModule } from "ng2-flatpickr";
import { SpkFileUploadOverwriteComponent } from "./components/framework/spk/controls/spk-file-upload-overwrite/spk-file-upload-overwrite.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { EditorModule } from "@tinymce/tinymce-angular";
import { MatPasswordStrengthModule } from "@angular-material-extensions/password-strength";
import { SubcategoryTypeComponent } from "./components/admin/subcategory-types/subcategory-type/subcategory-type.component";
import { SubcategoryTypesComponent } from "./components/admin/subcategory-types/subcategory-types/subcategory-types.component";
import { DeleteUserDataComponent } from "./components/account/delete-user-data/delete-user-data.component";
import { DeleteUserDataSuccessComponent } from "./components/account/delete-user-data-success/delete-user-data-success.component";
import { PendingUsersComponent } from "./components/security/users/pending-users/pending-users.component";

export function minlengthValidationMessage(err, field) {
  return `Should have atleast ${
    field.templateOptions && field.templateOptions.minLength
  } characters`;
}

export function maxlengthValidationMessage(err, field) {
  return `This value should be less than ${
    field.templateOptions && field.templateOptions.maxLength
  } characters`;
}

export function minValidationMessage(err, field) {
  return `This value should be more than ${
    field.templateOptions && field.templateOptions.min
  }`;
}

export function maxValidationMessage(err, field) {
  return `This value should be less than ${
    field.templateOptions && field.templateOptions.max
  }`;
}

@NgModule({
  declarations: [
    AppComponent,
    ActionLinksPanelComponent,
    DataEntryExampleComponent,
    FooterComponent,
    HeaderComponent,
    LoginComponent,
    MainMenuComponent,
    ReallyClickModalComponent,
    SearchTestsComponent,
    UsersComponent,
    ReallyClickDirective,
    ValidationResultPanelComponent,
    ExceptionExampleComponent,
    ErrorLogsComponent,
    ErrorLogComponent,
    ExamplesComponent,
    ExampleComponent,
    NotificationExampleComponent,
    SearchGridLinkDestinationExampleComponent,
    SearchGridFormatExampleComponent,
    UnsavedChangesDialogComponent,
    CreateAccountComponent,
    DevHelpComponent,
    ApplicationSettingsComponent,
    ApplicationSettingComponent,
    AddressComponent,
    CheckpointsComponent,
    CheckpointComponent,
    ImportCheckpointsComponent,
    AdminTypeLinksComponent,
    CalendarEventsComponent,
    NewsArticlesComponent,
    RoleComponent,
    CalendarEventComponent,
    NewsArticleComponent,
    RolesComponent,
    UserComponent,
    CountryComponent,
    CountriesComponent,
    DashboardComponent,
    WidgetComponent,
    QueuesComponent,
    FileUploadComponent,
    SpkFilesComponent,
    SpkViewImageModalComponent,
    FileEditModalComponent,
    UserFilesComponent,
    UserRecentFilesComponent,
    NotAuthorizedComponent,
    UserWidgetSelectionComponent,
    CountWidgetComponent,
    SpkCurrencyComponent,
    SpkDateComponent,
    SpkSelectComponent,
    SpkTextComponent,
    SpkCheckboxComponent,
    SpkTextareaComponent,
    SpkPasswordComponent,
    SpkNumberComponent,
    SpkCardComponent,
    SpkTabPanesComponent,
    SpkTabPaneComponent,
    SpkTabsComponent,
    SpkTabComponent,
    SpkPercentComponent,
    DateDtoPipe,
    StringSpacerPipe,
    NegativeParenthesesPipe,
    UppercasePipe,
    SpkModalLookupComponent,
    SpkLookupComponent,
    SpkQuantityComponent,
    SpkSearchGridComponent,
    SpkPagerComponent,
    SpkValueTextCheckboxListComponent,
    SpkControlsExampleComponent,
    FileUploadExampleComponent,
    DashboardComponent,
    UserWidgetSelectionComponent,
    WidgetComponent,
    CountWidgetComponent,
    AdminTypeLinksComponent,
    CalendarEventsComponent,
    NewsArticlesComponent,
    CountriesComponent,
    CountryComponent,
    SpkControlsExampleComponent,
    SearchGridComprehensiveExampleComponent,
    SpkLookupExampleComponent,
    SpkInputExamplesComponent,
    UploadFilesComponent,
    LockboxComponent,
    FileTypeComponent,
    FileTypesComponent,
    ModalLookupComponent,
    AutoLookupWithModalComponent,
    AdminSchedulerComponent,
    MessageTranscriptsComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    SpkFileUploadOverwriteComponent,
    ChangeSecurityPinComponent,
    EventNotificationsComponent,
    ResourcesComponent,
    ResourceComponent,
    SubcategoryTypeComponent,
    SubcategoryTypesComponent,
    DeleteUserDataComponent,
    DeleteUserDataSuccessComponent,
    PendingUsersComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "ng-cli-universal" }),
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientXsrfModule,
    FormsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserModule,
    EditorModule,
    MatPasswordStrengthModule.forRoot(),
    Ng2FlatpickrModule,
    DndModule.forRoot(),
    NgbModule,
  ],
  entryComponents: [UnsavedChangesDialogComponent],
  providers: [
    ActionLinksPanelService,
    BrowserNavigateService,
    BusyService,
    CanDeactivateGuard,
    CanActivateGuard,
    ClientSessionService,
    ExecuteCommandService,
    PageContainerService,
    ReallyClickService,
    ValidationResultsService,
    ApiService,
    UnsavedChangesDialogService,
    NotificationService,
    SearchGridService,
    TabService,
    UserFileService,
    DateDtoPipe,
    PercentPipe,
    SearchGridCustomFilterService,
    JsonPipe,
    { provide: ErrorHandler, useClass: GlobalExceptionHandler },
    { provide: Notifier, useClass: ToastrNotifier },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
