import {
  FilterSpecification,
  LogicalOperator,
  ComparisonOperator,
  FilterDataType,
} from "../../data/framework/searchObjects";

export class SearchUtility {
  public static getIsActiveFilterForSearch(): FilterSpecification {
    var result: FilterSpecification = {
      Filters: [],
      JoinType: LogicalOperator.And,
      Parameters: [
        {
          Comparison: ComparisonOperator.Equals,
          DataType: FilterDataType.Boolean,
          FieldName: "IsActive",
          Filter: "true",
        },
      ],
    };
    return result;
  }
  public static getDefaultFilterForSearch(): FilterSpecification {
    var result: FilterSpecification = {
      Filters: [],
      JoinType: LogicalOperator.And,
      Parameters: [],
    };
    return result;
  }
}
