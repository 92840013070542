import { Component, OnInit } from "@angular/core";
import { ILink } from "../../../data";
import { JsonPipe } from "@angular/common";
import { IOnClickCellAction } from "../../../data/framework/IonClickCellAction";
import {
  FilterSpecification,
  LogicalOperator,
  ComparisonOperator,
  FilterDataType,
} from "../../../data/framework/searchObjects";

@Component({
  selector: "search-grid-comprehensive-example",
  templateUrl: "./search-grid-comprehensive-example.component.html",
  styleUrls: ["./search-grid-comprehensive-example.component.scss"],
})
export class SearchGridComprehensiveExampleComponent implements OnInit {
  title: string = "My Title";
  list: string = "UserSearchRow";

  showSearch: boolean = true;
  csvExport: boolean = false;
  pdfExport: boolean = false;
  cardView: boolean = false;

  leftLinks: ILink[] = [];
  rightLinks: ILink[] = [];
  headerLinks: ILink[] = [];

  mousedOverRow: any;

  filter: FilterSpecification;
  firstName: string;
  active: boolean = true;

  onClickCellActions: IOnClickCellAction[] = [
    {
      action: (row) => {
        this.alertWithRow(row);
      },
      caller: this,
      columnName: "Email",
    },
  ];

  constructor(public jPipe: JsonPipe) {}

  ngOnInit() {
    this.setCellLinks();
    this.setFilterSpecification();
  }

  setCellLinks() {
    let links: ILink[] = [
      {
        text: "",
        action: (row) => this.alertWithRow(row),
        title: "Open",
        icon: "folder-open",
        class: "btn-primary",
      },
    ];
    this.leftLinks = links;
    this.rightLinks = links;
    this.headerLinks = [
      {
        text: "Header Button",
        action: (row) => alert("header button clicked"),
        title: "Open",
        icon: "folder-open",
        class: "btn-primary",
      },
    ];
  }

  alertWithRow(row) {
    alert(this.jPipe.transform(row));
  }

  setFilterSpecification() {
    this.filter = new FilterSpecification();
    this.filter.Filters = [];
    this.filter.JoinType = LogicalOperator.And;
    this.filter.Parameters = [];
    if (this.list == "UserSearchRow") {
      this.filter.Parameters.push({
        Comparison: ComparisonOperator.Equals,
        DataType: FilterDataType.Boolean,
        FieldName: "Active",
        Filter: this.active.toString(),
      });
      if (this.firstName) {
        this.filter.Parameters.push({
          Comparison: ComparisonOperator.Contains,
          DataType: FilterDataType.String,
          FieldName: "FirstName",
          Filter: this.firstName,
        });
      }
    }
  }
}
