import { Component, OnInit } from "@angular/core";
import { ValidationResultsService } from "../../../services";
import { trigger, style, animate, transition } from "@angular/animations";

@Component({
  selector: "validation-result-panel",
  templateUrl: "./validation-result-panel.component.html",
  styleUrls: ["./validation-result-panel.component.scss"],
  animations: [
    trigger("enterAnimation", [
      transition(":enter", [
        style({ transform: "translateX(100%)", opacity: 0 }),
        animate("500ms", style({ transform: "translateX(0)", opacity: 1 })),
      ]),
      transition(":leave", [
        style({ transform: "translateX(0)", opacity: 1 }),
        animate("500ms", style({ transform: "translateX(100%)", opacity: 0 })),
      ]),
    ]),
  ],
})
export class ValidationResultPanelComponent {
  constructor(public validationResultsService: ValidationResultsService) {}

  hideSuccessMessages() {
    this.validationResultsService.successMessages = [];
  }

  hideErrorMessages() {
    this.validationResultsService.errorMessages = [];
  }

  hideWarningMessages() {
    this.validationResultsService.warningMessages = [];
  }

  hideInfoMessages() {
    this.validationResultsService.infoMessages = [];
  }
}
