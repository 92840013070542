import { Injectable } from "@angular/core";
import { SpkSearchGridComponent } from "../components";

@Injectable()
export class SearchGridService {
  private searchGridComponents: SpkSearchGridComponent[] = [];
  constructor() {}

  async subscribe(component: SpkSearchGridComponent) {
    this.searchGridComponents.push(component);
  }

  async clearSubscribers() {
    this.searchGridComponents = [];
  }

  async refreshSubscribers() {
    for (let i = 0; i < this.searchGridComponents.length; i++) {
      const searchGridComponent = this.searchGridComponents[i];
      searchGridComponent.load();
    }
  }
}
