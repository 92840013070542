import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "uppercase",
})
export class UppercasePipe implements PipeTransform {
  transform(value: any, ignore: boolean): any {
    var result = "";
    if (
      value != null &&
      value != undefined &&
      typeof value === "string" &&
      ignore === false
    )
      result = value.toUpperCase();
    else result = value;
    return result;
  }
}
