import { Component, OnInit, Input, ViewChild } from "@angular/core";
import {
  AddressDto,
  CountryValueTextDto,
} from "../../../data/CommandServiceDtos.generated";
import { NgForm, ControlContainer } from "@angular/forms";
import * as _ from "lodash";
@Component({
  selector: "address-form",
  templateUrl: "./address.component.html",
  styleUrls: ["./address.component.scss"],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class AddressComponent implements OnInit {
  @Input() model: AddressDto;

  constructor() {}

  ngOnInit() {}

  countryChange(newCountryId: number) {
    let country = _.find(
      this.model.CountriesList,
      (value: CountryValueTextDto) => {
        return value.Value == newCountryId;
      },
    );
    if (country != undefined && country != null)
      this.model.IsInternational = country.IsInternational;
    this.model.CountryId = newCountryId;
  }
}
