import * as $ from "jquery";
import { Injectable } from "@angular/core";

@Injectable()
export class BusyService {
  constructor() {
    setInterval(this.busyIndicatorChecker, 100);
  }

  private Count: number = 0;
  private _isBusy: boolean = false;
  get isBusy(): boolean {
    return this._isBusy;
  }
  set isBusy(value: boolean) {
    this._isBusy = value;
  }

  increment(promise: Promise<any>): void {
    this.Count += 1;
    this.updateDisplay();
    setTimeout(() => {
      this.updateIsBusy(promise);
    }, 100);
  }

  decrement(): void {
    this.Count -= 1;
    if (this.Count < 0) {
      this.Count = 0;
    }
    this.updateDisplay();
    setTimeout(() => {
      this.updateIsBusy();
    }, 100);
  }

  updateDisplay() {
    if (this.Count > 0 && !$("#busy-indicator").hasClass("slideInDown")) {
      $("#busy-indicator").removeClass("animated slideOutUp");
      $("#busy-indicator").addClass("animated slideInDown");
      $("#busy-indicator-backdrop").removeClass("animated fadeOut");
      $("#busy-indicator-backdrop").addClass("animated fadeIn");
    } else if (
      this.Count <= 0 &&
      !$("#busy-indicator").hasClass("slideOutUp")
    ) {
      $("#busy-indicator").removeClass("animated slideInDown");
      $("#busy-indicator").addClass("animated slideOutUp");
      $("#busy-indicator-backdrop").removeClass("animated fadeIn");
      $("#busy-indicator-backdrop").addClass("animated fadeOut");
    }
  }

  private updateIsBusy(promise?: Promise<any>) {
    var originalBusy = this.isBusy;
    if (this.Count > 0) {
      this.isBusy = true;
    } else {
      this.isBusy = false;
    }
    if (originalBusy !== this.isBusy) {
      this.triggerBusyChanged(this.isBusy);
    }
    if (promise !== undefined && promise !== null) {
      this.triggerBusyStarted(promise);
    }
  }

  busyIndicatorChecker = () => {};

  private busyChangedHandlers: { (data: boolean): void }[] = [];
  private busyStartedHandlers: { (promise: Promise<any>): void }[] = [];

  public onBusyStarted(handler: { (promise: Promise<any>): void }) {
    this.busyStartedHandlers.push(handler);
  }

  public offBusyStarted(handler: { (promise: Promise<any>): void }) {
    this.busyStartedHandlers = this.busyStartedHandlers.filter(
      (h) => h !== handler,
    );
  }

  private triggerBusyStarted(promise: Promise<any>) {
    this.busyStartedHandlers.slice(0).forEach((h) => h(promise));
  }

  public onBusyChanged(handler: { (data: boolean): void }) {
    this.busyChangedHandlers.push(handler);
  }

  public offBusyChanged(handler: { (data: boolean): void }) {
    this.busyChangedHandlers = this.busyChangedHandlers.filter(
      (h) => h !== handler,
    );
  }

  private triggerBusyChanged(data: boolean) {
    this.busyChangedHandlers.slice(0).forEach((h) => h(data));
  }
}
