import { Component, OnInit, ViewChild } from "@angular/core";
import { ILink } from "../../../data";
import { PageContainerService } from "../../../services";
import { ControllerForViewBase } from "../../framework/controller-for-view-base";
import {
  RoleSearchRow,
  DeleteRoleCommandRequest,
} from "../../../data/CommandServiceDtos.generated";
import { SpkSearchGridComponent } from "../../framework/spk/controls/spk-search-grid/spk-search-grid.component";

@Component({
  selector: "roles",
  templateUrl: "./roles.component.html",
  styleUrls: ["./roles.component.scss"],
})
export class RolesComponent extends ControllerForViewBase implements OnInit {
  leftLinks: ILink[] = [];
  @ViewChild("grid", { static: true }) grid: SpkSearchGridComponent;

  constructor(public pcs: PageContainerService) {
    super(pcs);
    this.pcs.actionLinksPanelService.addBackButton();
    this.pcs.actionLinksPanelService.addActionLinks([
      {
        text: "Create ",
        fontAwesomeIcon: "plus",
        onClick: () => {
          this.createRole();
        },
      },
    ]);
  }

  ngOnInit() {
    this.leftLinks = [
      { text: "", action: this.editRole, icon: "edit", class: "btn-primary" },
      {
        text: "",
        reallyClickAction: "deleteRole",
        reallyClickCaller: this,
        reallyClickMessage: "Are you sure you want to delete this role?",
        action: this.deleteRole,
        icon: "trash",
        class: "btn-danger",
      },
    ];
  }

  editRole = (row: RoleSearchRow) => {
    this.pcs.router.navigate(["role", row.Id]);
  };
  deleteRole = (row: RoleSearchRow) => {
    var payload: DeleteRoleCommandRequest = {
      Id: row.Id,
    };
    this.pcs.apiService
      .DeleteRole(payload, true, true)
      .then((response) => this.refreshGrid());
  };
  createRole = () => {
    this.pcs.router.navigate(["role", 0]);
  };

  refreshGrid() {
    this.grid.load();
  }
}
