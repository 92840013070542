import { SearchCriteria, ColumnInformation, PaginationSpecification, FilterSpecification } from './framework/searchObjects';

export interface IPagedResult
                {
                    PaginationInfo: PaginationSpecification;
                    TotalCount: number;
                    Data: any[];
                }
	export class AddressDto {
		Id: number;
		Address1: string;
		Address2: string;
		Address3: string;
		City: string;
		StateId: number;
		Zip: string;
		CountryId: number;
		IsInternational: boolean;
		StatesList: ValueTextDto[] = [];
		CountriesList: CountryValueTextDto[] = [];
	}

	export class AdminSearchRow {
		Id: number;
		UserName: string;
		FirstName: string;
		LastName: string;
		Email: string;
		PendingMobileUser: boolean;
		Active: boolean;
	}

	export class ApplicationSettingSearchRow {
		Id: number;
		Name: string;
	}

	export class CalendarEventDTO {
		Id: number;
		Header: string;
		EventTimeZone: string;
		FileId: number;
		CalendarImageFileUrl: string;
		ImageName: string;
		Location: string;
		Description: string;
		Author: string;
		PublishDate: Date;
		EventDate: Date;
		EventDateTo: Date;
		IsMultipleDate: boolean;
		StartTime: string;
		EndTime: string;
		IsActive: boolean = true;
	}

	export class CalendarEventSearchRow {
		Id: number;
		Header: string;
		ImageName: string;
		Location: string;
		TruncatedDescription: string;
		PublishDate: Date;
		Author: string;
		EventDate: Date;
		Start: Date;
		End: Date;
		EventTimeZone: string;
		IsActive: boolean = true;
	}

	export class ConversationDto {
		Messages: MessagingMessage[] = [];
		Id: number;
		UserId: number;
		AdminId: number;
		StartDate: Date;
		IsActive: boolean = true;
	}

	export class CountryDto {
		Id: number;
		Name: string;
		IsInternational: boolean;
		IsActive: boolean = true;
	}

	export class CountrySearchRow {
		Id: number;
		Name: string;
		IsActive: boolean = true;
	}

	export class CountryValueTextDto {
		Value: number;
		IsInternational: boolean;
		Text: string;
	}

	export class CountWidgetDto {
		ReferenceName: string;
		DisplayName: string;
		ViewName: string;
		Link: string;
		Count: number;
		Id: number;
		Order: number;
		WidgetType: WidgetType;
		Selected: boolean;
	}

	export class DashboardDto {
		Widgets: WidgetDto[] = [];
	}

	export class DateDto implements IComparable {
		Day: number;
		Month: number;
		Year: number;
	}

	export class DeviceInstallation {
		InstallationId: string;
		Platform: string;
		PushChannel: string;
		Tags: string[] = [];
	}

	export class EmailDto {
		From: string;
		Subject: string;
		Body: string;
		Recipients: string[] = [];
		Attachments: FileDto[] = [];
	}

	export class EmailResultDto {
		Succeeded: boolean;
		ResponseInformation: string;
		Exception: any;
	}

	export class ErrorLogSearchRow {
		Id: number;
		CreationDate: Date;
		ApplicationName: string;
		URL: string;
		User: string;
		EnvironmentInfo: string;
		Message: string;
	}

	export class ExampleSearchRow {
		Id: number;
		CreateDate: Date;
		Key: string;
		DisplayText: string;
		Value: string;
		ExampleLookupDisplayText: string;
	}

	export class FavoritedEvents {
		Id: number;
		EventId: string;
		UserId: number;
		IsFavorite: boolean;
	}

	export class FavoritedNews {
		Id: number;
		NewsId: string;
		UserId: number;
		IsFavorite: boolean;
	}

	export class FileDto {
		Bytes: Blob;
		Base64String: string;
		Name: string;
		MimeType: string;
	}

	export class FileLinkSearchRow {
		FileLinkId: number;
		LinkId: number;
		UserId: number;
		LinkType: string;
		FileId: number;
		RecordRefId: number;
		DisplayName: string;
		Description: string;
		User: string;
		FileType: string;
		FileName: string;
		CreatedOn: Date;
		CreatedBy: string;
	}

	export class FileSearchRow {
		FileId: number;
		DisplayName: string;
		Description: string;
		CreatedOn: Date;
		CreatedBy: string;
	}

	export class FileTypeDto {
		Id: number;
		Name: string;
		IsActive: boolean = true;
	}

	export class FileTypeSearchRow {
		Id: number;
		Name: string;
		IsActive: boolean = true;
	}

	export class LinkFileDto {
		FileId: number;
		LinkFileType: string;
		LinkFileTypeKey: number;
		UserId: number;
		FileTypeId: number;
		RecordRefId: number;
		IsLockbox: boolean;
	}

	export class MessageDto {
		ConversationId: number;
		Message: MessagingMessage = new MessagingMessage();
	}

	export class MessageTranscriptSearchRow {
		Id: number;
		YouthName: string;
		YouthFirstName: string;
		YouthLastName: string;
		AdminName: string;
		AdminFirstName: string;
		AdminLastName: string;
		Message: string;
		SentDateTime: Date;
		SenderIsYouth: boolean;
		ReadByRecipient: boolean;
	}

	export class MessagingMessage {
		Id: number;
		ConversationId: number;
		SenderId: number;
		Message: string;
		SentDateTime: Date;
		ReadByRecipient: boolean;
	}

	export class NewsArticleDTO {
		Id: number;
		Title: string;
		FileId: number;
		NewsImageFileUrl: string;
		Body: string;
		Author: string;
		PublishDate: Date;
		IsActive: boolean = true;
	}

	export class NewsArticleSearchRow {
		Id: number;
		Title: string;
		Body: string;
		PublishDate: Date;
		Author: string;
		IsActive: boolean = true;
	}

	export class NotificationRequest {
		Text: string;
		Action: string;
		Tags: String[][] = [];
		Silent: boolean;
	}

	export class PendingUserSearchRow {
		Id: number;
		UserName: string;
		FirstName: string;
		LastName: string;
		Email: string;
		Active: boolean;
		PendingMobileUser: boolean;
	}

	export class ResourceCategorySearchRow {
		Id: number;
		UserId: number;
		FileId: number;
		Name: string;
		CreatedDate: Date;
		CreatedBy: string;
	}

	export class ResourceDTO {
		Id: number;
		Title: string;
		FileId: number;
		ResourceFileUrl: string;
		CategoryId: number;
		SubCategoryTypeId: number;
		Body: string;
		Author: string;
		PublishDate: Date;
		IsActive: boolean = true;
	}

	export class ResourceSearchRow {
		Id: number;
		Title: string;
		Category: string;
		SubCategory: string;
		Body: string;
		PublishDate: Date;
		Author: string;
		IsActive: boolean = true;
	}

	export class RoleDto {
		Id: number;
		Name: string;
		Checkpoints: SecurityCheckpointDto[] = [];
	}

	export class RoleSearchRow {
		Id: number;
		Name: string;
	}

	export class SearchGridFormatTestSearchRow {
		Id: number;
		Name: string;
		Image: Blob;
		Checkbox: boolean;
	}

	export class SearchTestSearchRow {
		Id: number;
		UserId: number;
		Name: string;
		BigInt: number;
		Date: Date;
		DateTime2: Date;
		DateTimeOffset: Date;
		Guid: string;
		Active: boolean;
		AddDate: Date;
		AddUser: string;
	}

	export class SecurityCheckpointAccessDto {
		Name: string;
		HasAccess: boolean;
	}

	export class SecurityCheckpointCSVDto {
		CommandName: string;
		Description: string;
		Roles: string;
	}

	export class SecurityCheckpointDto {
		Id: number;
		Name: string;
		Description: string;
	}

	export class SecurityCheckpointSearchRow {
		Id: number;
		Name: string;
		Description: string;
	}

	export class ServeFileDownloadResponse {
		Bytes: Blob;
		ContentType: string;
		DisplayName: string;
	}

	export class SubCategoryTypeDto {
		Id: number;
		Name: string;
		CategoryId: number;
		IsActive: boolean = true;
		Categories: ValueTextDto[] = [];
	}

	export class SubCategoryTypeSearchRow {
		Id: number;
		Name: string;
		CategoryName: string;
		IsActive: boolean = true;
	}

	export class UserAccountTypeDto {
		Id: number;
		AccountTypeName: string;
		IsActive: boolean = true;
	}

	export class UserDeviceInstallation {
		Id: number;
		InstallationId: string;
		Platform: string;
		PushChannel: string;
		Tags: string;
	}

	export class UserDto {
		Id: number;
		Username: string;
		FirstName: string;
		LastName: string;
		Email: string;
		Active: boolean;
		LogCommands: boolean;
		UserRoleDtos: UserRoleDto[] = [];
		AvailableForMessaging: boolean;
		PendingMobileUser: boolean;
		PhoneNumber: string;
		UserAccountTypeId: number;
		AccountTypeName: string;
		HasOnboarded: boolean;
		SocialId: string;
		SecurityPin: string;
	}

	export class UserFavoritedEvent {
		Id: number;
		EventId: string;
		UserId: number;
		IsFavorite: boolean;
	}

	export class UserFavoritedEventsDto {
		FavoritedEvents: UserFavoritedEvent[] = [];
	}

	export class UserFavoritedNews {
		Id: number;
		NewsId: string;
		UserId: number;
		IsFavorite: boolean;
	}

	export class UserFavoritedNewsDto {
		FavoritedNews: UserFavoritedNews[] = [];
	}

	export class UserFileDto {
		Id: number;
		UserId: number;
		FileLinkId: number;
		FileId: number;
		FileName: string;
		FileType: string;
		FileTypeEnum: UserFileType;
		Status: string;
		StatusEnum: UserFileStatus;
		TimeQueued: Date;
	}

	export class UserFilesDto {
		UserFiles: UserFileDto[] = [];
	}

	export class UserFileSearchRow {
		Id: number;
		FileLinkId: number;
		FileId: number;
		UserId: number;
		FileName: string;
		Status: string;
		TimeQueued: Date;
	}

	export class UserInfoDto {
		Id: number;
		UserName: string;
		FirstName: string;
		LastName: string;
		Roles: ValueTextDto[] = [];
		EmailAddress: string;
		LogCommands: boolean;
		Checkpoints: SecurityCheckpointAccessDto[] = [];
		PhoneNumber: string;
		UserAccountTypeId: number;
		AccountType: UserAccountTypeDto = new UserAccountTypeDto();
		HasOnboarded: boolean;
		SocialId: string;
		PendingMobileUser: boolean;
	}

	export class UserProfilePhotoDto {
		UserId: number;
		BlobIdentifier: string;
	}

	export class UserRoleDto {
		RoleId: number;
		RoleName: string;
		IsSelected: boolean;
	}

	export class UserScheduleDto {
		Id: number;
		UserId: number;
		Sunday: boolean;
		Monday: boolean;
		Tuesday: boolean;
		Wednesday: boolean;
		Thursday: boolean;
		Friday: boolean;
		Saturday: boolean;
		StartTime: string;
		EndTime: string;
		FirstName: string;
		LastName: string;
	}

	export class UserScheduleSearchRow {
		Id: number;
		FirstName: string;
		LastName: string;
		Sunday: boolean;
		Monday: boolean;
		Tuesday: boolean;
		Wednesday: boolean;
		Thursday: boolean;
		Friday: boolean;
		Saturday: boolean;
		StartTime: string;
		EndTime: string;
	}

	export class UserSearchRow {
		Id: number;
		UserName: string;
		FirstName: string;
		LastName: string;
		Email: string;
		DateOfBirth: Date;
		PendingMobileUser: boolean;
		RoleName: string;
		AvailableForMessaging: boolean;
		Active: boolean;
	}

	export class ValueLabelDto {
		value: any;
		label: string;
	}

	export class ValueSelectedDto {
		Value: any;
		Selected: boolean;
	}

	export class ValueTextDescriptionDto {
		Value: any;
		Text: string;
		Description: string;
	}

	export class ValueTextDto {
		Value: any;
		Text: string;
	}

	export class ValueTextSelectedDto {
		Value: any;
		Text: string;
		Selected: boolean;
	}

	export class WidgetDto {
		Id: number;
		Order: number;
		WidgetType: WidgetType;
		Selected: boolean;
	}

	export interface IComparable {
	}

	export interface ICommandRequest {
	}

	export interface IUserFileCommandRequest {
		UserName: string;
		UserFileId: number;
	}

	export interface ISearchCommandRequest {
		SearchType: string;
		SearchCriteria: SearchCriteria;
	}

	export interface ISearchCommandResponse {
		PagedResult: IPagedResult;
		Columns: ColumnInformation[];
	}

	export class ActivateCalendarEventCommandRequest implements ICommandRequest {
		Id: number;
	}

	export class ActivateNewsArticleCommandRequest implements ICommandRequest {
		Id: number;
	}

	export class ActivateResourceCommandRequest implements ICommandRequest {
		Id: number;
	}

	export class AdminCommandRequest implements ICommandRequest {
	}

	export class ChangePasswordCommandRequest implements ICommandRequest {
		UserId: number;
		NewPassword: string;
	}

	export class ChangeSecurityPinCommandRequest implements ICommandRequest {
		UserId: number;
		NewSecurityPin: string;
	}

	export class CheckIfFileAlreadyExistsCommandRequest implements ICommandRequest {
		LinkFileDto: LinkFileDto = new LinkFileDto();
	}

	export class CheckIfFileExistsCommandRequest implements ICommandRequest {
		LinkFileDto: LinkFileDto = new LinkFileDto();
		FileLinkId: number;
		FileTypeId: number;
	}

	export class CreateMobileUserCommandRequest implements ICommandRequest {
		Username: string;
		Password: string;
		FirstName: string;
		LastName: string;
		Email: string;
		DateOfBirth: Date;
		Roles: ValueTextSelectedDto[] = [];
		FromMobile: boolean;
		AccountTypeName: string;
	}

	export class CreateTestDummyCommandQueueCommandRequest implements ICommandRequest {
	}

	export class CreateUserCommandRequest implements ICommandRequest {
		Username: string;
		Password: string;
		FirstName: string;
		LastName: string;
		Email: string;
		DateOfBirth: string;
		Roles: ValueTextSelectedDto[] = [];
		FromMobile: boolean;
		SocialMediaLogin: boolean;
		LogCommands: boolean;
		AvailableForMessaging: boolean;
	}

	export class DeleteAuthenticatedUserDataCommandRequest implements ICommandRequest {
		UserId: number;
	}

	export class DeleteCalendarEventCommandRequest implements ICommandRequest {
		Id: number;
	}

	export class DeleteCheckpointCommandRequest implements ICommandRequest {
		CommandSecurityRoleId: number;
	}

	export class DeleteExampleCommandRequest implements ICommandRequest {
		Id: number;
	}

	export class DeleteFileLinkCommandRequest implements ICommandRequest {
		FileLinkId: number;
	}

	export class DeleteNewsArticleCommandRequest implements ICommandRequest {
		Id: number;
	}

	export class DeleteResourceCommandRequest implements ICommandRequest {
		Id: number;
	}

	export class DeleteRoleCommandRequest implements ICommandRequest {
		Id: number;
	}

	export class DeleteUserDataCommandRequest implements ICommandRequest {
		Username: string;
		Password: string;
	}

	export class DeleteUserScheduleCommandRequest implements ICommandRequest {
		UserId: number;
	}

	export class EditFileCommandRequest implements ICommandRequest {
		FileId: number;
		DisplayName: string;
		Description: string;
	}

	export class ExecuteAllPendingQueuesCommandRequest implements ICommandRequest {
	}

	export class ExecuteNextQueueEntryCommandRequest implements ICommandRequest {
		QueueDefinition: BatchProcessingQueueDefinition;
		QueueEntryId: number;
	}

	export class ExecutePendingQueueEntriesCommandRequest implements ICommandRequest {
		QueueDefinition: BatchProcessingQueueDefinition;
	}

	export class ExportCheckpointsToCSVCommandRequest implements ICommandRequest {
	}

	export class FinalizeOnboardingCommandRequest implements ICommandRequest {
		Id: number;
		FirstName: string;
		LastName: string;
		Email: string;
		DateOfBirth: string;
		PhoneNumber: string;
		SecurityPin: string;
	}

	export class GetAllCalendarEventsCommandRequest implements ICommandRequest {
		SearchCriteria: string;
	}

	export class GetAllNewsArticleCommandRequest implements ICommandRequest {
		SearchCriteria: string;
	}

	export class GetAllResourcesCommandRequest implements ICommandRequest {
	}

	export class GetApplicationSettingCommandRequest implements ICommandRequest {
		SettingName: string;
	}

	export class GetBlobBaseUrlCommandRequest implements ICommandRequest {
	}

	export class GetCalendarEventCommandRequest implements ICommandRequest {
		Id: number;
	}

	export class GetCheckpointCommandRequest implements ICommandRequest {
		SecurityCheckpointId: number;
	}

	export class GetConversationByIdCommandRequest implements ICommandRequest {
		ConversationId: number;
		UserId: number;
	}

	export class GetConversationsForAdminCommandRequest implements ICommandRequest {
		AdminId: number;
	}

	export class GetConversationsForUserCommandRequest implements ICommandRequest {
		UserId: number;
	}

	export class GetCountryCommandRequest implements ICommandRequest {
		Id: number;
	}

	export class GetCurrentUserCommandRequest implements ICommandRequest {
		CurrentUserBool: boolean;
	}

	export class GetDashboardCommandRequest implements ICommandRequest {
	}

	export class GetExampleCommandRequest implements ICommandRequest {
		Id: number;
	}

	export class GetExceptionCommandRequest implements ICommandRequest {
		Id: number;
	}

	export class GetFavoriteEventsForUserCommandRequest implements ICommandRequest {
		UserId: number;
	}

	export class GetFavoriteNewsForUserCommandRequest implements ICommandRequest {
		UserId: number;
	}

	export class GetFileCommandRequest implements ICommandRequest {
		Id: number;
	}

	export class GetFileTypeCommandRequest implements ICommandRequest {
		Id: number;
	}

	export class GetFileTypesCommandRequest implements ICommandRequest {
	}

	export class GetNewsArticleCommandRequest implements ICommandRequest {
		Id: number;
	}

	export class GetProfilePhotosForConversationsCommandRequest implements ICommandRequest {
		UserId: number;
	}

	export class GetQueueDetailCommandRequest implements ICommandRequest {
		QueueName: string;
	}

	export class GetRecentUserFilesCommandRequest implements ICommandRequest {
	}

	export class GetReleaseNumberCommandRequest implements ICommandRequest {
	}

	export class GetResourceCategoryCommandRequest implements ICommandRequest {
	}

	export class GetResourceCommandRequest implements ICommandRequest {
		Id: number;
	}

	export class GetResourcesByCategoryIdCommandRequest implements ICommandRequest {
		CategoryId: number;
	}

	export class GetRoleCommandRequest implements ICommandRequest {
		Id: number;
	}

	export class GetRolesCommandRequest implements ICommandRequest {
	}

	export class GetSubCategoryTypeCommandRequest implements ICommandRequest {
		Id: number;
	}

	export class GetSubCategoryTypesCommandRequest implements ICommandRequest {
	}

	export class GetUnreadStatusForUserCommandRequest implements ICommandRequest {
		UserId: number;
	}

	export class GetUserCommandRequest implements ICommandRequest {
		Id: number;
	}

	export class GetUserFileListByUserIdCommandRequest implements ICommandRequest {
		UserId: number;
	}

	export class GetUserFileListCommandRequest implements ICommandRequest {
	}

	export class GetUserScheduleCommandRequest implements ICommandRequest {
		Id: number;
	}

	export class GetUserWidgetSelectionsCommandRequest implements ICommandRequest {
	}

	export class ImportCheckpointsFromCSVCommandRequest implements ICommandRequest {
		fileBytes: Blob;
	}

	export class IsUserAuthenticatedCommandRequest implements ICommandRequest {
	}

	export class IsUserAuthorizedCommandRequest implements ICommandRequest {
		CheckPoint: string;
	}

	export class LoginCommandRequest implements ICommandRequest {
		Username: string;
		Password: string;
		ServiceToken: string;
		FromMobile: boolean;
	}

	export class LoginOrCreateUserFromOAuthCommandRequest implements ICommandRequest {
		FirstName: string;
		LastName: string;
		Email: string;
		AccountTypeName: string;
		FromMobile: boolean;
		SocialId: string;
	}

	export class LogoutCommandRequest implements ICommandRequest {
	}

	export class LogTypeScriptErrorCommandRequest implements ICommandRequest {
		Message: string;
		Type: string;
		Url: string;
		Stack: string;
	}

	export class PdfSearchCommandRequest implements ICommandRequest,IUserFileCommandRequest {
		CommandName: string;
		SearchType: string;
		UserName: string;
		UserFileId: number;
		SearchCriteria: SearchCriteria = new SearchCriteria();
	}

	export class ProcessEmailQueueCommandRequest implements ICommandRequest {
	}

	export class QueueUserFileCommandRequest implements ICommandRequest {
		UserFileType: UserFileType;
		FileCommandName: string;
		FileCommandPayloadAsJson: string;
	}

	export class ReadConversationByIdCommandRequest implements ICommandRequest {
		UserId: number;
		ConversationId: number;
	}

	export class RegisterDeviceCommandRequest implements ICommandRequest {
		DeviceId: number;
		Token: string;
	}

	export class RegisterForNotificationsCommandRequest implements ICommandRequest {
		DeviceInstallation: DeviceInstallation = new DeviceInstallation();
		UserId: number;
	}

	export class SaveAndAssignNewConversationCommandRequest implements ICommandRequest {
	}

	export class SaveApplicationSettingCommandRequest implements ICommandRequest {
		Id: number;
		Name: string;
		Value: string;
	}

	export class SaveCalendarEventCommandRequest implements ICommandRequest {
		Event: CalendarEventDTO = new CalendarEventDTO();
	}

	export class SaveCheckpointCommandRequest implements ICommandRequest {
		Roles: ValueTextSelectedDto[] = [];
		SecurityCheckpoint: SecurityCheckpointDto = new SecurityCheckpointDto();
	}

	export class SaveCountryCommandRequest implements ICommandRequest {
		Country: CountryDto = new CountryDto();
	}

	export class SaveExampleCommandRequest implements ICommandRequest {
		Id: number;
		DisplayText: string;
		Value: string;
		ExampleLookupId: number;
	}

	export class SaveFavoriteEventForUserCommandRequest implements ICommandRequest {
		Event: UserFavoritedEvent = new UserFavoritedEvent();
	}

	export class SaveFavoriteNewsForUserCommandRequest implements ICommandRequest {
		News: UserFavoritedNews = new UserFavoritedNews();
	}

	export class SaveFileCommandRequest implements ICommandRequest {
		FileName: string;
		ContentType: string;
		Description: string;
	}

	export class SaveFileLinkCommandRequest implements ICommandRequest {
		LinkType: string;
		LinkTypeKey: number;
		FileId: number;
	}

	export class SaveFileTypeCommandRequest implements ICommandRequest {
		FileType: FileTypeDto = new FileTypeDto();
	}

	export class SaveMessageCommandRequest implements ICommandRequest {
	}

	export class SaveMessageToConversationCommandRequest implements ICommandRequest {
		Message: MessagingMessage = new MessagingMessage();
	}

	export class SaveNewsArticleCommandRequest implements ICommandRequest {
		Event: NewsArticleDTO = new NewsArticleDTO();
	}

	export class SaveResourceCommandRequest implements ICommandRequest {
		Resource: ResourceDTO = new ResourceDTO();
	}

	export class SaveRoleCommandRequest implements ICommandRequest {
		Role: RoleDto = new RoleDto();
		RoleWidgets: ValueTextSelectedDto[] = [];
	}

	export class SaveSubCategoryTypeCommandRequest implements ICommandRequest {
		SubCategoryType: SubCategoryTypeDto = new SubCategoryTypeDto();
	}

	export class SaveUserCommandRequest implements ICommandRequest {
		UserDto: UserDto = new UserDto();
	}

	export class SaveUserScheduleCommandRequest implements ICommandRequest {
		UserSchedule: UserScheduleDto = new UserScheduleDto();
	}

	export class SaveUserWidgetOrderCommandRequest implements ICommandRequest {
		WidgetIds: number[] = [];
	}

	export class SaveUserWidgetSelectionsCommandRequest implements ICommandRequest {
		SelectedWidgetIds: number[] = [];
	}

	export class SearchObjectsCommandRequest implements ICommandRequest {
		SearchCriteria: SearchCriteria = new SearchCriteria();
		Objects: any[] = [];
	}

	export class SearchUserFilesCommandRequest implements ICommandRequest,ISearchCommandRequest {
		SearchType: string;
		SearchCriteria: SearchCriteria = new SearchCriteria();
	}

	export class SendEventNotificationCommandRequest implements ICommandRequest {
		UserId: number;
		EventNotification: string;
	}

	export class SendForgotPasswordEmailCommandRequest implements ICommandRequest {
		Email: string;
	}

	export class TestDummyCommandRequest implements ICommandRequest {
		MyString: string;
		MyInt: number;
		MyNullableInt: number;
	}

	export class TriggerServerExceptionCommandRequest implements ICommandRequest {
	}

	export class UnusedSecurityCheckpointSearchCommandRequest implements ICommandRequest {
		SearchType: string;
		SearchCriteria: SearchCriteria = new SearchCriteria();
	}

	export class UpdatePhoneNumberCommandRequest implements ICommandRequest {
		NewPhoneNumber: string;
	}

	export class VerifyUserAccessCommandRequest implements ICommandRequest {
		UserId: number;
		SecurityPinOrPassword: string;
	}

	export class ActivateCalendarEventCommandResponse {
	}

	export class ActivateNewsArticleCommandResponse {
	}

	export class ActivateResourceCommandResponse {
	}

	export class AdminCommandResponse {
	}

	export class ChangePasswordCommandResponse {
	}

	export class ChangeSecurityPinCommandResponse {
		Success: boolean;
	}

	export class CheckIfFileAlreadyExistsCommandResponse {
		FileId: number;
	}

	export class CheckIfFileExistsCommandResponse {
		FileId: number;
		ImageUrl: string;
	}

	export class CreateMobileUserCommandResponse {
	}

	export class CreateTestDummyCommandQueueCommandResponse {
	}

	export class CreateUserCommandResponse {
	}

	export class DeleteAuthenticatedUserDataCommandResponse {
	}

	export class DeleteCalendarEventCommandResponse {
	}

	export class DeleteCheckpointCommandResponse {
	}

	export class DeleteFileLinkCommandResponse {
	}

	export class DeleteNewsArticleCommandResponse {
	}

	export class DeleteResourceCommandResponse {
	}

	export class DeleteRoleCommandResponse {
	}

	export class DeleteUserDataCommandResponse {
	}

	export class DeleteUserScheduleCommandResponse {
	}

	export class EditFileCommandResponse {
	}

	export class ExecuteAllPendingQueuesCommandResponse {
	}

	export class ExecuteNextQueueEntryCommandResponse {
		ProcessedQueueEntryId: number;
		WasAQueueEntryProcessed: boolean;
	}

	export class ExecutePendingQueueEntriesCommandResponse {
	}

	export class ExportCheckpointsToCSVCommandResponse {
		FileName: string;
		Bytes: Blob;
		MimeType: string;
	}

	export class FinalizeOnboardingCommandResponse {
		Succeeded: boolean;
	}

	export class GetAllCalendarEventsCommandResponse {
		Events: CalendarEventDTO[] = [];
	}

	export class GetAllNewsArticleCommandResponse {
		Events: NewsArticleDTO[] = [];
	}

	export class GetAllResourcesCommandResponse {
		Resources: ResourceDTO[] = [];
	}

	export class GetApplicationSettingCommandResponse {
		Id: number;
		Name: string;
		Value: string;
	}

	export class GetBlobBaseUrlCommandResponse {
		BaseUrl: string;
	}

	export class GetCalendarEventCommandResponse {
		Id: number;
		FileId: number;
		CalendarImageFileUrl: string;
		Header: string;
		EventTimeZone: string;
		ImageName: string;
		Location: string;
		Description: string;
		Author: string;
		EventDateTo: Date;
		IsMultipleDate: boolean;
		PublishDate: Date;
		EventDate: Date;
		StartTime: string;
		EndTime: string;
		IsActive: boolean = true;
	}

	export class GetCheckpointCommandResponse {
		Roles: ValueTextSelectedDto[] = [];
		AvailableCommands: ValueTextDto[] = [];
		SecurityCheckpoint: SecurityCheckpointDto = new SecurityCheckpointDto();
	}

	export class GetConversationByIdCommandResponse {
		Conversation: ConversationDto = new ConversationDto();
	}

	export class GetConversationsForAdminCommandResponse {
		Conversations: ConversationDto[] = [];
	}

	export class GetConversationsForUserCommandResponse {
		Conversations: ConversationDto[] = [];
	}

	export class GetCountryCommandResponse {
		Country: CountryDto = new CountryDto();
	}

	export class GetCurrentUserCommandResponse {
		CurrentUser: UserDto = new UserDto();
	}

	export class GetDashboardCommandResponse {
		Dashboard: DashboardDto = new DashboardDto();
	}

	export class GetExampleCommandResponse {
		Id: number;
		DisplayText: string;
		Value: string;
		ExampleLookupId: number;
		ExampleLookups: ValueLabelDto[] = [];
	}

	export class GetExceptionCommandResponse {
		Id: number;
		CreationDate: string;
		ApplicationName: string;
		MachineName: string;
		Type: string;
		User: string;
		URL: string;
		HTTPMethod: string;
		Message: string;
		Detail: string;
	}

	export class GetFavoriteEventsForUserCommandResponse {
		FavoritedEvents: UserFavoritedEventsDto = new UserFavoritedEventsDto();
	}

	export class GetFavoriteNewsForUserCommandResponse {
		FavoritedNews: UserFavoritedNewsDto = new UserFavoritedNewsDto();
	}

	export class GetFileCommandResponse {
		Id: number;
		DisplayName: string;
		ReferenceName: string;
		CreatedOn: Date;
		CreatedBy: string;
		ContentType: string;
		Description: string;
	}

	export class GetFileTypeCommandResponse {
		FileType: FileTypeDto = new FileTypeDto();
	}

	export class GetFileTypesCommandResponse {
		FileTypeList: ValueTextDto[] = [];
	}

	export class GetNewsArticleCommandResponse {
		Id: number;
		FileId: number;
		NewsImageFileUrl: string;
		Title: string;
		Body: string;
		Author: string;
		PublishDate: Date;
		IsActive: boolean = true;
	}

	export class GetProfilePhotosForConversationsCommandResponse {
		UserProfileImages: UserProfilePhotoDto[] = [];
	}

	export class GetQueueDetailCommandResponse {
	}

	export class GetRecentUserFilesCommandResponse {
		UserFiles: UserFilesDto = new UserFilesDto();
	}

	export class GetReleaseNumberCommandResponse {
		ReleaseId: string;
	}

	export class GetResourceCategoryCommandResponse {
		FileTypeList: ValueTextDto[] = [];
	}

	export class GetResourceCommandResponse {
		Id: number;
		FileId: number;
		ResourceFileUrl: string;
		CategoryId: number;
		SubCategoryTypeId: number;
		Title: string;
		Body: string;
		Author: string;
		PublishDate: Date;
		IsActive: boolean = true;
	}

	export class GetResourcesByCategoryIdCommandResponse {
		Resources: ResourceDTO[] = [];
	}

	export class GetRoleCommandResponse {
		Role: RoleDto = new RoleDto();
		RoleWidgets: ValueTextSelectedDto[] = [];
	}

	export class GetRolesCommandResponse {
		Roles: ValueTextSelectedDto[] = [];
	}

	export class GetSubCategoryTypeCommandResponse {
		SubCategoryType: SubCategoryTypeDto = new SubCategoryTypeDto();
	}

	export class GetSubCategoryTypesCommandResponse {
		SubCategoryTypeList: ValueTextDto[] = [];
		SubCategoryTypeDescriptionList: ValueTextDescriptionDto[] = [];
	}

	export class GetUnreadStatusForUserCommandResponse {
		HasUnread: boolean;
	}

	export class GetUserCommandResponse {
		UserDto: UserDto = new UserDto();
	}

	export class GetUserFileListByUserIdCommandResponse {
		UserFiles: UserFilesDto = new UserFilesDto();
	}

	export class GetUserFileListCommandResponse {
		UserFiles: UserFilesDto = new UserFilesDto();
	}

	export class GetUserScheduleCommandResponse {
		UserSchedule: UserScheduleDto = new UserScheduleDto();
	}

	export class GetUserWidgetSelectionsCommandResponse {
		Widgets: WidgetDto[] = [];
	}

	export class ImportCheckpointsFromCSVCommandResponse {
	}

	export class IsUserAuthenticatedCommandResponse {
		IsAuthenticated: boolean;
		UserInfo: UserInfoDto = new UserInfoDto();
	}

	export class IsUserAuthorizedCommandResponse {
		IsAuthorized: boolean;
	}

	export class LoginCommandResponse {
		UserInfo: UserInfoDto = new UserInfoDto();
	}

	export class LoginOrCreateUserFromOAuthCommandResponse {
		UserInfo: UserInfoDto = new UserInfoDto();
	}

	export class PdfSearchCommandResponse {
		FileDto: FileDto = new FileDto();
	}

	export class ProcessEmailQueueCommandResponse {
	}

	export class QueueUserFileCommandResponse {
		UserFileId: number;
	}

	export class ReadConversationByIdCommandResponse {
		Completed: boolean;
	}

	export class RegisterDeviceCommandResponse {
		Success: boolean;
	}

	export class RegisterForNotificationsCommandResponse {
		Success: boolean;
	}

	export class SaveAndAssignNewConversationCommandResponse {
		ConversationId: number;
	}

	export class SaveApplicationSettingCommandResponse {
		Id: number;
	}

	export class SaveCalendarEventCommandResponse {
		Id: number;
	}

	export class SaveCheckpointCommandResponse {
		Id: number;
	}

	export class SaveCountryCommandResponse {
		Id: number;
	}

	export class SaveExampleCommandResponse {
		Id: number;
	}

	export class SaveFavoriteEventForUserCommandResponse {
		Success: boolean;
	}

	export class SaveFavoriteNewsForUserCommandResponse {
		Success: boolean;
	}

	export class SaveFileCommandResponse {
		Id: number;
		ReferenceName: string;
	}

	export class SaveFileLinkCommandResponse {
		Id: number;
	}

	export class SaveFileTypeCommandResponse {
		Id: number;
	}

	export class SaveMessageCommandResponse {
		MessageId: number;
	}

	export class SaveMessageToConversationCommandResponse {
		MessageDto: MessageDto = new MessageDto();
	}

	export class SaveNewsArticleCommandResponse {
		Id: number;
	}

	export class SaveResourceCommandResponse {
		Id: number;
	}

	export class SaveRoleCommandResponse {
		Id: number;
	}

	export class SaveSubCategoryTypeCommandResponse {
		Id: number;
	}

	export class SaveUserCommandResponse {
		Id: number;
	}

	export class SaveUserScheduleCommandResponse {
		Id: number;
	}

	export class SaveUserWidgetOrderCommandResponse {
	}

	export class SaveUserWidgetSelectionsCommandResponse {
	}

	export class SearchObjectsCommandResponse {
		Results: IPagedResult;
	}

	export class SearchUserFilesCommandResponse implements ISearchCommandResponse {
		PagedResult: IPagedResult;
		Columns: ColumnInformation[] = [];
	}

	export class SendEventNotificationCommandResponse {
		Succeeded: boolean;
	}

	export class SendForgotPasswordEmailCommandResponse {
	}

	export class TestDummyCommandResponse {
		MyString: string;
		MyInt: number;
		MyNullableInt: number;
	}

	export class TriggerServerExceptionCommandResponse {
	}

	export class UnusedSecurityCheckpointSearchCommandResponse {
		PagedResult: IPagedResult;
		Columns: ColumnInformation[] = [];
	}

	export class UpdatePhoneNumberCommandResponse {
		Success: boolean;
	}

	export class VerifyUserAccessCommandResponse {
		Verified: boolean;
	}

	export enum BatchProcessingQueueDefinition {
		TestDummy = 1,
		Email = 2,
		UserFile = 3,
	}

	export enum QueueEntryStatus {
		Pending = 0,
		Executing = 1,
		CompletedSuccessfully = 2,
		CompletedWithError = 3,
	}

	export enum ClaimsSource {
		Database = 0,
		JWTToken = 1,
		ActiveDirectory = 2,
	}

	export enum AuthenticationType {
		Database = 0,
		LoginActiveDirectory = 1,
		AutoActiveDirectory = 2,
		AzureActiveDirectory = 3,
	}

	export enum EmailStatus {
		Pending = 0,
		Sent = 1,
		Failed = 2,
	}

	export enum EmailType {
		Generic = 0,
		BusinessRegistrationRequest = 1,
		InternalBusinessRegistrationRequest = 2,
		BusinessRegistrationApproval = 3,
	}

	export enum UserFileStatus {
		Pending = 0,
		Complete = 1,
		Failed = 2,
	}

	export enum UserFileType {
		SearchPdf = 1,
		SearchCsv = 2,
	}

	export enum WidgetType {
		Count = 0,
	}

	export let BatchProcessingQueueDefinitionDisplayOptions = [
		{ name: "TestDummy", value: "TestDummy", enum: BatchProcessingQueueDefinition.TestDummy },
		{ name: "Email", value: "Email", enum: BatchProcessingQueueDefinition.Email },
		{ name: "UserFile", value: "UserFile", enum: BatchProcessingQueueDefinition.UserFile },
	];

	export let QueueEntryStatusDisplayOptions = [
		{ name: "Pending", value: "Pending", enum: QueueEntryStatus.Pending },
		{ name: "Executing", value: "Executing", enum: QueueEntryStatus.Executing },
		{ name: "CompletedSuccessfully", value: "CompletedSuccessfully", enum: QueueEntryStatus.CompletedSuccessfully },
		{ name: "CompletedWithError", value: "CompletedWithError", enum: QueueEntryStatus.CompletedWithError },
	];

	export let ClaimsSourceDisplayOptions = [
		{ name: "Database", value: "Database", enum: ClaimsSource.Database },
		{ name: "JWTToken", value: "JWTToken", enum: ClaimsSource.JWTToken },
		{ name: "ActiveDirectory", value: "ActiveDirectory", enum: ClaimsSource.ActiveDirectory },
	];

	export let AuthenticationTypeDisplayOptions = [
		{ name: "Database", value: "Database", enum: AuthenticationType.Database },
		{ name: "LoginActiveDirectory", value: "LoginActiveDirectory", enum: AuthenticationType.LoginActiveDirectory },
		{ name: "AutoActiveDirectory", value: "AutoActiveDirectory", enum: AuthenticationType.AutoActiveDirectory },
		{ name: "AzureActiveDirectory", value: "AzureActiveDirectory", enum: AuthenticationType.AzureActiveDirectory },
	];

	export let EmailStatusDisplayOptions = [
		{ name: "Pending", value: "Pending", enum: EmailStatus.Pending },
		{ name: "Sent", value: "Sent", enum: EmailStatus.Sent },
		{ name: "Failed", value: "Failed", enum: EmailStatus.Failed },
	];

	export let EmailTypeDisplayOptions = [
		{ name: "Generic", value: "Generic", enum: EmailType.Generic },
		{ name: "BusinessRegistrationRequest", value: "BusinessRegistrationRequest", enum: EmailType.BusinessRegistrationRequest },
		{ name: "InternalBusinessRegistrationRequest", value: "InternalBusinessRegistrationRequest", enum: EmailType.InternalBusinessRegistrationRequest },
		{ name: "BusinessRegistrationApproval", value: "BusinessRegistrationApproval", enum: EmailType.BusinessRegistrationApproval },
	];

	export let UserFileStatusDisplayOptions = [
		{ name: "Pending", value: "Pending", enum: UserFileStatus.Pending },
		{ name: "Complete", value: "Complete", enum: UserFileStatus.Complete },
		{ name: "Failed", value: "Failed", enum: UserFileStatus.Failed },
	];

	export let UserFileTypeDisplayOptions = [
		{ name: "SearchPdf", value: "SearchPdf", enum: UserFileType.SearchPdf },
		{ name: "SearchCsv", value: "SearchCsv", enum: UserFileType.SearchCsv },
	];

	export let WidgetTypeDisplayOptions = [
		{ name: "Count", value: "Count", enum: WidgetType.Count },
	];

	export let BatchProcessingQueueDefinitionDisplayMap = {
		[BatchProcessingQueueDefinition.TestDummy]: "TestDummy",
		[BatchProcessingQueueDefinition.Email]: "Email",
		[BatchProcessingQueueDefinition.UserFile]: "UserFile",
	};

	export let QueueEntryStatusDisplayMap = {
		[QueueEntryStatus.Pending]: "Pending",
		[QueueEntryStatus.Executing]: "Executing",
		[QueueEntryStatus.CompletedSuccessfully]: "CompletedSuccessfully",
		[QueueEntryStatus.CompletedWithError]: "CompletedWithError",
	};

	export let ClaimsSourceDisplayMap = {
		[ClaimsSource.Database]: "Database",
		[ClaimsSource.JWTToken]: "JWTToken",
		[ClaimsSource.ActiveDirectory]: "ActiveDirectory",
	};

	export let AuthenticationTypeDisplayMap = {
		[AuthenticationType.Database]: "Database",
		[AuthenticationType.LoginActiveDirectory]: "LoginActiveDirectory",
		[AuthenticationType.AutoActiveDirectory]: "AutoActiveDirectory",
		[AuthenticationType.AzureActiveDirectory]: "AzureActiveDirectory",
	};

	export let EmailStatusDisplayMap = {
		[EmailStatus.Pending]: "Pending",
		[EmailStatus.Sent]: "Sent",
		[EmailStatus.Failed]: "Failed",
	};

	export let EmailTypeDisplayMap = {
		[EmailType.Generic]: "Generic",
		[EmailType.BusinessRegistrationRequest]: "BusinessRegistrationRequest",
		[EmailType.InternalBusinessRegistrationRequest]: "InternalBusinessRegistrationRequest",
		[EmailType.BusinessRegistrationApproval]: "BusinessRegistrationApproval",
	};

	export let UserFileStatusDisplayMap = {
		[UserFileStatus.Pending]: "Pending",
		[UserFileStatus.Complete]: "Complete",
		[UserFileStatus.Failed]: "Failed",
	};

	export let UserFileTypeDisplayMap = {
		[UserFileType.SearchPdf]: "SearchPdf",
		[UserFileType.SearchCsv]: "SearchCsv",
	};

	export let WidgetTypeDisplayMap = {
		[WidgetType.Count]: "Count",
	};

	export class CommandNames {
		static ActivateCalendarEvent: string = "ActivateCalendarEvent"
		static ActivateNewsArticle: string = "ActivateNewsArticle"
		static ActivateResource: string = "ActivateResource"
		static Admin: string = "Admin"
		static ChangePassword: string = "ChangePassword"
		static ChangeSecurityPin: string = "ChangeSecurityPin"
		static CheckIfFileAlreadyExists: string = "CheckIfFileAlreadyExists"
		static CheckIfFileExists: string = "CheckIfFileExists"
		static CreateMobileUser: string = "CreateMobileUser"
		static CreateTestDummyCommandQueue: string = "CreateTestDummyCommandQueue"
		static CreateUser: string = "CreateUser"
		static DeleteAuthenticatedUserData: string = "DeleteAuthenticatedUserData"
		static DeleteCalendarEvent: string = "DeleteCalendarEvent"
		static DeleteCheckpoint: string = "DeleteCheckpoint"
		static DeleteExample: string = "DeleteExample"
		static DeleteFileLink: string = "DeleteFileLink"
		static DeleteNewsArticle: string = "DeleteNewsArticle"
		static DeleteResource: string = "DeleteResource"
		static DeleteRole: string = "DeleteRole"
		static DeleteUserData: string = "DeleteUserData"
		static DeleteUserSchedule: string = "DeleteUserSchedule"
		static EditFile: string = "EditFile"
		static ExecuteAllPendingQueues: string = "ExecuteAllPendingQueues"
		static ExecuteNextQueueEntry: string = "ExecuteNextQueueEntry"
		static ExecutePendingQueueEntries: string = "ExecutePendingQueueEntries"
		static ExportCheckpointsToCSV: string = "ExportCheckpointsToCSV"
		static FinalizeOnboarding: string = "FinalizeOnboarding"
		static GetAllCalendarEvents: string = "GetAllCalendarEvents"
		static GetAllNewsArticle: string = "GetAllNewsArticle"
		static GetAllResources: string = "GetAllResources"
		static GetApplicationSetting: string = "GetApplicationSetting"
		static GetBlobBaseUrl: string = "GetBlobBaseUrl"
		static GetCalendarEvent: string = "GetCalendarEvent"
		static GetCheckpoint: string = "GetCheckpoint"
		static GetConversationById: string = "GetConversationById"
		static GetConversationsForAdmin: string = "GetConversationsForAdmin"
		static GetConversationsForUser: string = "GetConversationsForUser"
		static GetCountry: string = "GetCountry"
		static GetCurrentUser: string = "GetCurrentUser"
		static GetDashboard: string = "GetDashboard"
		static GetExample: string = "GetExample"
		static GetException: string = "GetException"
		static GetFavoriteEventsForUser: string = "GetFavoriteEventsForUser"
		static GetFavoriteNewsForUser: string = "GetFavoriteNewsForUser"
		static GetFile: string = "GetFile"
		static GetFileType: string = "GetFileType"
		static GetFileTypes: string = "GetFileTypes"
		static GetNewsArticle: string = "GetNewsArticle"
		static GetProfilePhotosForConversations: string = "GetProfilePhotosForConversations"
		static GetQueueDetail: string = "GetQueueDetail"
		static GetRecentUserFiles: string = "GetRecentUserFiles"
		static GetReleaseNumber: string = "GetReleaseNumber"
		static GetResourceCategory: string = "GetResourceCategory"
		static GetResource: string = "GetResource"
		static GetResourcesByCategoryId: string = "GetResourcesByCategoryId"
		static GetRole: string = "GetRole"
		static GetRoles: string = "GetRoles"
		static GetSubCategoryType: string = "GetSubCategoryType"
		static GetSubCategoryTypes: string = "GetSubCategoryTypes"
		static GetUnreadStatusForUser: string = "GetUnreadStatusForUser"
		static GetUser: string = "GetUser"
		static GetUserFileListByUserId: string = "GetUserFileListByUserId"
		static GetUserFileList: string = "GetUserFileList"
		static GetUserSchedule: string = "GetUserSchedule"
		static GetUserWidgetSelections: string = "GetUserWidgetSelections"
		static ImportCheckpointsFromCSV: string = "ImportCheckpointsFromCSV"
		static IsUserAuthenticated: string = "IsUserAuthenticated"
		static IsUserAuthorized: string = "IsUserAuthorized"
		static Login: string = "Login"
		static LoginOrCreateUserFromOAuth: string = "LoginOrCreateUserFromOAuth"
		static Logout: string = "Logout"
		static LogTypeScriptError: string = "LogTypeScriptError"
		static PdfSearch: string = "PdfSearch"
		static ProcessEmailQueue: string = "ProcessEmailQueue"
		static QueueUserFile: string = "QueueUserFile"
		static ReadConversationById: string = "ReadConversationById"
		static RegisterDevice: string = "RegisterDevice"
		static RegisterForNotifications: string = "RegisterForNotifications"
		static SaveAndAssignNewConversation: string = "SaveAndAssignNewConversation"
		static SaveApplicationSetting: string = "SaveApplicationSetting"
		static SaveCalendarEvent: string = "SaveCalendarEvent"
		static SaveCheckpoint: string = "SaveCheckpoint"
		static SaveCountry: string = "SaveCountry"
		static SaveExample: string = "SaveExample"
		static SaveFavoriteEventForUser: string = "SaveFavoriteEventForUser"
		static SaveFavoriteNewsForUser: string = "SaveFavoriteNewsForUser"
		static SaveFile: string = "SaveFile"
		static SaveFileLink: string = "SaveFileLink"
		static SaveFileType: string = "SaveFileType"
		static SaveMessage: string = "SaveMessage"
		static SaveMessageToConversation: string = "SaveMessageToConversation"
		static SaveNewsArticle: string = "SaveNewsArticle"
		static SaveResource: string = "SaveResource"
		static SaveRole: string = "SaveRole"
		static SaveSubCategoryType: string = "SaveSubCategoryType"
		static SaveUser: string = "SaveUser"
		static SaveUserSchedule: string = "SaveUserSchedule"
		static SaveUserWidgetOrder: string = "SaveUserWidgetOrder"
		static SaveUserWidgetSelections: string = "SaveUserWidgetSelections"
		static SearchObjects: string = "SearchObjects"
		static SearchUserFiles: string = "SearchUserFiles"
		static SendEventNotification: string = "SendEventNotification"
		static SendForgotPasswordEmail: string = "SendForgotPasswordEmail"
		static TestDummy: string = "TestDummy"
		static TriggerServerException: string = "TriggerServerException"
		static UnusedSecurityCheckpointSearch: string = "UnusedSecurityCheckpointSearch"
		static UpdatePhoneNumber: string = "UpdatePhoneNumber"
		static VerifyUserAccess: string = "VerifyUserAccess"
	}

