import { Component } from "@angular/core";
import { OnInit, ActivatedRoute, Params, AfterContentInit } from "../../../ng";
import { ControllerForViewBase } from "../../framework/controller-for-view-base";
import { PageContainerService } from "../../../services";
import {
  IsUserAuthenticatedCommandResponse,
  LoginCommandRequest,
  AuthenticationType,
  AuthenticationTypeDisplayMap,
} from "../../../data/CommandServiceDtos.generated";
import * as config from "../../../../app-config.json";

@Component({
  selector: "login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent extends ControllerForViewBase implements OnInit {
  formModel: LoginCommandRequest = new LoginCommandRequest();
  redirectRoute: string;
  screenMessage = "Authenticating";

  constructor(
    pcs: PageContainerService,
    private activatedRoute: ActivatedRoute,
  ) {
    super(pcs);
    this.redirectRoute =
      this.activatedRoute.snapshot.queryParams["redirectRoute"];
  }

  async ngOnInit() {
    await this.handleAuthenticationScheme();
  }

  async handleAuthenticationScheme() {
    let authSchemeString = config.security.authenticationType;
    switch (authSchemeString) {
      case AuthenticationTypeDisplayMap[
        AuthenticationType.LoginActiveDirectory
      ]:
      case AuthenticationTypeDisplayMap[AuthenticationType.Database]:
        await this.handleLoginFormAuthenticationTypes();
        break;
      case AuthenticationTypeDisplayMap[
        AuthenticationType.AzureActiveDirectory
      ]:
      case AuthenticationTypeDisplayMap[AuthenticationType.AutoActiveDirectory]:
        await this.handleAutoLoginAuthenticationTypes();
        break;
      default:
    }
  }

  async handleLoginFormAuthenticationTypes() {
    let response = await this.pcs.apiService.IsUserAuthenticated(
      {},
      true,
      false,
    );
    if (response.IsAuthenticated) {
      this.pcs.clientSessionService.loginUser(response.UserInfo);
      this.navigateAfterLogin();
    } else {
      this.pcs.clientSessionService.reset();
    }
  }

  async handleAutoLoginAuthenticationTypes() {
    await this.login().catch((error) => this.handleError(error));
  }

  handleError(error) {
    this.screenMessage = "Login failed";
  }

  async login() {
    this.pcs.notificationService.removeAll();
    let response = await this.pcs.apiService.Login(this.formModel);
    this.pcs.notificationService.info("You are now logged in.");
    this.pcs.clientSessionService.loginUser(response.UserInfo);
    this.navigateAfterLogin();
  }

  private navigateAfterLogin() {
    if (
      this.redirectRoute !== "" &&
      this.redirectRoute != null &&
      this.redirectRoute != "/login" &&
      this.redirectRoute != undefined
    ) {
      this.pcs.router.navigateByUrl(this.redirectRoute);
    } else {
      this.pcs.router.navigate(["/"]);
    }
  }

  get loginFormAuthType(): boolean {
    let result =
      config.security.authenticationType ===
        AuthenticationTypeDisplayMap[AuthenticationType.LoginActiveDirectory] ||
      config.security.authenticationType ===
        AuthenticationTypeDisplayMap[AuthenticationType.Database];
    return result;
  }

  get autoLoginFormAuthType(): boolean {
    let result =
      config.security.authenticationType ===
        AuthenticationTypeDisplayMap[AuthenticationType.AzureActiveDirectory] ||
      config.security.authenticationType ===
        AuthenticationTypeDisplayMap[AuthenticationType.AutoActiveDirectory];
    return result;
  }
}
