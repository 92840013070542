import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { ILink, ISearchGridFormat } from "../../../data";
import * as $ from "jquery";
import { FilterSpecification } from "../../../data/framework/searchObjects";
import { ClientSessionService } from "../../../services";
import { SpkSearchGridComponent } from "../spk/controls/spk-search-grid/spk-search-grid.component";
import { SearchGridLoadStartedEventArgs } from "../../../data/framework/searchGridLoadStartedEventArgs";

@Component({
  selector: "modal-lookup",
  templateUrl: "./modal-lookup.component.html",
  styleUrls: ["./modal-lookup.component.scss"],
})
export class ModalLookupComponent implements OnChanges {
  @Input() name: string;
  @Input() class: string = "";
  @Input() list: string = "";
  @Input() title: string = "";
  @Input() sort: string = "";
  @Input() command: string = "";
  @Input() searchString: string = "";
  @Input() filter: FilterSpecification = new FilterSpecification();
  @Input() autoload: boolean = true;
  @Input() showSearch: boolean = true;
  @Input() focusInputId: string = "";
  @Input() customSearchHasBeenApplied: boolean = true;
  @Input() leftLinks: ILink[] = [];
  @Input() rowFormats: ISearchGridFormat[] = [];
  @Output() rowSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() closed: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() mousedOverChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() public loadStarting: EventEmitter<SearchGridLoadStartedEventArgs> =
    new EventEmitter<SearchGridLoadStartedEventArgs>();
  highlightedIndex: number = -1;
  highlightRowformat: ISearchGridFormat[] = [];
  combinedFormats: ISearchGridFormat[] = [];
  @ViewChild("searchGrid") searchGrid: SpkSearchGridComponent;

  showing: boolean = false;
  componentDestroyed: boolean = false;
  links: ILink[] = [];
  buttonClass: string = "buttonSelected";

  constructor(public css: ClientSessionService) {}

  ngOnChanges(changes: SimpleChanges) {
    for (let propName in changes) {
      if (propName == "leftLinks") {
        let result: ILink[] = [
          {
            text: "",
            action: this.selectRow,
            icon: "hand-pointer-o",
            class: "",
          },
        ];
        result = result.concat(this.leftLinks);
        this.links = result;
      }
    }
  }

  show() {
    if (!this.showing) {
      this.showing = true;
      this.highlightedIndex = -1;
      this.updateRowFormats();
      this.animateShow();
      this.assignKeyBinds();
      setTimeout(() => {
        if (this.showSearch) {
          this.searchGrid.searchInput.input.nativeElement.focus();
        } else {
          $(`#${this.focusInputId}`).focus();
        }
      }, 1000);
    }
  }

  animateShow() {
    $(`#${this.name}`).removeClass("animated slideOutUp");
    $(`#${this.name}`).addClass("animated slideInDown");
    $(`#${this.name}-backdrop`).removeClass("animated fadeOut");
    $(`#${this.name}-backdrop`).addClass("animated fadeIn");
  }

  animateHide() {
    $(`#${this.name}`).removeClass("animated slideInDown");
    $(`#${this.name}`).addClass("animated slideOutUp");
    $(`#${this.name}-backdrop`).removeClass("animated fadeIn");
    $(`#${this.name}-backdrop`).addClass("animated fadeOut");
  }

  selectRow = (row: any) => {
    this.rowSelected.emit(row);
    this.close();
  };

  public load() {
    this.searchGrid.load();
  }

  close() {
    this.closed.emit(true);
    this.showing = false;
    this.animateHide();
    this.unassignKeyBinds();
  }

  resetModal() {
    this.componentDestroyed = true;
    setTimeout(() => {
      this.componentDestroyed = false;
    }, 2000);
  }

  assignKeyBinds = () => {
    var that = this;
    $(document).keydown(function (event) {
      if (event.which == 27 && event.key == "Escape") {
        that.close();
        event.preventDefault();
        event.stopPropagation();
        return false;
      }
      if (event.which == 33 && event.key == "PageUp") {
        that.searchGrid.pageUp();
        that.highlightedIndex = -1;
        event.preventDefault();
        event.stopPropagation();
        return false;
      }
      if (event.which == 34 && event.key == "PageDown") {
        that.searchGrid.pageDown();
        that.highlightedIndex = -1;
        event.preventDefault();
        event.stopPropagation();
        return false;
      }
      if (event.which == 38 && event.key == "ArrowUp") {
        that.decrementHighlight();
        event.preventDefault();
        event.stopPropagation();
      }
      if (event.which == 40 && event.key == "ArrowDown") {
        that.incrementHighlight();
        event.preventDefault();
        event.stopPropagation();
        return false;
      }
      if (event.which == 13 && event.key == "Enter") {
        if (
          that.highlightedIndex > -1 &&
          that.searchGrid.searchTermHasBeenSearched &&
          that.customSearchHasBeenApplied
        ) {
          that.selectRow(
            that.searchGrid.pagedResult.Data[that.highlightedIndex],
          );
          event.preventDefault();
          event.stopPropagation();
          return false;
        }
      }
    });
  };

  unassignKeyBinds = () => {
    $(document).off("keydown");
  };

  async decrementHighlight() {
    if (this.highlightedIndex > -1) {
      this.highlightedIndex--;
      if (
        this.highlightedIndex == -1 &&
        this.searchGrid.pagedResult.PaginationInfo.PageIndex > 1
      ) {
        await this.searchGrid.pageUp();
        this.highlightedIndex = this.searchGrid.pagedResult.Data.length - 1;
      }
    }
    this.updateRowFormats();
  }

  async incrementHighlight() {
    if (this.highlightedIndex < this.searchGrid.pagedResult.Data.length - 1) {
      this.highlightedIndex++;
    } else {
      await this.searchGrid.pageDown();
      this.highlightedIndex = 0;
    }
    this.updateRowFormats();
  }

  updateRowFormats() {
    if (this.highlightedIndex > -1) {
      this.highlightRowformat = [
        {
          columnName: null,
          cssClass: "highlight",
          index: this.highlightedIndex,
          value: null,
        },
      ];
    } else {
      this.highlightRowformat = [];
    }
    this.combinedFormats = this.rowFormats.concat(this.highlightRowformat);
  }

  onLoadStarting($event: SearchGridLoadStartedEventArgs) {
    this.loadStarting.emit($event);
  }

  checkFocus(event) {
    if (event.target.className.indexOf("modal-lookup") >= 0) {
      this.close();
    }
  }
}
