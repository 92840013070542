import { Observable, CanDeactivate, Location } from "../ng";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { PlatformLocation } from "@angular/common";
import { BrowserNavigateService } from "./browser-navigate.service";
import { Injectable } from "@angular/core";

export interface CanComponentDeactivate {
  canDeactivate: () => Promise<boolean>;
}

@Injectable()
export class CanDeactivateGuard
  implements CanDeactivate<CanComponentDeactivate>
{
  constructor(
    private location: Location,
    private router: Router,
    private browserNavigateService: BrowserNavigateService,
  ) {}

  canDeactivate(
    component: CanComponentDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot,
  ) {
    return component.canDeactivate
      ? component.canDeactivate().then((canDeactivate) => {
          if (!canDeactivate && this.browserNavigateService.navigatedBack) {
            this.browserNavigateService.reset();
            this.location.go(currentState.url);
          }
          return canDeactivate;
        })
      : true;
  }
}
