import { Component } from "@angular/core";
import { LinkFileDto } from "../../../data/CommandServiceDtos.generated";
import {
  FilterSpecification,
  LogicalOperator,
  ComparisonOperator,
  FilterDataType,
} from "../../../data/framework/searchObjects";
import { PageContainerService } from "../../../services";

@Component({
  selector: "file-upload-example",
  templateUrl: "./file-upload-example.component.html",
  styleUrls: ["./file-upload-example.component.scss"],
})
export class FileUploadExampleComponent {
  fileLinkType: string = "ExampleLink";
  linkFileDto: LinkFileDto;
  filter: FilterSpecification;
  constructor(public pcs: PageContainerService) {
    this.linkFileDto = {
      IsLockbox: false,
      LinkFileType: this.fileLinkType,
      LinkFileTypeKey: this.pcs.clientSessionService.userId,
      FileTypeId: null,
      UserId: null,
      FileId: null,
      RecordRefId: null,
    };
    this.setFilterSpecification();
  }

  setFilterSpecification() {
    this.filter = new FilterSpecification();
    this.filter.Filters = [];
    this.filter.JoinType = LogicalOperator.And;

    if (this.pcs.clientSessionService.userId) {
      this.filter.Parameters = [
        {
          Comparison: ComparisonOperator.Equals,
          DataType: FilterDataType.String,
          FieldName: "LinkType",
          Filter: this.fileLinkType,
        },
        {
          Comparison: ComparisonOperator.Equals,
          DataType: FilterDataType.Numeric,
          FieldName: "LinkId",
          Filter: this.pcs.clientSessionService.userId.toString(),
        },
      ];
    }
  }
}
