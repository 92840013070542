import { Component, OnInit, Input } from "@angular/core";
import { WidgetType } from "../../../data/CommandServiceDtos.generated";

@Component({
  selector: "widget",
  templateUrl: "./widget.component.html",
  styleUrls: ["./widget.component.scss"],
})
export class WidgetComponent implements OnInit {
  @Input() model: any;
  @Input() showSelect: boolean = false;

  constructor() {}

  ngOnInit() {}

  isCountWidget(): boolean {
    var result = this.model.WidgetType == WidgetType.Count;
    return result;
  }
}
