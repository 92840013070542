import { Component, OnInit, ViewChild } from "@angular/core";
import { FilterSpecification } from "../../../data/framework/searchObjects";
import { PageContainerService } from "../../../services";
import { ControllerForViewBase } from "../../framework/controller-for-view-base";
import { UploadFilesComponent } from "../upload-files/upload-files.component";

import { LinkFileDto } from "../../../data/CommandServiceDtos.generated";
import {
  LogicalOperator,
  ComparisonOperator,
  FilterDataType,
} from "../../../data/framework/searchObjects";
import { SpkFilesComponent } from "../../framework/spk/controls/spk-files/spk-files.component";
import { Router } from '@angular/router';


@Component({
  selector: "lockbox",
  templateUrl: "./lockbox.component.html",
  styleUrls: ["./lockbox.component.scss"],
})
export class LockboxComponent extends ControllerForViewBase implements OnInit {
  fileLinkType: string = "ExampleLink";
  path: string;
  linkFileDto: LinkFileDto;
  lockboxLinkFileDto: LinkFileDto;
  filter: FilterSpecification;
  generalFilter: FilterSpecification;
  @ViewChild("files", { static: false }) files: SpkFilesComponent;
  constructor(public pcs: PageContainerService,
    private router: Router) {
    super(pcs);
    this.lockboxLinkFileDto = {
      LinkFileType: this.fileLinkType,
      LinkFileTypeKey: this.pcs.clientSessionService.userId,
      UserId: null,
      FileTypeId: null,
      FileId: null,
      RecordRefId: null,
      IsLockbox: true,
    };

    this.setFilterSpecification();
    this.setActionLinks();
  }

  ngOnInit() {
    this.getUrlFirstSegment()
  } 

  setActionLinks() {
    this.pcs.actionLinksPanelService.reset();
    this.pcs.actionLinksPanelService.addBackButton();
    this.pcs.actionLinksPanelService.addActionLinks([
      {
        text: "Upload Files ",
        fontAwesomeIcon: "upload",
        onClick: () => {
          this.navigateToDocumentUpload();
        },
      },
    ]);
  }

  setFilterSpecification() {
    this.filter = new FilterSpecification();
    this.filter.Filters = [];
    this.filter.JoinType = LogicalOperator.And;

    if (this.pcs.clientSessionService.userId) {
      this.filter.Parameters = [
        {
          Comparison: ComparisonOperator.Equals,
          DataType: FilterDataType.String,
          FieldName: "LinkType",
          Filter: this.fileLinkType,
        },
        {
          Comparison: ComparisonOperator.Equals,
          DataType: FilterDataType.Numeric,
          FieldName: "LinkId",
          Filter: this.pcs.clientSessionService.userId.toString(),
        },
      ];
    }

    this.generalFilter = new FilterSpecification();
    this.generalFilter.Filters = [];
    this.generalFilter.JoinType = LogicalOperator.And;
    this.generalFilter.Parameters = [
      {
        Comparison: ComparisonOperator.Equals,
        DataType: FilterDataType.Numeric,
        FieldName: "IsLockbox",
        Filter: 1,
      },
    ];
  }

  navigateToDocumentUpload() {
    this.pcs.router.navigate(["upload-files"]);
  }

  getUrlFirstSegment() {
    const url = window.location.href.split('/').slice(2, 3).toString().trim();

    if (url === 'app.childrensaid.org') {
      let lockboxPath = 'https://' + url + '/casuserfiles/';
      this.path = lockboxPath;
    } else {
      let lockboxPath = 'http://' + url + '/casuserfiles/';
      this.path = lockboxPath;

    }

  }

}
