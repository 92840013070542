import { Component, OnInit, Input } from "@angular/core";
import { TabService } from "../../../../../../services";

@Component({
  selector: "spk-tab",
  templateUrl: "./spk-tab.component.html",
  styleUrls: ["./spk-tab.component.scss"],
})
export class SpkTabComponent implements OnInit {
  @Input() name: string;
  @Input() label: string;
  @Input() class: string;
  @Input() active: boolean;
  @Input() subTab: boolean = false;

  constructor(private tabService: TabService) {}

  ngOnInit() {}

  updateTab(tab: string) {
    if (this.subTab) {
      this.tabService.updateSubTab(tab);
    } else {
      this.tabService.updateTab(tab);
    }
  }

  get tabClass(): string {
    if (this.subTab) {
      return "spk-sub-tab";
    } else {
      return "spk-tab";
    }
  }
}
