import { Component } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { OnInit, AfterViewInit, ActivatedRoute, Params } from "../../../ng";
import { ControllerForViewBase } from "../../framework/controller-for-view-base";
import { PageContainerService } from "../../../services";
import {
  CreateUserCommandRequest,
  DateDto,
  ValueTextDto,
} from "../../../data/CommandServiceDtos.generated";

@Component({
  selector: "create-account",
  templateUrl: "./create-account.component.html",
  styleUrls: ["./create-account.component.scss"],
})
export class CreateAccountComponent
  extends ControllerForViewBase
  implements OnInit
{
  model: CreateUserCommandRequest = new CreateUserCommandRequest();
  dateModel: DateDto;
  strength: Number;

  constructor(pcs: PageContainerService) {
    super(pcs);
    this.setActionLinks();
  }

  unCheckOthers(index: number) {
    this.model.Roles.forEach((item, i) => {
      if (index == i) return;
      item.Selected = false;
    });
  }

  setActionLinks() {
    this.pcs.actionLinksPanelService.addBackButton();
    this.pcs.actionLinksPanelService.addActionLinks([
      {
        text: "Save ",
        fontAwesomeIcon: "save",
        onClick: () => {
          this.createUser();
        },
      },
    ]);
  }

  async ngOnInit() {
    let response = await this.pcs.apiService.GetRoles({}, true, false, true);
    this.model.Roles = response.Roles;
  }

  async createUser() {
    if (this.strength < 100) {
      this.pcs.notificationService.warning(
        "Password strength is not strong enough",
      );
    } else {
      if (this.dateModel != null || this.dateModel != undefined) {
        var day = this.dateModel.Day.toString();
        var month = this.dateModel.Month.toString();
        var year = this.dateModel.Year.toString();
        var completeDate = month + "/" + day + "/" + year;
      }
      if (this.dateModel == null || this.dateModel == undefined) {
        completeDate = null;
      }
      this.model.DateOfBirth = completeDate;
      await this.pcs.apiService.CreateUser(this.model, true, true);
      this.pcs.router.navigate(["users"]);
    }
  }

  onStrengthChanged(strength) {
    this.strength = strength;
  }
}
