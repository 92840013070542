import { Component, OnInit } from "@angular/core";

@Component({
  selector: "spk-tab-panes",
  templateUrl: "./spk-tab-panes.component.html",
  styleUrls: ["./spk-tab-panes.component.scss"],
})
export class SpkTabPanesComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
