// **** Auto-generated by Sparkhound.CodeGenerator. ****
import { Injectable } from '@angular/core';
import { ExecuteCommandService } from '../services/execute-command.service';
import { CommandResponseTyped } from './framework/commandResponse';
import * as Web from './CommandServiceDtos.generated';
import * as Search from './framework/searchObjects';
import * as _ from 'lodash';


@Injectable()
export class ApiService {

    public constructor(
        private executeCommandService: ExecuteCommandService
    ) {
    
    }

    public async Search<T>(request: Search.SearchCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Search.SearchCommandResponse<T>> {
        let response: CommandResponseTyped<Search.SearchCommandResponse<T>> = await this.executeCommandService.executeCommand("Search", request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async ActivateCalendarEvent(request: Web.ActivateCalendarEventCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.ActivateCalendarEventCommandResponse> {
        let response: CommandResponseTyped<Web.ActivateCalendarEventCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.ActivateCalendarEvent, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async ActivateNewsArticle(request: Web.ActivateNewsArticleCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.ActivateNewsArticleCommandResponse> {
        let response: CommandResponseTyped<Web.ActivateNewsArticleCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.ActivateNewsArticle, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async ActivateResource(request: Web.ActivateResourceCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.ActivateResourceCommandResponse> {
        let response: CommandResponseTyped<Web.ActivateResourceCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.ActivateResource, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async Admin(request: Web.AdminCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.AdminCommandResponse> {
        let response: CommandResponseTyped<Web.AdminCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.Admin, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async ChangePassword(request: Web.ChangePasswordCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.ChangePasswordCommandResponse> {
        let response: CommandResponseTyped<Web.ChangePasswordCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.ChangePassword, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async ChangeSecurityPin(request: Web.ChangeSecurityPinCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.ChangeSecurityPinCommandResponse> {
        let response: CommandResponseTyped<Web.ChangeSecurityPinCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.ChangeSecurityPin, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async CheckIfFileAlreadyExists(request: Web.CheckIfFileAlreadyExistsCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.CheckIfFileAlreadyExistsCommandResponse> {
        let response: CommandResponseTyped<Web.CheckIfFileAlreadyExistsCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.CheckIfFileAlreadyExists, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async CheckIfFileExists(request: Web.CheckIfFileExistsCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.CheckIfFileExistsCommandResponse> {
        let response: CommandResponseTyped<Web.CheckIfFileExistsCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.CheckIfFileExists, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async CreateMobileUser(request: Web.CreateMobileUserCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.CreateMobileUserCommandResponse> {
        let response: CommandResponseTyped<Web.CreateMobileUserCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.CreateMobileUser, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async CreateTestDummyCommandQueue(request: Web.CreateTestDummyCommandQueueCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.CreateTestDummyCommandQueueCommandResponse> {
        let response: CommandResponseTyped<Web.CreateTestDummyCommandQueueCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.CreateTestDummyCommandQueue, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async CreateUser(request: Web.CreateUserCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.CreateUserCommandResponse> {
        let response: CommandResponseTyped<Web.CreateUserCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.CreateUser, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async DeleteAuthenticatedUserData(request: Web.DeleteAuthenticatedUserDataCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.DeleteAuthenticatedUserDataCommandResponse> {
        let response: CommandResponseTyped<Web.DeleteAuthenticatedUserDataCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.DeleteAuthenticatedUserData, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async DeleteCalendarEvent(request: Web.DeleteCalendarEventCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.DeleteCalendarEventCommandResponse> {
        let response: CommandResponseTyped<Web.DeleteCalendarEventCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.DeleteCalendarEvent, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async DeleteCheckpoint(request: Web.DeleteCheckpointCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.DeleteCheckpointCommandResponse> {
        let response: CommandResponseTyped<Web.DeleteCheckpointCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.DeleteCheckpoint, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async DeleteExample(request: Web.DeleteExampleCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<object> {
        let response: CommandResponseTyped<object> = await this.executeCommandService.executeCommand(Web.CommandNames.DeleteExample, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async DeleteFileLink(request: Web.DeleteFileLinkCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.DeleteFileLinkCommandResponse> {
        let response: CommandResponseTyped<Web.DeleteFileLinkCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.DeleteFileLink, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async DeleteNewsArticle(request: Web.DeleteNewsArticleCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.DeleteNewsArticleCommandResponse> {
        let response: CommandResponseTyped<Web.DeleteNewsArticleCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.DeleteNewsArticle, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async DeleteResource(request: Web.DeleteResourceCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.DeleteResourceCommandResponse> {
        let response: CommandResponseTyped<Web.DeleteResourceCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.DeleteResource, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async DeleteRole(request: Web.DeleteRoleCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.DeleteRoleCommandResponse> {
        let response: CommandResponseTyped<Web.DeleteRoleCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.DeleteRole, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async DeleteUserData(request: Web.DeleteUserDataCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.DeleteUserDataCommandResponse> {
        let response: CommandResponseTyped<Web.DeleteUserDataCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.DeleteUserData, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async DeleteUserSchedule(request: Web.DeleteUserScheduleCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.DeleteUserScheduleCommandResponse> {
        let response: CommandResponseTyped<Web.DeleteUserScheduleCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.DeleteUserSchedule, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async EditFile(request: Web.EditFileCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.EditFileCommandResponse> {
        let response: CommandResponseTyped<Web.EditFileCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.EditFile, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async ExecuteAllPendingQueues(request: Web.ExecuteAllPendingQueuesCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.ExecuteAllPendingQueuesCommandResponse> {
        let response: CommandResponseTyped<Web.ExecuteAllPendingQueuesCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.ExecuteAllPendingQueues, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async ExecuteNextQueueEntry(request: Web.ExecuteNextQueueEntryCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.ExecuteNextQueueEntryCommandResponse> {
        let response: CommandResponseTyped<Web.ExecuteNextQueueEntryCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.ExecuteNextQueueEntry, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async ExecutePendingQueueEntries(request: Web.ExecutePendingQueueEntriesCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.ExecutePendingQueueEntriesCommandResponse> {
        let response: CommandResponseTyped<Web.ExecutePendingQueueEntriesCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.ExecutePendingQueueEntries, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async ExportCheckpointsToCSV(request: Web.ExportCheckpointsToCSVCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.ExportCheckpointsToCSVCommandResponse> {
        let response: CommandResponseTyped<Web.ExportCheckpointsToCSVCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.ExportCheckpointsToCSV, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async FinalizeOnboarding(request: Web.FinalizeOnboardingCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.FinalizeOnboardingCommandResponse> {
        let response: CommandResponseTyped<Web.FinalizeOnboardingCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.FinalizeOnboarding, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetAllCalendarEvents(request: Web.GetAllCalendarEventsCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetAllCalendarEventsCommandResponse> {
        let response: CommandResponseTyped<Web.GetAllCalendarEventsCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetAllCalendarEvents, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetAllNewsArticle(request: Web.GetAllNewsArticleCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetAllNewsArticleCommandResponse> {
        let response: CommandResponseTyped<Web.GetAllNewsArticleCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetAllNewsArticle, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetAllResources(request: Web.GetAllResourcesCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetAllResourcesCommandResponse> {
        let response: CommandResponseTyped<Web.GetAllResourcesCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetAllResources, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetApplicationSetting(request: Web.GetApplicationSettingCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetApplicationSettingCommandResponse> {
        let response: CommandResponseTyped<Web.GetApplicationSettingCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetApplicationSetting, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetBlobBaseUrl(request: Web.GetBlobBaseUrlCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetBlobBaseUrlCommandResponse> {
        let response: CommandResponseTyped<Web.GetBlobBaseUrlCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetBlobBaseUrl, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetCalendarEvent(request: Web.GetCalendarEventCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetCalendarEventCommandResponse> {
        let response: CommandResponseTyped<Web.GetCalendarEventCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetCalendarEvent, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetCheckpoint(request: Web.GetCheckpointCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetCheckpointCommandResponse> {
        let response: CommandResponseTyped<Web.GetCheckpointCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetCheckpoint, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetConversationById(request: Web.GetConversationByIdCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetConversationByIdCommandResponse> {
        let response: CommandResponseTyped<Web.GetConversationByIdCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetConversationById, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetConversationsForAdmin(request: Web.GetConversationsForAdminCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetConversationsForAdminCommandResponse> {
        let response: CommandResponseTyped<Web.GetConversationsForAdminCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetConversationsForAdmin, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetConversationsForUser(request: Web.GetConversationsForUserCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetConversationsForUserCommandResponse> {
        let response: CommandResponseTyped<Web.GetConversationsForUserCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetConversationsForUser, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetCountry(request: Web.GetCountryCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetCountryCommandResponse> {
        let response: CommandResponseTyped<Web.GetCountryCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetCountry, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetCurrentUser(request: Web.GetCurrentUserCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetCurrentUserCommandResponse> {
        let response: CommandResponseTyped<Web.GetCurrentUserCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetCurrentUser, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetDashboard(request: Web.GetDashboardCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetDashboardCommandResponse> {
        let response: CommandResponseTyped<Web.GetDashboardCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetDashboard, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetExample(request: Web.GetExampleCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetExampleCommandResponse> {
        let response: CommandResponseTyped<Web.GetExampleCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetExample, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetException(request: Web.GetExceptionCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetExceptionCommandResponse> {
        let response: CommandResponseTyped<Web.GetExceptionCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetException, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetFavoriteEventsForUser(request: Web.GetFavoriteEventsForUserCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetFavoriteEventsForUserCommandResponse> {
        let response: CommandResponseTyped<Web.GetFavoriteEventsForUserCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetFavoriteEventsForUser, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetFavoriteNewsForUser(request: Web.GetFavoriteNewsForUserCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetFavoriteNewsForUserCommandResponse> {
        let response: CommandResponseTyped<Web.GetFavoriteNewsForUserCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetFavoriteNewsForUser, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetFile(request: Web.GetFileCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetFileCommandResponse> {
        let response: CommandResponseTyped<Web.GetFileCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetFile, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetFileType(request: Web.GetFileTypeCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetFileTypeCommandResponse> {
        let response: CommandResponseTyped<Web.GetFileTypeCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetFileType, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetFileTypes(request: Web.GetFileTypesCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetFileTypesCommandResponse> {
        let response: CommandResponseTyped<Web.GetFileTypesCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetFileTypes, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetNewsArticle(request: Web.GetNewsArticleCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetNewsArticleCommandResponse> {
        let response: CommandResponseTyped<Web.GetNewsArticleCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetNewsArticle, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetProfilePhotosForConversations(request: Web.GetProfilePhotosForConversationsCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetProfilePhotosForConversationsCommandResponse> {
        let response: CommandResponseTyped<Web.GetProfilePhotosForConversationsCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetProfilePhotosForConversations, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetQueueDetail(request: Web.GetQueueDetailCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetQueueDetailCommandResponse> {
        let response: CommandResponseTyped<Web.GetQueueDetailCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetQueueDetail, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetRecentUserFiles(request: Web.GetRecentUserFilesCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetRecentUserFilesCommandResponse> {
        let response: CommandResponseTyped<Web.GetRecentUserFilesCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetRecentUserFiles, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetReleaseNumber(request: Web.GetReleaseNumberCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetReleaseNumberCommandResponse> {
        let response: CommandResponseTyped<Web.GetReleaseNumberCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetReleaseNumber, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetResourceCategory(request: Web.GetResourceCategoryCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetResourceCategoryCommandResponse> {
        let response: CommandResponseTyped<Web.GetResourceCategoryCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetResourceCategory, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetResource(request: Web.GetResourceCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetResourceCommandResponse> {
        let response: CommandResponseTyped<Web.GetResourceCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetResource, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetResourcesByCategoryId(request: Web.GetResourcesByCategoryIdCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetResourcesByCategoryIdCommandResponse> {
        let response: CommandResponseTyped<Web.GetResourcesByCategoryIdCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetResourcesByCategoryId, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetRole(request: Web.GetRoleCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetRoleCommandResponse> {
        let response: CommandResponseTyped<Web.GetRoleCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetRole, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetRoles(request: Web.GetRolesCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetRolesCommandResponse> {
        let response: CommandResponseTyped<Web.GetRolesCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetRoles, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetSubCategoryType(request: Web.GetSubCategoryTypeCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetSubCategoryTypeCommandResponse> {
        let response: CommandResponseTyped<Web.GetSubCategoryTypeCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetSubCategoryType, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetSubCategoryTypes(request: Web.GetSubCategoryTypesCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetSubCategoryTypesCommandResponse> {
        let response: CommandResponseTyped<Web.GetSubCategoryTypesCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetSubCategoryTypes, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetUnreadStatusForUser(request: Web.GetUnreadStatusForUserCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetUnreadStatusForUserCommandResponse> {
        let response: CommandResponseTyped<Web.GetUnreadStatusForUserCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetUnreadStatusForUser, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetUser(request: Web.GetUserCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetUserCommandResponse> {
        let response: CommandResponseTyped<Web.GetUserCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetUser, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetUserFileListByUserId(request: Web.GetUserFileListByUserIdCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetUserFileListByUserIdCommandResponse> {
        let response: CommandResponseTyped<Web.GetUserFileListByUserIdCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetUserFileListByUserId, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetUserFileList(request: Web.GetUserFileListCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetUserFileListCommandResponse> {
        let response: CommandResponseTyped<Web.GetUserFileListCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetUserFileList, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetUserSchedule(request: Web.GetUserScheduleCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetUserScheduleCommandResponse> {
        let response: CommandResponseTyped<Web.GetUserScheduleCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetUserSchedule, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetUserWidgetSelections(request: Web.GetUserWidgetSelectionsCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetUserWidgetSelectionsCommandResponse> {
        let response: CommandResponseTyped<Web.GetUserWidgetSelectionsCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetUserWidgetSelections, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async ImportCheckpointsFromCSV(request: Web.ImportCheckpointsFromCSVCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.ImportCheckpointsFromCSVCommandResponse> {
        let response: CommandResponseTyped<Web.ImportCheckpointsFromCSVCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.ImportCheckpointsFromCSV, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async IsUserAuthenticated(request: Web.IsUserAuthenticatedCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.IsUserAuthenticatedCommandResponse> {
        let response: CommandResponseTyped<Web.IsUserAuthenticatedCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.IsUserAuthenticated, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async IsUserAuthorized(request: Web.IsUserAuthorizedCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.IsUserAuthorizedCommandResponse> {
        let response: CommandResponseTyped<Web.IsUserAuthorizedCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.IsUserAuthorized, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async Login(request: Web.LoginCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.LoginCommandResponse> {
        let response: CommandResponseTyped<Web.LoginCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.Login, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async LoginOrCreateUserFromOAuth(request: Web.LoginOrCreateUserFromOAuthCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.LoginOrCreateUserFromOAuthCommandResponse> {
        let response: CommandResponseTyped<Web.LoginOrCreateUserFromOAuthCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.LoginOrCreateUserFromOAuth, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async Logout(request: Web.LogoutCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<object> {
        let response: CommandResponseTyped<object> = await this.executeCommandService.executeCommand(Web.CommandNames.Logout, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async LogTypeScriptError(request: Web.LogTypeScriptErrorCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<object> {
        let response: CommandResponseTyped<object> = await this.executeCommandService.executeCommand(Web.CommandNames.LogTypeScriptError, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async PdfSearch(request: Web.PdfSearchCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.PdfSearchCommandResponse> {
        let response: CommandResponseTyped<Web.PdfSearchCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.PdfSearch, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async ProcessEmailQueue(request: Web.ProcessEmailQueueCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.ProcessEmailQueueCommandResponse> {
        let response: CommandResponseTyped<Web.ProcessEmailQueueCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.ProcessEmailQueue, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async QueueUserFile(request: Web.QueueUserFileCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.QueueUserFileCommandResponse> {
        let response: CommandResponseTyped<Web.QueueUserFileCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.QueueUserFile, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async ReadConversationById(request: Web.ReadConversationByIdCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.ReadConversationByIdCommandResponse> {
        let response: CommandResponseTyped<Web.ReadConversationByIdCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.ReadConversationById, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async RegisterDevice(request: Web.RegisterDeviceCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.RegisterDeviceCommandResponse> {
        let response: CommandResponseTyped<Web.RegisterDeviceCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.RegisterDevice, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async RegisterForNotifications(request: Web.RegisterForNotificationsCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.RegisterForNotificationsCommandResponse> {
        let response: CommandResponseTyped<Web.RegisterForNotificationsCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.RegisterForNotifications, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async SaveAndAssignNewConversation(request: Web.SaveAndAssignNewConversationCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.SaveAndAssignNewConversationCommandResponse> {
        let response: CommandResponseTyped<Web.SaveAndAssignNewConversationCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.SaveAndAssignNewConversation, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async SaveApplicationSetting(request: Web.SaveApplicationSettingCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.SaveApplicationSettingCommandResponse> {
        let response: CommandResponseTyped<Web.SaveApplicationSettingCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.SaveApplicationSetting, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async SaveCalendarEvent(request: Web.SaveCalendarEventCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.SaveCalendarEventCommandResponse> {
        let response: CommandResponseTyped<Web.SaveCalendarEventCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.SaveCalendarEvent, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async SaveCheckpoint(request: Web.SaveCheckpointCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.SaveCheckpointCommandResponse> {
        let response: CommandResponseTyped<Web.SaveCheckpointCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.SaveCheckpoint, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async SaveCountry(request: Web.SaveCountryCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.SaveCountryCommandResponse> {
        let response: CommandResponseTyped<Web.SaveCountryCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.SaveCountry, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async SaveExample(request: Web.SaveExampleCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.SaveExampleCommandResponse> {
        let response: CommandResponseTyped<Web.SaveExampleCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.SaveExample, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async SaveFavoriteEventForUser(request: Web.SaveFavoriteEventForUserCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.SaveFavoriteEventForUserCommandResponse> {
        let response: CommandResponseTyped<Web.SaveFavoriteEventForUserCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.SaveFavoriteEventForUser, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async SaveFavoriteNewsForUser(request: Web.SaveFavoriteNewsForUserCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.SaveFavoriteNewsForUserCommandResponse> {
        let response: CommandResponseTyped<Web.SaveFavoriteNewsForUserCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.SaveFavoriteNewsForUser, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async SaveFile(request: Web.SaveFileCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.SaveFileCommandResponse> {
        let response: CommandResponseTyped<Web.SaveFileCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.SaveFile, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async SaveFileLink(request: Web.SaveFileLinkCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.SaveFileLinkCommandResponse> {
        let response: CommandResponseTyped<Web.SaveFileLinkCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.SaveFileLink, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async SaveFileType(request: Web.SaveFileTypeCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.SaveFileTypeCommandResponse> {
        let response: CommandResponseTyped<Web.SaveFileTypeCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.SaveFileType, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async SaveMessage(request: Web.SaveMessageCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.SaveMessageCommandResponse> {
        let response: CommandResponseTyped<Web.SaveMessageCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.SaveMessage, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async SaveMessageToConversation(request: Web.SaveMessageToConversationCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.SaveMessageToConversationCommandResponse> {
        let response: CommandResponseTyped<Web.SaveMessageToConversationCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.SaveMessageToConversation, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async SaveNewsArticle(request: Web.SaveNewsArticleCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.SaveNewsArticleCommandResponse> {
        let response: CommandResponseTyped<Web.SaveNewsArticleCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.SaveNewsArticle, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async SaveResource(request: Web.SaveResourceCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.SaveResourceCommandResponse> {
        let response: CommandResponseTyped<Web.SaveResourceCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.SaveResource, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async SaveRole(request: Web.SaveRoleCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.SaveRoleCommandResponse> {
        let response: CommandResponseTyped<Web.SaveRoleCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.SaveRole, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async SaveSubCategoryType(request: Web.SaveSubCategoryTypeCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.SaveSubCategoryTypeCommandResponse> {
        let response: CommandResponseTyped<Web.SaveSubCategoryTypeCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.SaveSubCategoryType, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async SaveUser(request: Web.SaveUserCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.SaveUserCommandResponse> {
        let response: CommandResponseTyped<Web.SaveUserCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.SaveUser, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async SaveUserSchedule(request: Web.SaveUserScheduleCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.SaveUserScheduleCommandResponse> {
        let response: CommandResponseTyped<Web.SaveUserScheduleCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.SaveUserSchedule, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async SaveUserWidgetOrder(request: Web.SaveUserWidgetOrderCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.SaveUserWidgetOrderCommandResponse> {
        let response: CommandResponseTyped<Web.SaveUserWidgetOrderCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.SaveUserWidgetOrder, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async SaveUserWidgetSelections(request: Web.SaveUserWidgetSelectionsCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.SaveUserWidgetSelectionsCommandResponse> {
        let response: CommandResponseTyped<Web.SaveUserWidgetSelectionsCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.SaveUserWidgetSelections, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async SearchObjects(request: Web.SearchObjectsCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.SearchObjectsCommandResponse> {
        let response: CommandResponseTyped<Web.SearchObjectsCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.SearchObjects, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async SearchUserFiles(request: Web.SearchUserFilesCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.SearchUserFilesCommandResponse> {
        let response: CommandResponseTyped<Web.SearchUserFilesCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.SearchUserFiles, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async SendEventNotification(request: Web.SendEventNotificationCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.SendEventNotificationCommandResponse> {
        let response: CommandResponseTyped<Web.SendEventNotificationCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.SendEventNotification, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async SendForgotPasswordEmail(request: Web.SendForgotPasswordEmailCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.SendForgotPasswordEmailCommandResponse> {
        let response: CommandResponseTyped<Web.SendForgotPasswordEmailCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.SendForgotPasswordEmail, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async TestDummy(request: Web.TestDummyCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.TestDummyCommandResponse> {
        let response: CommandResponseTyped<Web.TestDummyCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.TestDummy, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async TriggerServerException(request: Web.TriggerServerExceptionCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.TriggerServerExceptionCommandResponse> {
        let response: CommandResponseTyped<Web.TriggerServerExceptionCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.TriggerServerException, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async UnusedSecurityCheckpointSearch(request: Web.UnusedSecurityCheckpointSearchCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.UnusedSecurityCheckpointSearchCommandResponse> {
        let response: CommandResponseTyped<Web.UnusedSecurityCheckpointSearchCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.UnusedSecurityCheckpointSearch, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async UpdatePhoneNumber(request: Web.UpdatePhoneNumberCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.UpdatePhoneNumberCommandResponse> {
        let response: CommandResponseTyped<Web.UpdatePhoneNumberCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.UpdatePhoneNumber, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async VerifyUserAccess(request: Web.VerifyUserAccessCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.VerifyUserAccessCommandResponse> {
        let response: CommandResponseTyped<Web.VerifyUserAccessCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.VerifyUserAccess, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }
}
