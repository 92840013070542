import { Component, ViewChild } from "@angular/core";
import { ControllerForViewBase } from "../../controller-for-view-base";
import { PageContainerService } from "../../../../services";
import { QueueEntryStatus } from "../../../../data/CommandServiceDtos.generated";
import { SpkSearchGridComponent } from "../../spk/controls/spk-search-grid/spk-search-grid.component";

@Component({
  selector: "queues",
  templateUrl: "./queues.component.html",
  styleUrls: ["./queues.component.scss"],
})
export class QueuesComponent extends ControllerForViewBase {
  @ViewChild("grid", { static: false }) grid: SpkSearchGridComponent;
  customFormats: any = {
    Status: (column) => this.formatQueueStatus(column),
  };

  constructor(public pcs: PageContainerService) {
    super(pcs);
    this.setActionlinks();
  }

  setActionlinks() {
    this.pcs.actionLinksPanelService.addBackButton();
    this.pcs.actionLinksPanelService.addActionLinks([
      {
        text: "Add Test Dummy Queue",
        fontAwesomeIcon: "plus",
        onClick: () => this.AddTestDummyQueue(),
      },
      {
        text: "Run All Pending Queues",
        fontAwesomeIcon: "play",
        onClick: () => this.ExecuteAllPendingQueues(),
      },
    ]);
  }

  async ExecuteAllPendingQueues(): Promise<void> {
    let response = await this.pcs.apiService.ExecuteAllPendingQueues(
      {},
      true,
      true,
    );
    this.grid.load();
  }

  async AddTestDummyQueue(): Promise<void> {
    let response = await this.pcs.apiService.CreateTestDummyCommandQueue(
      {},
      true,
      true,
    );
    this.grid.load();
  }

  formatQueueStatus(value: any): string {
    let result: string;
    switch (value) {
      case QueueEntryStatus[QueueEntryStatus.CompletedSuccessfully]: {
        result =
          '<i title="Completed Successfully" class="fa fa-check text-success"></i>';
        break;
      }
      case QueueEntryStatus[QueueEntryStatus.Pending]: {
        result = '<i title="Pending" class="fa fa-clock-o text-warning"></i>';
        break;
      }
      case QueueEntryStatus[QueueEntryStatus.Executing]: {
        result = '<i title="Executing" class="fa fa-ellipsis-h"></i>';
        break;
      }
      case QueueEntryStatus[QueueEntryStatus.CompletedWithError]: {
        result =
          '<i title="Completed With Error(s)" class="fa fa-exclamation-triangle  text-danger"></i>';
        break;
      }
      default: {
        result = "";
        break;
      }
    }
    return result;
  }
}
