import { Component, OnInit } from "@angular/core";
import { ILink } from "../../../../data";
import { SubCategoryTypeSearchRow } from "../../../../data/CommandServiceDtos.generated";
import {
  ComparisonOperator,
  FilterDataType,
  FilterSpecification,
  LogicalOperator,
} from "../../../../data/framework/searchObjects";
import { PageContainerService } from "../../../../services";
import { ControllerForViewBase } from "../../../framework/controller-for-view-base";

@Component({
  selector: "subcategory-types",
  templateUrl: "./subcategory-types.component.html",
  styleUrls: ["./subcategory-types.component.css"],
})
export class SubcategoryTypesComponent
  extends ControllerForViewBase
  implements OnInit
{
  leftLinks: ILink[];
  filter: FilterSpecification;
  showInactive: boolean = false;
  constructor(public pcs: PageContainerService) {
    super(pcs);
    let links: ILink[] = [
      {
        text: "",
        action: (row) => this.openRow(row),
        title: "Open",
        icon: "folder-open",
        class: "btn-primary",
      },
    ];
    this.leftLinks = links;

    this.pcs.actionLinksPanelService.addBackButton();
    this.pcs.actionLinksPanelService.addActionLinks([
      {
        text: "Create",
        fontAwesomeIcon: "plus",
        onClick: () => {
          this.create();
        },
      },
    ]);
    this.setFilterSpecification();
  }

  setFilterSpecification() {
    this.filter = new FilterSpecification();
    this.filter.Filters = [];
    this.filter.JoinType = LogicalOperator.And;

    if (!this.showInactive) {
      this.filter.Parameters = [
        {
          Comparison: ComparisonOperator.Equals,
          DataType: FilterDataType.Boolean,
          FieldName: "IsActive",
          Filter: "true",
        },
      ];
    }
  }

  create(): void {
    this.pcs.router.navigate(["/subcategory-type", 0]);
  }

  openRow(row: SubCategoryTypeSearchRow): void {
    this.pcs.router.navigate(["/subcategory-type", row.Id]);
  }
}
