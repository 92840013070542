import { Injectable } from "@angular/core";

@Injectable()
export class BrowserNavigateService {
  public navigatedBack: boolean = false;
  public navigatedForward: boolean = false;

  public reset() {
    this.navigatedBack = false;
    this.navigatedForward = false;
  }

  constructor() {}
}
