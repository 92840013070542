import { Component } from "@angular/core";
import { ViewEncapsulation, OnInit } from "./ng";
import { PageContainerService, BrowserNavigateService } from "./services";
import { NavigationEnd } from "@angular/router";
import { PlatformLocation } from "@angular/common";
import { SignalRService } from "./services/signal-r.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  previousUrl = "";
  constructor(
    private pcs: PageContainerService,
    private platformLocation: PlatformLocation,
    private browserNavigateService: BrowserNavigateService,
    private signalRService: SignalRService,
  ) {
    this.platformLocation.onPopState(() => {
      this.browserNavigateService.navigatedBack = true;
    });
    pcs.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        //on navigation changed (in case navigation changing without running a command to clear out the notifications/validation results)
        this.browserNavigateService.reset();
        this.pcs.handleNavigationEnd();
        if (this.navigationIsPageChange(val.url)) {
          //do stuff that needs to happen on url change here
        }
        this.previousUrl = val.url;
      }
    });
  }

  navigationIsPageChange(newUrl: string): boolean {
    const newUrlWithoutQueryParams = this.removeQueryParams(newUrl);
    const previousUrlWithoutQueryParams = this.removeQueryParams(
      this.previousUrl,
    );
    if (
      newUrlWithoutQueryParams.toLowerCase() !==
      previousUrlWithoutQueryParams.toLowerCase()
    ) {
      return true;
    } else {
      return false;
    }
  }

  removeQueryParams(url: string): string {
    const indexOfQueryParams = url.indexOf("?");
    let urlWithoutQueryParams = url;
    if (indexOfQueryParams > -1) {
      urlWithoutQueryParams = url.substring(0, indexOfQueryParams);
    }
    urlWithoutQueryParams = urlWithoutQueryParams.replace(/[0-9]/g, "");
    return urlWithoutQueryParams;
  }

  ngOnInit() {
    if (!this.pcs.clientSessionService.loggedIn) {
      this.pcs.clientSessionService.reset();
    }
    this.signalRService.startConnection();
    this.signalRService.addAllListeners();
  }

  get isActionLinksMenuVisible(): boolean {
    return this.pcs.actionLinksPanelService.hasActionLinks;
  }

  get isBusy(): boolean {
    const result = this.pcs.busyService.isBusy;
    return result;
  }

  actionLinkClass(): string {
    const result = this.isActionLinksMenuVisible
      ? "with-actionLinksBar"
      : "without-actionLinksBar";
    return result;
  }

  toggleMenu() {}
}
