import { Router, ActivatedRoute, NgForm } from "../ng";
import * as Data from "../data";
import { CustomReallyClickButton } from "../services";
import { Location } from "@angular/common";
import { Injectable } from "@angular/core";

@Injectable()
export class ActionLinksPanelService {
  actionLinks: ActionLink[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
  ) {}

  getActionLinks(): ActionLink[] {
    return this.actionLinks;
  }

  addActionLinks(actionLinks: ActionLink[]) {
    actionLinks.forEach((o) => this.actionLinks.push(o));
    this.fixActionLinkHrefs();
  }

  setActionLinks(actionLinks: ActionLink[]) {
    this.reset();
    this.addActionLinks(actionLinks);
  }

  reset() {
    this.actionLinks = [];
  }

  addSaveAndCancelButtons(
    onSaveClicked: () => Promise<Data.CommandResponse>,
    onCancelClicked?: () => void,
  ) {
    this.addSaveButton(onSaveClicked);
    this.addSaveAndCloseButton(onSaveClicked);
    this.addCancelButton(onCancelClicked);
  }

  addSaveButton(onSaveClicked: () => Promise<Data.CommandResponse>) {
    var actionLinksTemp: ActionLink[] = [];
    actionLinksTemp.push({
      text: "Save",
      fontAwesomeIcon: "floppy-o",
      onClick: onSaveClicked,
    });
    this.addActionLinks(actionLinksTemp);
  }

  addSaveAndCloseButton(onSaveClicked: () => Promise<Data.CommandResponse>) {
    var actionLinksTemp: ActionLink[] = [];
    actionLinksTemp.push({
      text: "Save and Close",
      fontAwesomeIcon: "folder",
      onClick: () => this.onSaveAndClose(onSaveClicked),
    });
    this.addActionLinks(actionLinksTemp);
  }

  addSubmitAndCloseButton(
    confirmationMessage: string,
    onSubmitClicked: () => Promise<Data.CommandResponse>,
    linkText?: string,
    fontAwesomeIcon?: string,
  ) {
    var actionLinksTemp: ActionLink[] = [];
    var text = linkText != undefined ? linkText : "Submit and Close";
    var icon = fontAwesomeIcon != undefined ? fontAwesomeIcon : "check";
    actionLinksTemp.push({
      text: text,
      fontAwesomeIcon: icon,
      confirmationMessage: confirmationMessage,
      onClick: () => this.onSaveAndClose(onSubmitClicked),
    });
    this.addActionLinks(actionLinksTemp);
  }

  addCancelButton(
    onCancelClicked?: () => void,
    linkText?: string,
    fontAwesomeIcon?: string,
  ) {
    var actionLinksTemp: ActionLink[] = [];
    var text = linkText != undefined ? linkText : "Cancel";
    var icon = fontAwesomeIcon != undefined ? fontAwesomeIcon : "ban";
    if (onCancelClicked !== undefined) {
      actionLinksTemp.push({
        text: text,
        fontAwesomeIcon: icon,
        onClick: onCancelClicked,
      });
    } else {
      actionLinksTemp.push({
        text: text,
        fontAwesomeIcon: icon,
        onClick: () => this.navigateBack(),
      });
    }
    this.addActionLinks(actionLinksTemp);
  }

  addBackButton(form?: NgForm) {
    var actionLinksTemp: ActionLink[] = [];
    var text = "Back";
    var icon = "arrow-left";
    actionLinksTemp.push({
      text: text,
      fontAwesomeIcon: icon,
      onClick: () => {
        if (form != null) {
          form.form.markAsPristine();
        }
        this.navigateBack();
      },
    });
    this.addActionLinks(actionLinksTemp);
  }

  addDeleteButton(
    confirmationMessage: string,
    caller: any,
    deleteMethod: string,
    linkText: string = "Delete",
  ) {
    var actionLinksTemp: ActionLink[] = [];
    this.actionLinks.forEach((o) => actionLinksTemp.push(o));
    actionLinksTemp.push({
      text: linkText,
      fontAwesomeIcon: "trash",
      reallyClickMessage: confirmationMessage,
      reallyClickAction: deleteMethod,
      reallyClickCaller: caller,
    });
    this.setActionLinks(actionLinksTemp);
  }

  private onSaveAndClose(onSaveClicked: () => Promise<Data.CommandResponse>) {
    var promise = onSaveClicked();
    promise.then((response) => {
      if (response.Succeeded) {
        this.navigateBack();
      }
    });
    return promise;
  }

  private onDelete = (onDeleteClicked: () => Promise<Data.CommandResponse>) => {
    var promise = onDeleteClicked();
    promise.then((response) => {
      if (response.Succeeded) {
        this.navigateBack();
      }
    });
  };

  public navigateBack() {
    this.location.back();
  }

  private fixActionLinkHrefs() {
    this.actionLinks.forEach(
      (o) => (o.href = o.href !== undefined ? o.href : ""),
    );
  }

  public get hasActionLinks(): boolean {
    var result = this.actionLinks && this.actionLinks.length > 0;
    return result;
  }
}

export interface ActionLink {
  text: string;
  fontAwesomeIcon: string;
  href?: string;
  onClick?: () => void;
  confirmationMessage?: String;
  reallyClickMessage?: string;
  reallyClickAction?: string;
  reallyClickCaller?: any;
  reallyClickArgs?: any[];
  reallyClickButtons?: CustomReallyClickButton[];
}
