import { Component } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { PageContainerService } from "../../../services/page-container.service";
import { ControllerForViewBase } from "../../framework/controller-for-view-base";
import { Location } from "@angular/common";
import {
  ChangePasswordCommandRequest,
  GetUserCommandResponse,
  UserDto,
} from "../../../data/CommandServiceDtos.generated";

@Component({
  selector: "change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
})
export class ChangePasswordComponent extends ControllerForViewBase {
  model: UserDto;
  newPassword: string;
  confirmNewPassword: string;
  strength: Number;
  constructor(
    private activatedRoute: ActivatedRoute,
    public pcs: PageContainerService,
    private location: Location,
  ) {
    super(pcs);
    this.pcs.actionLinksPanelService.addBackButton();
  }

  async ngOnInit() {
    let userId: number;
    this.activatedRoute.params.subscribe((params: Params) => {
      userId = +params["id"];
      this.load(userId);
    });
    if (userId == 0) this.location.back();
  }

  async load(id: number) {
    let getCommandResponse = await this.pcs.apiService.GetUser({ Id: id });
    this.mapResponseToModel(getCommandResponse);
  }

  mapResponseToModel(getCommandResponse: GetUserCommandResponse): void {
    this.model = getCommandResponse.UserDto;
  }

  changePassword() {
    if (this.newPassword.length > 0 && this.confirmNewPassword.length > 0) {
      if (this.strength < 100) {
        this.pcs.notificationService.warning(
          "Password strength is not strong enough",
        );
      } else if (this.newPassword == this.confirmNewPassword) {
        var request: ChangePasswordCommandRequest = {
          NewPassword: this.newPassword,
          UserId: this.model.Id,
        };
        this.pcs.apiService.ChangePassword(request, true, true);
      } else this.pcs.notificationService.warning("Passwords must match");
    } else
      this.pcs.notificationService.warning("Both fields must contain a value");
  }

  onStrengthChanged(strength) {
    this.strength = strength;
  }
}
