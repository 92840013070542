import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "spk-card",
  templateUrl: "./spk-card.component.html",
  styleUrls: ["./spk-card.component.scss"],
})
export class SpkCardComponent implements OnInit {
  @Input() class: string;
  @Input() meta: string;
  @Input() title: string;
  @Input() divider: boolean;
  constructor() {}

  ngOnInit() {}
}
