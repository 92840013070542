import { Component, OnInit } from "@angular/core";
import {
  DateDto,
  ValueTextDto,
} from "../../../data/CommandServiceDtos.generated";
import { JsonPipe } from "@angular/common";

@Component({
  selector: "spk-input-examples",
  templateUrl: "./spk-input-examples.component.html",
  styleUrls: ["./spk-input-examples.component.scss"],
})
export class SpkInputExamplesComponent implements OnInit {
  constructor(public jPipe: JsonPipe) {}

  ngOnInit() {}

  //checkbox
  checkbox: boolean = false;
  checkboxLabel: string = "checkbox label";
  checkboxClass: string;
  checkboxWrappedClass: string;
  checkboxName: string = "checkbox name";
  checkboxHelp: string;
  checkboxWrapped: boolean = true;
  checkboxDisabled: boolean = false;

  //currency
  currency: number = 0;
  currencyLabel: string = "currency label";
  currencyWrapped: boolean = true;
  currencyClass: string;
  currencyWrappedClass: string;
  currencyName: string = "currency name";
  currencyPlaceholder: string = "currency placeholder";
  currencyHelp: string;
  currencyDisabled: boolean = false;
  currencyNullable: boolean = false;

  //date
  date: DateDto = {
    Day: 1,
    Month: 1,
    Year: 2020,
  };
  dateLabel: string = "date label";
  dateWrapped: boolean = true;
  dateClass: string;
  dateWrappedClass: string;
  dateName: string = "datename";
  datePlaceholder: string = "date placeholder";
  dateHelp: string;
  dateDisabled: boolean = false;
  dateOrientation: string = "auto";
  showDate: boolean = true;

  //number
  number: number = 0;
  numberLabel: string = "number label";
  numberWrapped: boolean = true;
  numberClass: string;
  numberWrappedClass: string;
  numberName: string = "number name";
  numberPlaceholder: string = "number placeholder";
  numberHelp: string;
  numberDisabled: boolean = false;
  numberNullable: boolean = false;
  numberPartialNumbers: boolean = false;

  //password
  password: string;
  passwordLabel: string = "password label";
  passwordWrapped: boolean = true;
  passwordClass: string;
  passwordWrappedClass: string;
  passwordName: string = "password name";
  passwordPlaceholder: string = "password placeholder";
  passwordHelp: string;
  passwordDisabled: boolean = false;

  //percent
  percent: number = 0;
  percentLabel: string = "percent label";
  percentWrapped: boolean = true;
  percentClass: string;
  percentWrappedClass: string;
  percentName: string = "percent name";
  percentPlaceholder: string = "percent placeholder";
  percentHelp: string;
  percentDisabled: boolean = false;
  percentNullable: boolean = false;
  percentDecimalPlaces: number = 2;

  //quantity
  quantity: number = 0;
  quantityLabel: string = "quantity label";
  quantityWrapped: boolean = true;
  quantityWrappedClass: string;
  quantityName: string = "quantity name";
  quantityHelp: string;
  quantityDisabled: boolean = false;
  quantityNullable: boolean = false;
  quantityMin: number = 0;
  quantityMax: number = 100000;

  //select
  select: any;
  selectLabel: string = "select label";
  selectWrapped: boolean = true;
  selectClass: string;
  selectWrappedClass: string;
  selectDisabled: boolean = false;
  selectName: string = "select name";
  selectHelp: string;
  selectOptions: ValueTextDto[] = [
    {
      Text: "One",
      Value: 1,
    },
    {
      Text: "Two",
      Value: 2,
    },
    {
      Text: "Three",
      Value: 2,
    },
  ];
  selectNullable: boolean = false;
  selectNullableValue: string;
  selectOptionTextProperty: string = "Text";
  selectOptionValueProperty: string = "Value";

  //text
  text: string;
  textLabel: string = "text label";
  textWrapped: boolean = true;
  textClass: string;
  textWrappedClass: string;
  textName: string = "text name";
  textPlaceholder: string = "text placeholder";
  textHelp: string;
  textDisabled: boolean = false;
  textGroup: boolean = false;
  textGroupButtonText: string;
  textGroupButtonClass: string;
  textGroupButtonIcon: string;
  textGroupButtonDisabled: boolean = false;
  textMaxLength: number;

  //textarea
  textarea: string;
  textareaLabel: string = "text area label";
  textareaWrapped: boolean = true;
  textareaClass: string;
  textareaWrappedClass: string;
  textareaName: string = "text area name";
  textareaPlaceholder: string = "text area placeholder";
  textareaHelp: string;
  textareaDisabled: boolean = false;
  textareaMaxLength: number;
  textareaRows: number;
  textareaCols: number;

  reloadDate() {
    let that = this;
    this.showDate = false;
    setTimeout(() => {
      that.showDate = true;
    }, 100);
  }

  controlModelChange(controlName: string) {
    console.log(`${controlName} changed`);
  }

  controlBlur(controlName: string) {
    console.log(`${controlName} blurred`);
  }

  controlFocus(controlName: string) {
    console.log(`${controlName} focused`);
  }

  controlOnKeyPress(controlName: string, data: any) {
    console.log(
      `${controlName} keypress | data: ${this.jPipe.transform(data)}`,
    );
  }

  controlOnEnterKeyPress(controlName: string, data: any) {
    console.log(
      `${controlName} enterkeypress | data: ${this.jPipe.transform(data)}`,
    );
  }

  controlGroupButtonClick(controlName: string) {
    console.log(`${controlName} group button click`);
  }
}
