export class ValidationFeedbackUtility {
  getNamedFormElements(): Element[] {
    let namedFormElements: Element[] = [];
    let forms = document.getElementsByTagName("form");
    for (let i = 0; i < forms.length; i++) {
      let currentNameElements = forms[i].querySelectorAll("[name]");
      for (let n = 0; n < currentNameElements.length; n++)
        namedFormElements.push(currentNameElements[n]);
    }
    return namedFormElements;
  }

  addInvalidClassToMatchingMemberNames(memberNames: string[]): void {
    let namedFormElements: Element[] = this.getNamedFormElements();
    for (let m = 0; m < memberNames.length; m++) {
      const memberName = memberNames[m];
      for (let j = 0; j < namedFormElements.length; j++) {
        let currentElement = namedFormElements[j];
        if (currentElement.getAttribute("name") === memberName) {
          currentElement.classList.add("is-invalid");
        }
      }
    }
  }

  removeInvalidClassFromElements(): void {
    let elementsWithClassName = document.getElementsByClassName("is-invalid");
    for (let j = elementsWithClassName.length - 1; j >= 0; j--)
      elementsWithClassName[j].classList.remove("is-invalid");
  }
}
