import { Component, ViewChild, Input, OnInit } from "@angular/core";
import { ILink, IFileSearchRow } from "../../../../../data";
import { PageContainerService } from "../../../../../services";
import { FilterSpecification } from "../../../../../data/framework/searchObjects";
import {
  CommandNames,
  EditFileCommandRequest,
} from "../../../../../data/CommandServiceDtos.generated";
import { SpkFileEditModalComponent } from "../spk-file-edit-modal/spk-file-edit-modal.component";
import { SpkViewImageModalComponent } from "../spk-view-image-modal/spk-view-image-modal.component";
import * as $ from "jquery";
import { SpkSearchGridComponent } from "../spk-search-grid/spk-search-grid.component";

@Component({
  selector: "spk-files",
  templateUrl: "./spk-files.component.html",
  styleUrls: ["./spk-files.component.scss"],
})
export class SpkFilesComponent implements OnInit {
  @Input() path?: string;
  @Input() defaultSortProperty: string;
  @Input() list: string = "FileLinkSearchRow";
  @Input() useQueryString: boolean;
  @Input() filter: FilterSpecification;
  @Input() isLockbox: boolean = false; 
  leftLinks: ILink[] = [];
  @ViewChild("grid", { static: false }) public grid: SpkSearchGridComponent;
  @ViewChild("fileEditModal") fileEditModal: SpkFileEditModalComponent; 
  @ViewChild("viewImageModal") viewImageModal: SpkViewImageModalComponent;
 // fileEditModal: SpkFileEditModalComponent;
  //viewImageModal: SpkViewImageModalComponent;
  displayActionButtons: boolean;

  baseUrl = "";

  constructor(public pcs: PageContainerService) {}

  ngOnInit(): void {
    this.pcs.executeCommandService
      .executeCommand(CommandNames.GetBlobBaseUrl, {})
      .then((response) => {
        this.baseUrl = response.Payload.BaseUrl;
      });

    this.setLeftLinks(this.isLockbox);

  }

  async editDescription(row: IFileSearchRow): Promise<void> {
    let response = await this.pcs.apiService.GetFile(
      { Id: row.FileId },
      true,
      false,
    );
    this.fileEditModal.setModel(response);
    this.fileEditModal.show();
  }


  async viewImage(row: IFileSearchRow): Promise<void> {
    let response = await this.pcs.apiService.GetFile(
      { Id: row.FileId },
      true,
      false,
    );
    const path = this.path + response.DisplayName;
    this.viewImageModal.setModel(response);
    this.viewImageModal.setImagePath(path);
    this.viewImageModal.show();
  }


  downloadFile(row: IFileSearchRow): void {
    this.pcs.executeCommandService.executeFileEndpoint(
      "Download",
      { id: row.FileId, displayName: row.DisplayName },
      true,
      false,
    );
  }

  copyUrl(row: IFileSearchRow): void {
    var url = this.baseUrl + "/" + row.DisplayName;
    navigator.clipboard.writeText(url).then(() => {
      this.pcs.notificationService.success("URL Copied!");
    });
  }

  async deleteFileLink(row: IFileSearchRow): Promise<void> {
    await this.pcs.apiService.DeleteFileLink(
      { FileLinkId: row.FileLinkId },
      true,
      true,
    );
    this.grid.load();
  }

  async submitFileEdit(request: EditFileCommandRequest) {
    await this.pcs.apiService.EditFile(request, true, true);
    $("#spk-file-edit-modal").modal("hide");
    this.grid.load();
  }

  setLeftLinks(isLockbox: boolean) {
    if (!isLockbox) {
      this.leftLinks = [
        {
          text: "",
          action: (row) => this.downloadFile(row),
          title: "Download",
          icon: "download",
          class: "btn-primary",
        },
        {
          text: "",
          action: (row) => this.copyUrl(row),
          title: "Copy URL",
          icon: "copy",
          class: "btn-light",
        },
        {
          text: "",
          action: (row) => this.editDescription(row),
          title: "Edit Description",
          icon: "edit",
          class: "btn-light",
        },
        {
          text: "",
          action: (row) => this.deleteFileLink(row),
          reallyClickMessage: "Are you sure you want to remove the file?",
          reallyClickAction: "deleteFileLink",
          reallyClickCaller: this,
          title: "Remove",
          icon: "times",
          class: "btn-danger",
        },
      ];

    } else {
      this.leftLinks = [
        {
          text: "",
          action: (row) => this.viewImage(row),
          title: "View Image",
          icon: "eye",
          class: "btn-light",
        },
        {
          text: "",
          action: (row) => this.editDescription(row),
          title: "Edit Description",
          icon: "edit",
          class: "btn-light",
        },
        {
          text: "",
          action: (row) => this.deleteFileLink(row),
          reallyClickMessage: "Are you sure you want to remove the file?",
          reallyClickAction: "deleteFileLink",
          reallyClickCaller: this,
          title: "Remove",
          icon: "times",
          class: "btn-danger",
        },
      ];
    }
  }

}
