import { Component, OnInit, ViewChild } from "@angular/core";
import { ControllerForViewBase } from "../../../framework/controller-for-view-base";
import { PageContainerService } from "../../../../services";
import { ActivatedRoute, Params, NgForm } from "../../../../ng";
import {
  CountryDto,
  GetCountryCommandRequest,
  SaveCountryCommandRequest,
} from "../../../../data/CommandServiceDtos.generated";
import { CanComponentDeactivate } from "../../../../services/can-deactivate-guard.service";

@Component({
  selector: "country",
  templateUrl: "./country.component.html",
  styleUrls: ["./country.component.scss"],
})
export class CountryComponent
  extends ControllerForViewBase
  implements OnInit, CanComponentDeactivate
{
  public model: CountryDto = new CountryDto();
  @ViewChild("form", { static: true }) form: NgForm;

  constructor(
    public pcs: PageContainerService,
    public activatedRoute: ActivatedRoute,
  ) {
    super(pcs);
    this.pcs.actionLinksPanelService.addBackButton();
    this.pcs.actionLinksPanelService.addActionLinks([
      {
        text: "Save ",
        fontAwesomeIcon: "save",
        onClick: () => {
          this.submit();
        },
      },
    ]);
    this.pcs.actionLinksPanelService.addActionLinks([
      {
        text: "Save and Close",
        fontAwesomeIcon: "save",
        onClick: () => {
          this.submit(true);
        },
      },
    ]);
  }

  getActionText = () => (this.model.Id > 0 ? "Update" : "Create");

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.load(+params["id"]);
    });
  }

  async load(id: number) {
    var payload: GetCountryCommandRequest = {
      Id: id,
    };
    var response = await this.pcs.apiService.GetCountry(payload, true, false);
    this.model = response.Country;
  }

  async submit(navigateaway: boolean = false) {
    var payload: SaveCountryCommandRequest = {
      Country: this.model,
    };
    var response = await this.pcs.apiService.SaveCountry(payload, true, true);
    this.form.form.markAsPristine();
    this.updateUrlForNewEntity(["/country", response.Id]);
    if (navigateaway) {
      window.history.back();
    } else {
      this.load(response.Id);
    }
  }

  canDeactivate(): Promise<boolean> {
    var promise = Promise.resolve(true);
    if (this.form.dirty) return this.pcs.showUnsavedChangesDialog();
    return promise;
  }
}
