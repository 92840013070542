import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import { SpkSearchGridComponent } from "../spk-search-grid/spk-search-grid.component";
import { FilterSpecification } from "../../../../../data/framework/searchObjects";
import { ISearchGridFormat, ILink } from "../../../../../data";
import * as $ from "jquery";

@Component({
  selector: "spk-modal-lookup",
  templateUrl: "./spk-modal-lookup.component.html",
  styleUrls: ["./spk-modal-lookup.component.scss"],
})
export class SpkModalLookupComponent implements OnInit, AfterViewInit {
  @Input() id: string = "";
  @Input() list: string = "";
  @Input() title: string = "";
  @Input() sort: string = "";
  @Input() command: string = "";
  @Input() searchString: string = "";
  @Input() filter: FilterSpecification = new FilterSpecification();
  @Input() autoload: boolean = true;
  @Output() rowSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() closed: EventEmitter<boolean> = new EventEmitter<boolean>();
  highlightedIndex: number = -1;
  rowFormats: ISearchGridFormat[] = [];
  @ViewChild("searchGrid", { static: false })
  searchGrid: SpkSearchGridComponent;

  showing: boolean = false;

  public links: ILink[] = [];

  constructor() {}

  ngOnInit() {
    this.links.push({
      text: "",
      action: this.selectRow,
      icon: "hand-pointer-o",
    });
  }

  ngAfterViewInit() {}

  show() {
    if (!this.showing) {
      this.showing = true;
      this.animateShow();
      this.assignKeyBinds();
      this.searchGrid.searchInput.input.nativeElement.focus();
      this.searchGrid.searchString = ''; 
    }
  }

  animateShow() {
    $(`#${this.id}`).removeClass("animated slideOutUp");
    $(`#${this.id}`).addClass("animated slideInDown");
    $(`#${this.id}-backdrop`).removeClass("animated fadeOut");
    $(`#${this.id}-backdrop`).addClass("animated fadeIn");
  }

  animateHide() {
    $(`#${this.id}`).removeClass("animated slideInDown");
    $(`#${this.id}`).addClass("animated slideOutUp");
    $(`#${this.id}-backdrop`).removeClass("animated fadeIn");
    $(`#${this.id}-backdrop`).addClass("animated fadeOut");
  }

  selectRow = (row: any) => {
    this.rowSelected.emit(row);
    this.close();
  };

  public load() {
    this.searchGrid.load();
  }

  close() {
    this.closed.emit(true);
    this.showing = false;
    this.animateHide();
    this.unassignKeyBinds();
  }

  assignKeyBinds = () => {
    var that = this;
    $(document).keydown(function (event) {
      if (event.which == 27 && event.key == "Escape") {
        that.close();
        event.preventDefault();
        event.stopPropagation();
        return false;
      }
      if (event.which == 33 && event.key == "PageUp") {
        that.searchGrid.pageUp();
        this.highlightedIndex = -1;
        event.preventDefault();
        event.stopPropagation();
        return false;
      }
      if (event.which == 34 && event.key == "PageDown") {
        that.searchGrid.pageDown();
        this.highlightedIndex = -1;
        event.preventDefault();
        event.stopPropagation();
        return false;
      }
      if (event.which == 38 && event.key == "ArrowUp") {
        that.decrementHighlight();
        event.preventDefault();
        event.stopPropagation();
      }
      if (event.which == 40 && event.key == "ArrowDown") {
        that.incrementHighlight();
        event.preventDefault();
        event.stopPropagation();
        return false;
      }
      if (event.which == 13 && event.key == "Enter") {
        if (
          that.highlightedIndex > -1 &&
          that.searchGrid.searchTermHasBeenSearched
        ) {
          that.selectRow(
            that.searchGrid.pagedResult.Data[that.highlightedIndex],
          );
          event.preventDefault();
          event.stopPropagation();
          return false;
        }
      }
    });
  };

  unassignKeyBinds = () => {
    $(document).off("keydown");
  };

  async decrementHighlight() {
    if (this.highlightedIndex > -1) {
      this.highlightedIndex--;
      if (
        this.highlightedIndex == -1 &&
        this.searchGrid.pagedResult.PaginationInfo.PageIndex > 1
      ) {
        await this.searchGrid.pageUp();
        this.highlightedIndex = this.searchGrid.pagedResult.Data.length - 1;
      }
    }
    this.updateRowFormats();
  }

  async incrementHighlight() {
    if (this.highlightedIndex < this.searchGrid.pagedResult.Data.length - 1) {
      this.highlightedIndex++;
    } else {
      await this.searchGrid.pageDown();
      this.highlightedIndex = 0;
    }
    this.updateRowFormats();
  }

  updateRowFormats() {
    if (this.highlightedIndex > -1) {
      this.rowFormats = [
        {
          columnName: null,
          cssClass: "highlight",
          index: this.highlightedIndex,
          value: null,
        },
      ];
    } else {
      this.rowFormats = [];
    }
  }
}
