import { Component, OnInit } from "@angular/core";
import { ControllerForViewBase } from "../../framework/controller-for-view-base";
import { PageContainerService } from "../../../services";

@Component({
  selector: "notification-example",
  templateUrl: "./notification-example.component.html",
  styleUrls: ["./notification-example.component.scss"],
})
export class NotificationExampleComponent
  extends ControllerForViewBase
  implements OnInit
{
  messageList: string[] = [
    "first worked",
    "second message",
    "third message",
    "testing testing",
    "keep going!",
  ];
  constructor(public pcs: PageContainerService) {
    super(pcs);
  }

  Success = () => this.pcs.notificationService.success("Success worked");
  Danger = () => this.pcs.notificationService.danger("Danger worked");
  Warning = () => this.pcs.notificationService.warning("Warning worked");
  Info = () =>
    this.pcs.notificationService.info("Info worked", undefined, {
      timeOut: 0,
      closeButton: true,
    });
  OverrideDefaults = () => {
    this.pcs.notificationService.success("Success worked", undefined, {
      timeOut: 0,
      extendedTimeOut: 0,
      closeButton: true,
    });
    this.pcs.notificationService.info("Info worked", undefined, {
      timeOut: 0,
      extendedTimeOut: 0,
      closeButton: true,
    });
  };

  Multiple = () => this.pcs.notificationService.dangerList(this.messageList);
  SplitMultiple = () =>
    this.pcs.notificationService.infoList(this.messageList, true);
  MultipleWithOverrides = () =>
    this.pcs.notificationService.warningList(this.messageList, undefined, {
      timeOut: 30000,
      preventDuplicates: true,
      closeButton: true,
    });

  RemoveAll = () => this.pcs.notificationService.removeAll();
  RemovePersistent = () => this.pcs.notificationService.removePersistents();
}
