import { Component, OnInit } from "@angular/core";
import {
  GetExceptionCommandRequest,
  GetExceptionCommandResponse,
} from "../../../../data/CommandServiceDtos.generated";
import { ActivatedRoute, Params } from "../../../../ng";
import { PageContainerService } from "../../../../services";
import { ControllerForViewBase } from "../../controller-for-view-base";

@Component({
  selector: "error-log",
  templateUrl: "./error-log.component.html",
  styleUrls: ["./error-log.component.scss"],
})
export class ErrorLogComponent extends ControllerForViewBase {
  commandRequest: GetExceptionCommandRequest;
  commandResponse: GetExceptionCommandResponse;
  constructor(
    private activatedRoute: ActivatedRoute,
    pcs: PageContainerService,
  ) {
    super(pcs);
    this.commandRequest = new GetExceptionCommandRequest();
    this.commandResponse = new GetExceptionCommandResponse();
    this.pcs.actionLinksPanelService.addBackButton();
  }

  async ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.commandRequest.Id = +params["id"];
    });
    this.commandResponse = await this.pcs.apiService.GetException(
      this.commandRequest,
    );
  }

  get getUser(): string {
    return this.commandResponse.User || "N/A";
  }
}
