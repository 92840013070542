export class TimeUtility {
  static generateTimeList() {
    const timeList: string[] = [];
    const timeMarkers = ["AM", "PM"];
    for (const timeMarker of timeMarkers) {
      for (let i = 0; i < 12; i++) {
        let hours = 12 + i;
        if (hours > 12) hours -= 12;
        for (let j = 0; j < 4; j++) {
          const minutes = 15 * j;
          timeList.push(
            `${hours.toString().padStart(2, "0")}:${minutes
              .toString()
              .padStart(2, "0")} ${timeMarker}`,
          );
        }
      }
    }

    return timeList;
  }

  static generate24HTimeList() {
    const timeList: string[] = [];
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 4; j++) {
        const minutes = 15 * j;
        timeList.push(
          `${i.toString().padStart(2, "0")}:${minutes
            .toString()
            .padStart(2, "0")}`,
        );
      }
    }

    return timeList;
  }
}
