import { NgModule, RouterModule, Routes } from "./ng";
import {
  DataEntryExampleComponent,
  LoginComponent,
  UsersComponent,
  SearchTestsComponent,
  SearchGridFormatExampleComponent,
  SearchGridLinkDestinationExampleComponent,
  ExceptionExampleComponent,
  ErrorLogComponent,
  ErrorLogsComponent,
  ExamplesComponent,
  ExampleComponent,
  NotificationExampleComponent,
  CreateAccountComponent,
  ApplicationSettingComponent,
  ApplicationSettingsComponent,
  CheckpointComponent,
  CheckpointsComponent,
  RolesComponent,
  RoleComponent,
  CalendarEventComponent,
  NewsArticleComponent,
  UserComponent,
  UserWidgetSelectionComponent,
  DashboardComponent,
  AdminTypeLinksComponent,
  CountryComponent,
  CountriesComponent,
  QueuesComponent,
  UserFilesComponent,
  NotAuthorizedComponent,
  ImportCheckpointsComponent,
  SpkControlsExampleComponent,
  SpkFilesComponent,
  SearchGridComprehensiveExampleComponent,
  SpkLookupExampleComponent,
  SpkInputExamplesComponent,
  UploadFilesComponent,
  LockboxComponent,
  FileTypeComponent,
  FileTypesComponent,
  AdminSchedulerComponent,
  MessageTranscriptsComponent,
  ForgotPasswordComponent,
  ChangePasswordComponent,
  ChangeSecurityPinComponent,
  EventNotificationsComponent,
  CalendarEventsComponent,
  NewsArticlesComponent,
  ResourcesComponent,
  ResourceComponent,
  SubcategoryTypeComponent,
  SubcategoryTypesComponent,
} from "./components";

import { CanDeactivateGuard, CanActivateGuard } from "./services";
import { DeleteUserDataComponent } from "./components/account/delete-user-data/delete-user-data.component";
import { DeleteUserDataSuccessComponent } from "./components/account/delete-user-data-success/delete-user-data-success.component";
import { PendingUsersComponent } from "./components/security/users/pending-users/pending-users.component";

const anonymousRoutes: Routes = [
  { path: "login", component: LoginComponent },
  { path: "create-account", component: CreateAccountComponent },
  { path: "not-authorized", component: NotAuthorizedComponent },
  {
    path: "spk-controls-examples",
    component: SpkControlsExampleComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  { path: "spk-input-examples", component: SpkInputExamplesComponent },
  {
    path: "search-full-example",
    component: SearchGridComprehensiveExampleComponent,
  },
  { path: "spk-lookup-example", component: SpkLookupExampleComponent },
  { path: "examples", component: ExamplesComponent },
  {
    path: "example/:id",
    component: ExampleComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: "data",
    component: DataEntryExampleComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  { path: "search-grid-format", component: SearchGridFormatExampleComponent },
  {
    path: "search-grid-link/:id",
    component: SearchGridLinkDestinationExampleComponent,
  },
  { path: "search-tests", component: SearchTestsComponent },
  { path: "exception-example", component: ExceptionExampleComponent },
  { path: "notification-example", component: NotificationExampleComponent },
  { path: "forgot-password", component: ForgotPasswordComponent },
  { path: "delete-user-data", component: DeleteUserDataComponent },
  {
    path: "delete-user-data-success",
    component: DeleteUserDataSuccessComponent,
  },
];
const authRoutes: Routes = [
  {
    path: "",
    canActivate: [CanActivateGuard],
    children: [
      { path: "", component: DashboardComponent },
      { path: "dashboard", component: DashboardComponent },
      {
        path: "user-widget-selection",
        component: UserWidgetSelectionComponent,
        canDeactivate: [CanDeactivateGuard],
      },
      { path: "user-files", component: UserFilesComponent },
      { path: "users", component: UsersComponent },
      { path: "pending-users", component: PendingUsersComponent },
      { path: "user/:id", component: UserComponent },
      { path: "roles", component: RolesComponent },
      {
        path: "role/:id",
        component: RoleComponent,
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: "calendar-event/:id",
        component: CalendarEventComponent,
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: "news-article/:id",
        component: NewsArticleComponent,
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: "resource/:id",
        component: ResourceComponent,
        canDeactivate: [CanDeactivateGuard],
      },
      { path: "checkpoints", component: CheckpointsComponent },
      {
        path: "checkpoint/:id",
        component: CheckpointComponent,
        canDeactivate: [CanDeactivateGuard],
      },
      { path: "import-checkpoints", component: ImportCheckpointsComponent },
      // { path: 'admin-type-links', component: AdminTypeLinksComponent },
      { path: "calendar-events", component: CalendarEventsComponent },
      { path: "news-articles", component: NewsArticlesComponent },
      { path: "resources", component: ResourcesComponent },
      { path: "countries", component: CountriesComponent },
      {
        path: "country/:id",
        component: CountryComponent,
        canDeactivate: [CanDeactivateGuard],
      },
      { path: "error-logs", component: ErrorLogsComponent },
      { path: "error-log/:id", component: ErrorLogComponent },
      { path: "application-settings", component: ApplicationSettingsComponent },
      {
        path: "application-setting/:name",
        component: ApplicationSettingComponent,
      },
      { path: "queues", component: QueuesComponent },
      { path: "files", component: SpkFilesComponent },
      { path: "upload-files", component: UploadFilesComponent },
      { path: 'lockbox', component: LockboxComponent },
      { path: "file-type/:id", component: FileTypeComponent },
      { path: "file-types", component: FileTypesComponent },
      { path: "admin-scheduler", component: AdminSchedulerComponent },
      { path: "message-transcripts", component: MessageTranscriptsComponent },
      { path: "change-password/:id", component: ChangePasswordComponent },
      {
        path: "change-security-pin/:id",
        component: ChangeSecurityPinComponent,
      },
      { path: "event-notifications", component: EventNotificationsComponent },
      { path: "subcategory-types", component: SubcategoryTypesComponent },
      { path: "subcategory-type/:id", component: SubcategoryTypeComponent },
      { path: "**", component: DashboardComponent },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forRoot([...anonymousRoutes, ...authRoutes])],
  exports: [RouterModule],
})
export class AppRoutingModule {}
