import { Router } from "../ng";
import * as Data from "../data";
import { BusyService } from "../services/busy.service";
import { ClientSessionService } from "../services/client-session.service";
import { ValidationResultsService } from "../services/validation-results.service";
import { CommandResponseTyped } from "../data/framework/commandResponse";
import * as moment from "moment";
import * as _ from "lodash";
import { NotificationService } from "./notification.service";
import { HandledException, ValidationFeedbackUtility } from "../utilities";
import { DateDtoUtility } from "../utilities/dates/date-dto.utility";
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import {
  AuthenticationType,
  AuthenticationTypeDisplayMap,
} from "../data/CommandServiceDtos.generated";
import { ObjectUtility } from "../utilities/objects/object.utility";
import * as config from "../../app-config.json";

@Injectable()
export class ExecuteCommandService {
  validationFeedbackUtility: ValidationFeedbackUtility =
    new ValidationFeedbackUtility();
  httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    observe: "response" as "response",
  };
  constructor(
    private busyService: BusyService,
    private clientSessionService: ClientSessionService,
    private validationResultsService: ValidationResultsService,
    private router: Router,
    private http: HttpClient,
    private notificationService: NotificationService,
  ) {}

  executeCommand(
    commandName: String,
    payload: any,
    useBusyIndicator: boolean = true,
    showNotificationOnSuccess: boolean = false,
    removePersistentNotifications: boolean = true,
  ): Promise<any> {
    this.validationResultsService.reset();
    if (removePersistentNotifications) {
      this.notificationService.removePersistents();
    }
    this.validationFeedbackUtility.removeInvalidClassFromElements();
    let commandRequest = this.preparePayload(commandName, payload);
    let promise = this.callExecuteCommand(
      commandRequest,
      showNotificationOnSuccess,
    );
    if (useBusyIndicator) {
      this.incrementBusyCounter(promise);
    }
    this.prepareDecrementBusyCounter(promise, useBusyIndicator);

    return promise;
  }

  executeFileEndpoint(
    endpointName: string,
    payload: any,
    useBusyIndicator: boolean = true,
    showNotificationOnSuccess: boolean = false,
    removePersistentNotifications: boolean = true,
  ): Promise<any> {
    this.validationResultsService.reset();
    if (removePersistentNotifications) {
      this.notificationService.removePersistents();
    }
    this.validationFeedbackUtility.removeInvalidClassFromElements();
    let promise = this.callFileEndpoint(
      endpointName,
      payload,
      showNotificationOnSuccess,
    );
    if (useBusyIndicator) {
      this.incrementBusyCounter(promise);
    }
    this.prepareDecrementBusyCounter(promise, useBusyIndicator);
    return promise;
  }

  private preparePayload(commandName: String, payload: any) {
    let now = new Date();
    let result = {
      CommandName: commandName,
      PayloadAsJson: payload,
      TimeZoneOffsetFromGMT: moment(now).utcOffset(),
      Today: DateDtoUtility.today(),
      LogCommands: this.clientSessionService.userInfo.LogCommands,
    };
    return result;
  }

  private incrementBusyCounter(promise: Promise<any>) {
    this.busyService.increment(promise);
  }

  private handleHttpResponse(response: any) {
    if (
      response.status === 401 &&
      config.security.authenticationType ===
        AuthenticationTypeDisplayMap[AuthenticationType.AzureActiveDirectory]
    ) {
      this.clientSessionService.reset();
      var json = response.json();
      if (json.Message === "Not Authorized") {
        this.notificationService.danger(
          "You are unauthorized to access this function.",
        );
      } else if (json.Message === "Not Authenticated") {
        var encodedRedirect = encodeURIComponent(config.security.redirectUri);
        var guid = ObjectUtility.generateUUID();
        var redirectUri =
          "https://login.microsoftonline.com/336411f2-bdde-4ad6-bc95-f51feae5a2cf/oauth2/v2.0/authorize?response_type=code+id_token&redirect_uri=" +
          encodedRedirect +
          "&client_id=" +
          config.security.clientId +
          "&scope=openid+profile+email&response_mode=form_post&nonce=" +
          guid +
          "&state=redir%3D%252Flogin&sso_reload=true";
        window.location.href = redirectUri;
      }
    }
    if (response.status) {
      return this.createErrorResponse(response);
    } else {
      return response;
    }
  }

  private callFileEndpoint(
    endpointName: string,
    payload: any,
    showNotificationOnSuccess: boolean,
  ): Promise<any> {
    if (endpointName.toLowerCase().indexOf("download") !== -1) {
      let promise = this.downloadFile(endpointName, payload);
      return promise;
    } else if (endpointName.toLowerCase().indexOf("fileurl") !== -1) {
      var jsonPayload = JSON.stringify(payload);
      let promise = this.http
        .get(`File/${endpointName}?request=${jsonPayload}`)
        .toPromise<any>();
      return promise;
    } else {
      let promise = this.uploadFile(
        endpointName,
        payload,
        showNotificationOnSuccess,
      );
      return promise;
    }
  }

  private downloadFile(endpointName: string, payload: any): Promise<any> {
    var jsonPayload = JSON.stringify(payload);
    var promise = this.http
      .get(`File/${endpointName}?request=${jsonPayload}`, {
        responseType: "blob",
      })
      .toPromise<any>();
    promise.then(
      (response) => {
        let blob;
        if (response.blob) {
          blob = response.blob();
        } else {
          blob = response;
        }
        let blobUrl = window.URL.createObjectURL(blob);
        var link = document.getElementById("downloadLink");
        link.setAttribute("href", blobUrl);
        link.setAttribute("download", payload.displayName);
        link.click();
      },
      () => {
        this.notificationService.warning("File not found");
      },
    );
    return promise;
  }

  private uploadFile(
    endpointName: string,
    payload: any,
    showNotificationOnSuccess: boolean,
  ): Promise<any> {
    let formData: FormData = this.prepareFileFormData(payload);
    let promise = this.http
      .post("File/" + endpointName, formData)
      .toPromise()
      .then(
        (response) => this.handleHttpResponse(response),
        (response) => this.handleHttpResponse(response),
      )
      .then((response: CommandResponseTyped<object>) =>
        this.onExecuteCommandCompleted(
          endpointName,
          response,
          showNotificationOnSuccess,
        ),
      );
    return promise;
  }

  private prepareFileFormData(payload: any): FormData {
    const formData = new FormData();
    for (var propertyName in payload) {
      let propertyValue = payload[propertyName];
      if (propertyName === "files") {
        for (let key in propertyValue) {
          if (
            propertyValue.hasOwnProperty(key) &&
            propertyValue[key] instanceof File
          ) {
            formData.append(key, propertyValue[key]);
          }
        }
      } else {
        formData.append(propertyName, JSON.stringify(propertyValue));
      }
    }
    return formData;
  }

  private callExecuteCommand(
    commandRequest: any,
    showNotificationOnSuccess: boolean,
  ): Promise<any> {
    let promise = this.http
      .post("ExecuteCommand/" + commandRequest.CommandName, commandRequest)
      .toPromise()
      .then(
        (response) => this.handleHttpResponse(response),
        (response) => this.handleHttpResponse(response),
      )
      .then((response: CommandResponseTyped<object>) =>
        this.onExecuteCommandCompleted(
          commandRequest.CommandName,
          response,
          showNotificationOnSuccess,
        ),
      );
    return promise;
  }

  private createErrorResponse(response: HttpErrorResponse) {
    if (response.status === 401 || response.status === 403) {
      if (response.error.Message == "Not Authorized") {
        this.notificationService.danger(
          "You are unauthorized to access this function.",
        );
        this.router.navigate(["/not-authorized"], { replaceUrl: true });
        throw new HandledException(response.error.Message);
      } else if (response.error.Message == "Not Authenticated") {
        this.notificationService.danger("You are not logged in.", undefined, {
          timeOut: 10000,
          extendedTimeOut: 0,
          closeButton: true,
        });
        this.clientSessionService.reset();
        this.router.navigateByUrl(`/login`);
        throw new HandledException(response.error.Message);
      }
    } else if (response.status === 400) {
      return response.error;
    } else {
      let msg = `${response.status} - ${response.statusText}`;
      let errorResponse: Data.CommandResponse = {
        HasValidationResults: true,
        Message: msg,
        Payload: null,
        Succeeded: false,
        ValidationResults: [
          {
            ErrorMessage: msg,
            MemberNames: [],
          },
        ],
        Warnings: [],
      };
      return errorResponse;
    }
  }

  private prepareDecrementBusyCounter(
    promise: Promise<any>,
    useBusyIndicator: boolean,
  ) {
    if (useBusyIndicator) {
      promise.then(
        () => this.busyService.decrement(),
        () => this.busyService.decrement(),
      );
    }
  }

  protected onExecuteCommandCompleted(
    commandName: String,
    response: any,
    showNotificationOnSuccess: boolean,
    onCommandSucceeded?: (commandName: String, response: any) => void,
  ) {
    if (response && response.Warnings && response.Warnings.length > 0)
      this.notificationService.warningList(response.Warnings);
    if (response !== undefined && response !== null) {
      if (response.Succeeded === true) {
        this.onExecuteCommandSucceeded(showNotificationOnSuccess);
        if (onCommandSucceeded !== undefined) {
          onCommandSucceeded(commandName, response);
        }
      } else {
        this.onExecuteCommandFailed(response);
      }
    } else {
      this.onExecuteServiceCallFailed(commandName);
    }
    return response;
  }

  protected onExecuteCommandSucceeded(showNotificationOnSuccess: boolean) {
    if (showNotificationOnSuccess === true)
      this.notificationService.success("Succeeded!");
  }

  protected onExecuteCommandFailed(response: any) {
    if (response.HasValidationResults) {
      let messages: string[] = [];
      let memberNames: string[] = [];
      response.ValidationResults.forEach((o: Data.ValidationResult) => {
        messages.push(o.ErrorMessage);
        if (o.MemberNames !== undefined && o.MemberNames.length) {
          for (let i = 0; i < o.MemberNames.length; i++)
            if (o.MemberNames[i] !== undefined && o.MemberNames[i] !== "")
              memberNames.push(o.MemberNames[i]);
        }
      });
      this.validationFeedbackUtility.addInvalidClassToMatchingMemberNames(
        memberNames,
      );
      this.notificationService.dangerList(messages);
    } else if (response.Message !== undefined && response.Message !== null) {
      this.notificationService.danger(response.Message);
    }
    let validationResultsMessages = _.map(
      response.ValidationResults,
      (value) => {
        return value.ErrorMessage;
      },
    );
    throw new HandledException(validationResultsMessages);
  }

  protected onExecuteServiceCallFailed(commandName: String) {
    let message = "A call to the server failed.  Please try again.";
    this.notificationService.danger(message);
    throw new HandledException((message += "  ---CommandName: " + commandName));
  }
}
