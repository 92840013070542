import { Component } from "@angular/core";
import { OnInit } from "../../../ng";
import { ReallyClickService, CustomReallyClickButton } from "../../../services";

@Component({
  selector: "really-click-modal",
  templateUrl: "./really-click-modal.component.html",
  styleUrls: ["./really-click-modal.component.scss"],
})
export class ReallyClickModalComponent implements OnInit {
  constructor(public reallyClickService: ReallyClickService) {}

  ngOnInit() {}

  get modalFade(): string {
    var result = "modal fade";
    if (this.reallyClickService.isVisible) {
      result = "modal fade in";
    }
    return result;
  }

  get modalClass(): string {
    var result = "modalHide";
    if (this.reallyClickService.isVisible) {
      result = "modalShow";
    }
    return result;
  }

  get backdropClass(): string {
    var result = "";
    if (this.reallyClickService.isVisible) {
      result = "backdrop";
    }
    return result;
  }
  invoke(button: CustomReallyClickButton) {
    this.reallyClickService.invoke(button);
  }
  accept() {
    this.reallyClickService.accept();
  }

  decline() {
    this.reallyClickService.decline();
  }
}
