import { Component } from "@angular/core";
import { ControllerForViewBase } from "../../framework/controller-for-view-base";
import { OnInit, ViewChild, Observable, NgForm } from "../../../ng";
import { PageContainerService } from "../../../services";
import { CanComponentDeactivate } from "../../../services/can-deactivate-guard.service";
import { AfterViewInit } from "@angular/core";
import * as $ from "jquery";
import "../../../assets/third-party/bootstrap-datepicker.min.js";
import { DateDto } from "../../../data/CommandServiceDtos.generated";

@Component({
  selector: "data-entry-example",
  templateUrl: "./data-entry-example.component.html",
  styleUrls: ["./data-entry-example.component.scss"],
})
export class DataEntryExampleComponent
  extends ControllerForViewBase
  implements OnInit, AfterViewInit, CanComponentDeactivate
{
  @ViewChild("participantForm", { static: false }) participantForm: NgForm;

  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  date: DateDto = {
    Day: 2,
    Month: 1,
    Year: 2001,
  };
  minDate: DateDto = {
    Day: 1,
    Month: 1,
    Year: 2001,
  };

  constructor(pcs: PageContainerService) {
    super(pcs);
  }

  ngAfterViewInit() {}

  ngOnInit() {
    $(`#datepickertest`).datepicker({
      orientation: "bottom auto",
      todayHighlight: true,
      autoclose: true,
    });
  }

  canDeactivate(): Promise<boolean> {
    var promise = Promise.resolve(true);
    if (this.participantForm.dirty) {
      return this.pcs.showUnsavedChangesDialog();
    }
    return promise;
  }
}
