export class ColumnEditInformation {
  ColumnName: String;
  IsReadOnly: boolean;
  DisplayName: String;
}
export class ColumnInformation {
  ColumnName: String;
  SortName: String;
  DisplayName: String;
  Format: String;
}
export class SearchCommandRequest {
  SearchType: String;
  SearchCriteria: SearchCriteria;
}

export class SearchCommandResponse<T> {
  PagedResult: IPagedResult<T>;
}

export interface IPagedResult<T> {
  PaginationInfo: PaginationSpecification;
  TotalCount: number;
  Data: T[];
}

//export interface IPagedResult {
//  PaginationInfo: PaginationSpecification;
//  TotalCount: Number;
//  Data: any[];
//}

export class SearchCriteria {
  SearchText: String;
  PaginationSpecification: PaginationSpecification;
  SortSpecification: SortSpecification;
  FilterSpecification: FilterSpecification;
}

export class PaginationSpecification {
  PageSize: number;
  PageIndex: number;
}

export class SortSpecification {
  SortString: String;
}

export class FilterSpecification {
  Parameters: FilterParameter[];
  Filters: FilterSpecification[];
  JoinType: LogicalOperator;
  static Filters: any[];
  static JoinType: any;
  static Parameters: {
    Comparison: any;
    DataType: any;
    FieldName: string;
    Filter: string;
  }[];
}

export class FilterParameter {
  FieldName: String;
  Filter: String | Number;
  Comparison: ComparisonOperator;
  DataType: FilterDataType;
}

export enum FilterDataType {
  String,
  Numeric,
  DateTime,
  DateTimeOffset,
  Boolean,
  Decimal,
  Guid,
}

export enum ComparisonOperator {
  NotSet,
  StartsWith,
  DoesNotStartWith,
  EndsWith,
  DoesNotEndWith,
  GreaterThan,
  GreaterThanEquals,
  LessThan,
  LessThanEquals,
  Contains,
  NotContains,
  Like,
  NotLike,
  Equals,
  NotEquals,
  IsNull,
  IsNotNull,
  In,
  NotIn,
}

export enum LogicalOperator {
  And,
  AndNot,
  Or,
  OrNot,
}
