import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "negativeParentheses",
})
export class NegativeParenthesesPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    let result = value;
    if (
      value &&
      value != undefined &&
      value != null &&
      value.toString().indexOf("-") != -1
    ) {
      let stringifiedValue: string = value.toString();
      result = "(" + stringifiedValue.replace("-", "") + ")";
    }
    return result;
  }
}
