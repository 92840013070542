import { Router, Observable } from "../ng";
import { ExecuteCommandService } from "./execute-command.service";
import { ValidationResultsService } from "./validation-results.service";
import { ActionLinksPanelService } from "./action-links-panel.service";
import { ClientSessionService } from "./client-session.service";
import { BusyService } from "./busy.service";
import { ApiService } from "../data/ApiService.generated";
import { UnsavedChangesDialogService } from "./unsaved-changes-dialog.service";
import { NotificationService } from "./notification.service";
import { SearchGridService } from "./search-grid.service";
import { TabService } from "./tab.service";
import { Injectable } from "@angular/core";
import { UserFileService } from "./user-file.service";
import { ReallyClickService } from "./really-click.service";

@Injectable()
export class PageContainerService {
  handleNavigationEnd() {
    this.validationResultsService.reset();
    this.notificationService.removePersistents();
    this.searchGridService.clearSubscribers();
  }

  constructor(
    public clientSessionService: ClientSessionService,
    public executeCommandService: ExecuteCommandService,
    public actionLinksPanelService: ActionLinksPanelService,
    public validationResultsService: ValidationResultsService,
    public busyService: BusyService,
    public router: Router,
    public apiService: ApiService,
    public unsavedChangesDialogService: UnsavedChangesDialogService,
    public notificationService: NotificationService,
    public searchGridService: SearchGridService,
    public tabService: TabService,
    public reallyClickService: ReallyClickService,
  ) {}

  showUnsavedChangesDialog(): Promise<boolean> {
    var promise = this.unsavedChangesDialogService.showDialog();
    return promise;
  }
}
