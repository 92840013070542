import { Component, ViewChild } from "@angular/core";
import { ControllerForViewBase } from "../../../framework/controller-for-view-base";
import { NgForm } from "@angular/forms";
import {
  GetUserCommandResponse,
  UserDto,
} from "../../../../data/CommandServiceDtos.generated";
import { ActivatedRoute, Params } from "../../../../ng";
import { PageContainerService } from "../../../../services";
import { CanComponentDeactivate } from "../../../../services/can-deactivate-guard.service";

@Component({
  selector: "user",
  templateUrl: "./user.component.html",
  styleUrls: ["./user.component.scss"],
})
export class UserComponent
  extends ControllerForViewBase
  implements CanComponentDeactivate
{
  @ViewChild("form", { static: true }) form: NgForm;
  model: UserDto = new UserDto();
  getActionText = () => "Modify";
  readOnly: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    public pcs: PageContainerService,
  ) {
    super(pcs);
  }

  async load(id: number) {
    let getCommandResponse = await this.pcs.apiService.GetUser({ Id: id });
    this.mapResponseToModel(getCommandResponse);
    this.pcs.actionLinksPanelService.reset();
    this.pcs.actionLinksPanelService.addBackButton();
    this.pcs.actionLinksPanelService.addActionLinks([
      {
        text: "Save ",
        fontAwesomeIcon: "save",
        onClick: () => {
          this.submit();
        },
      },
      {
        text: "Save and Close",
        fontAwesomeIcon: "save",
        onClick: () => {
          this.submit(true);
        },
      },
    ]);

    if (this.model.UserAccountTypeId == 1) {
      this.pcs.actionLinksPanelService.addActionLinks([
        {
          text: "Change Password",
          fontAwesomeIcon: "key",
          onClick: () => {
            this.navigateToChangePassword();
          },
        },
      ]);
    }
    if (this.model.UserAccountTypeId != 1) {
      this.pcs.actionLinksPanelService.addActionLinks([
        {
          text: "Change Security Pin",
          fontAwesomeIcon: "key",
          onClick: () => {
            this.navigateToChangeSecurityPin();
          },
        },
      ]);
    }
  }

  async ngOnInit() {
    let userId: number;
    this.activatedRoute.params.subscribe((params: Params) => {
      userId = +params["id"];
      this.load(userId);
    });
  }

  mapResponseToModel(getCommandResponse: GetUserCommandResponse): void {
    this.model = getCommandResponse.UserDto;
    if (this.model.UserAccountTypeId != 1) {
      this.readOnly = true;
    }
  }

  async submit(navigateaway: boolean = false): Promise<void> {
 //   this.model.PendingMobileUser = false;
    let saveResponse = await this.pcs.apiService.SaveUser(
      { UserDto: this.model },
      undefined,
      true,
    );
    if (saveResponse.Id !== 0) {
      this.form.form.markAsPristine();
      this.updateUrlForNewEntity(["/user", saveResponse.Id]);
      if (navigateaway) {
        window.history.back();
      } else {
        this.load(saveResponse.Id);
      }
    }
  }

  unCheckOthers(index: number) {
    this.model.UserRoleDtos.forEach((item, i) => {
      if (index == i) return;
      item.IsSelected = false;
    });
  }

  canDeactivate(): Promise<boolean> {
    var promise = Promise.resolve(true);
    if (this.form.dirty) return this.pcs.showUnsavedChangesDialog();
    return promise;
  }

  navigateToChangePassword() {
    if (this.model)
      if (this.model.Id > 0)
        this.pcs.router.navigate(["/change-password", this.model.Id]);
  }

  navigateToChangeSecurityPin() {
    if (this.model)
      if (this.model.Id > 0)
        this.pcs.router.navigate(["/change-security-pin", this.model.Id]);
  }
}
