import {
  Component,
  OnInit,
  ViewChild,
  Input,
  ElementRef,
  Output,
  EventEmitter,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { PageContainerService } from "../../../../../services";
import {
  GetFileTypesCommandRequest,
  LinkFileDto,
  UserDto,
  ValueTextDto,
} from "../../../../../data/CommandServiceDtos.generated";
import { FilterSpecification } from "../../../../../data/framework/searchObjects";
import { SpkFilesComponent } from "../spk-files/spk-files.component";
import { AutoLookupWithModalInputFormat } from "../../../auto-lookup-with-modal/auto-lookup-with-modal.component";

@Component({
  selector: "spk-file-upload",
  templateUrl: "./spk-file-upload.component.html",
  styleUrls: ["./spk-file-upload.component.scss"],
})
export class SpkFileUploadComponent implements OnInit {
  @Input() showList: boolean = true;
  @Input() defaultSortProperty: string;
  @Input() list: string = "FileLinkSearchRow";
  @Input() useQueryString: boolean = true;
  @Input() linkFileDto: LinkFileDto;
  @Input() filter: FilterSpecification;
  @ViewChild("form", { static: true }) form: NgForm;
  @ViewChild("fileInput", { static: false }) fileInput: ElementRef;
  userInputFormat: AutoLookupWithModalInputFormat[] = [
    {
      columnNameElement: null,
      stringLiteralElement: null,
      manualFormatter: function (selectedResult) {
        return selectedResult.FirstName + " " + selectedResult.LastName;
      },
    },
  ];

  @Output() fileUploaded: EventEmitter<any> = new EventEmitter<any>();

  userFilter: FilterSpecification = null;
  filesToUpload: File[] = [];
  fileTypeList: ValueTextDto[] = [];
  selectedFileTypeId: number;
  selectedUser: UserDto;

  constructor(
    public pcs: PageContainerService,
    private http: HttpClient,
  ) {}

  async ngOnInit() {
    let request: GetFileTypesCommandRequest = {};
    var response = await this.pcs.apiService.GetFileTypes(request, true, false);
    this.fileTypeList = response.FileTypeList;
  }

  handleFileInput(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      this.filesToUpload.push(files[i]);
    }
  }

  async uploadFile() {
    let response = await this.pcs.executeCommandService.executeFileEndpoint(
      "Upload",
      { files: this.filesToUpload, linkFileDto: this.linkFileDto },
      true,
      true,
    );
    this.filesToUpload = [];
    this.fileInput.nativeElement.value = null;
    if (response.Succeeded) {
      this.pcs.notificationService.success(
        "File uploaded successfully",
        undefined,
        { timeOut: 0, extendedTimeOut: 0, closeButton: true },
      );
    } else
      this.pcs.notificationService.danger(
        "There was a problem uploading this file",
        undefined,
        { timeOut: 0, extendedTimeOut: 0, closeButton: true },
      );
    this.fileUploaded.emit();
  }
}
