import { Pipe } from "@angular/core";

@Pipe({
  name: "stringSpacer",
})
export class StringSpacerPipe {
  transform(value: string, args) {
    var result = "";
    if (value != undefined && value != null) {
      value = value
        .replace(/-/g, " ")
        .split(" ")
        .map(function (p) {
          return p.charAt(0).toUpperCase() + p.slice(1);
        })
        .join(" ");
      value = value
        .split(/(?=[A-Z])/)
        .map(function (p) {
          return p.charAt(0).toUpperCase() + p.slice(1);
        })
        .join(" ");
      if (value.endsWith(" Id")) value = value.slice(0, result.length - 3);
      result = value;
    }
    return result;
  }
}
