import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  GetRoleCommandRequest,
  SaveNewsArticleCommandRequest,
  NewsArticleDTO,
  DateDto,
  LinkFileDto,
} from "../../../data/CommandServiceDtos.generated";
import { ControllerForViewBase } from "../../framework/controller-for-view-base";
import { PageContainerService } from "../../../services";
import { ActivatedRoute, Params } from "@angular/router";
import { CanComponentDeactivate } from "../../../services/can-deactivate-guard.service";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { NgForm } from "../../../ng";
import { DateDtoUtility } from "../../../utilities/dates/date-dto.utility";
import moment = require("moment");

@Component({
  selector: "article",
  templateUrl: "./article.component.html",
  styleUrls: ["./article.component.scss"],
})
export class NewsArticleComponent
  extends ControllerForViewBase
  implements OnInit, CanComponentDeactivate
{
  @ViewChild("form", { static: true }) form: NgForm;
  @ViewChild("fileInput", { static: false }) fileInput: ElementRef;
  model: NewsArticleDTO = new NewsArticleDTO();
  dateModel: DateDto;
  eventDateModel: DateDto;
  filesToUpload: File[] = [];
  tempFilesToUpload: File[] = [];
  imageUrl: SafeUrl = "";
  linkFileDto: LinkFileDto = {
    IsLockbox: false,
    LinkFileType: "9",
    LinkFileTypeKey: this.pcs.clientSessionService.userId,
    FileTypeId: 9,
    UserId: this.pcs.clientSessionService.userId,
    FileId: null,
    RecordRefId: null,
  };

  constructor(
    public pcs: PageContainerService,
    private sanitizer: DomSanitizer,
    public activatedRoute: ActivatedRoute,
  ) {
    super(pcs);
    this.pcs.actionLinksPanelService.addBackButton();
    this.pcs.actionLinksPanelService.addActionLinks([
      {
        text: "Save ",
        fontAwesomeIcon: "save",
        onClick: () => {
          this.submit();
        },
      },
    ]);
    this.pcs.actionLinksPanelService.addActionLinks([
      {
        text: "Save and Close",
        fontAwesomeIcon: "save",
        onClick: () => {
          this.submit(true);
        },
      },
    ]);
  }

  handleEventEditorLoad(e: any) {
    try {
      e?.editor?.setContent(this.model.Body);
    } catch (error) {
      setTimeout(() => {
        e?.editor?.setContent(this.model.Body);
      }, 1000);
    }
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.load(+params["id"]);
    });
  }

  async load(id: number) {
    var payload: GetRoleCommandRequest = {
      Id: id,
    };

    let response = await this.pcs.apiService.GetNewsArticle(
      payload,
      true,
      false,
    );
    this.model = response;
    if (id === 0) this.model.IsActive = true;

    this.dateModel = DateDtoUtility.createDateDtoFromMoment(
      moment(response.PublishDate),
    );

    if (this.model.Id > 0 && this.model.FileId > 0) {
      var image = await this.pcs.executeCommandService.executeFileEndpoint(
        "GetFileUrl",
        { id: this.model.FileId, displayName: "event" },
        true,
        false,
      );
      this.imageUrl = image.url;
    }
  }

  handleFileInput(files: FileList) {
    this.filesToUpload = [];
    for (let i = 0; i < files.length; i++) {
      this.filesToUpload.push(files[i]);
    }
    this.tempFilesToUpload = this.filesToUpload;
    this.uploadFile();
  }

  async uploadFile() {
    var response = await this.pcs.executeCommandService.executeFileEndpoint(
      "Upload",
      { files: this.filesToUpload, linkFileDto: this.linkFileDto },
      true,
      true,
    );
    var fileLinkId = response.Payload[0];
    var fileInfo = await this.pcs.apiService.CheckIfFileExists({
      LinkFileDto: null,
      FileLinkId: fileLinkId,
      FileTypeId: this.linkFileDto.FileTypeId,
    });

    this.model.FileId = fileInfo.FileId;
    this.imageUrl = fileInfo.ImageUrl;

    if (this.model.Id === 0) this.tempFilesToUpload = this.filesToUpload;
    this.filesToUpload = [];
    this.fileInput.nativeElement.value = null;
  }

  async submit(navigateaway: boolean = false): Promise<void> {
    this.setStartAndEndTimes();
    var payload: SaveNewsArticleCommandRequest = {
      Event: this.model,
    };
    let saveResponse = await this.pcs.apiService.SaveNewsArticle(
      payload,
      undefined,
      true,
    );
    if (saveResponse.Id !== 0) {
      this.form.form.markAsPristine();
      this.updateUrlForNewEntity(["/news-article", saveResponse.Id]);
      if (navigateaway) {
        window.history.back();
      } else {
        this.load(saveResponse.Id);
      }
    }
  }

  canDeactivate(): Promise<boolean> {
    var promise = Promise.resolve(true);
    if (this.form.dirty) {
      return this.pcs.showUnsavedChangesDialog();
    }
    return promise;
  }

  setStartAndEndTimes() {
    if (this.dateModel) {
      var day = this.dateModel.Day;
      var month = this.dateModel.Month;
      var year = this.dateModel.Year;
      this.model.PublishDate = new Date(Date.UTC(year, month - 1, day));
    }
  }
}
