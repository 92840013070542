import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  ElementRef,
  AfterViewInit,
  OnChanges,
} from "@angular/core";
import { PercentPipe } from "@angular/common";
import { isNull, isNullOrUndefined } from "util";

@Component({
  selector: "spk-percent",
  templateUrl: "./spk-percent.component.html",
  styleUrls: ["./spk-percent.component.scss"],
})
export class SpkPercentComponent implements AfterViewInit, OnChanges {
  @Input() model: number;
  @Input() disabled: boolean;
  @Input() maxlength: number;
  @Input() wrapped: boolean = true;
  @Input() class: string;
  @Input() wrappedClass: string;
  @Input() placeholder: string;
  @Input() label: string;
  @Input() name: string;
  @Input() nullable: boolean = true;
  @Input() decimalPlaces: number = 2;
  @Input() help: string;
  display: string;

  @Output() modelChange: EventEmitter<number> = new EventEmitter<number>();
  @ViewChild("input", { static: false }) input: ElementRef;

  private validKeys: number[] = [
    48, //0
    49, //1
    50, //2
    51, //3
    52, //4
    53, //5
    54, //6
    55, //7
    56, //8
    57, //9
    96, //0
    97, //1
    98, //2
    99, //3
    100, //4
    101, //5
    102, //6
    103, //7
    104, //8
    105, //9
    110, //.
    190, //.
    189, //-
    109, //-
    35, //end
    36, //home
    37, //left
    39, //right
    46, //delete
    8, //backspace
    9, //tab
  ];

  constructor(
    private cdr: ChangeDetectorRef,
    private percentPipe: PercentPipe,
  ) {}

  ngAfterViewInit() {
    this.input.nativeElement.onkeydown = (evt) => this.keyDown(evt);
  }

  ngOnChanges() {
    if (this.model != null && this.model != undefined) {
      this.formatDisplay();
    } else {
      this.display = "";
    }
  }

  formatDisplay() {
    let result: string = "";
    if (isNaN(this.model)) {
      if (this.nullable) {
        this.model = null;
      } else {
        this.model = 0;
      }
    } else if (this.model != null && this.model != undefined) {
      if (this.model < 0) result = "-";
      result += `${Math.abs(this.model)}`;
    }
    result = this.percentPipe.transform(
      result,
      `1.${this.decimalPlaces}-${this.decimalPlaces}`,
    );
    this.display = result;
    this.cdr.detectChanges();
  }

  parseDisplay() {
    var result = null;
    if (this.display) {
      var isNegative = this.display.indexOf("-") > -1;
      var numberString = this.display.replace(/[-,%]/g, "");
      result = isNegative
        ? parseFloat(`-${numberString}`)
        : parseFloat(`${numberString}`);
      //this.formatDisplay();
    } else {
      if (this.nullable) {
        result = null;
      } else {
        result = 0;
      }
    }
    if (result != null) {
      result = result / 100;
    }
    this.model = result;
    this.modelChange.emit(this.model);
  }

  displayChanged(value: string) {
    //this.parseDisplay();
  }

  keyDown(value) {
    let isValidKeyCode: boolean = false;
    for (let i = 0; i < this.validKeys.length; i++) {
      if (value.keyCode == this.validKeys[i]) {
        isValidKeyCode = true;
        break;
      }
    }
    return isValidKeyCode;
  }

  onFocus() {
    if (!isNullOrUndefined(this.display)) {
      this.display = this.display.replace("%", "");
    }
  }

  onBlur() {
    this.parseDisplay();
    this.formatDisplay();
  }
}
