import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import * as $ from "jquery";
import {
  EditFileCommandRequest,
  GetFileCommandResponse,
} from "../../../../../data/CommandServiceDtos.generated";

@Component({
  selector: "spk-view-image-modal",
  templateUrl: "./spk-view-image-modal.component.html",
  styleUrls: ["./spk-view-image-modal.component.scss"],
})
export class SpkViewImageModalComponent implements OnInit {
  title: string;
  path: string;
  model: EditFileCommandRequest = new EditFileCommandRequest();
 // @Output() submitEmitter: EventEmitter<EditFileCommandRequest> =
  //  new EventEmitter<EditFileCommandRequest>();

  constructor() { }

  ngOnInit() { }

  setModel(response: GetFileCommandResponse) {
    this.model.FileId = response.Id;
    this.title = this.model.DisplayName = response.DisplayName;
    this.model.Description = response.Description;
  }

  setImagePath(path: string) {
    this.path = path;
  }

  show() {
    $("#spk-view-image-modal").modal("show");
  }

  submit() {
 //   this.submitEmitter.emit(this.model);
  }
}
