import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  Optional,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";
import { ControlContainerFactory } from "../../../../../factories/control-container.factory";

@Component({
  selector: "spk-text",
  templateUrl: "./spk-text.component.html",
  styleUrls: ["./spk-text.component.scss"],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: ControlContainerFactory,
      deps: [[new Optional(), NgForm]],
    },
  ],
})
export class SpkTextComponent implements AfterViewInit {
  @Input() model: string;
  @Input() wrapped: boolean = true;
  @Input() group: boolean;
  @Input() groupButtonText: string;
  @Input() groupButtonClass: string = "btn-outline-primary";
  @Input() groupButtonIcon: string;
  @Input() groupButtonDisabled: boolean;
  @Input() label: string;
  @Input() name: string;
  @Input() disabled: boolean;
  @Input() class: string;
  @Input() wrappedClass: string;
  @Input() maxlength: number;
  @Input() placeholder: string;
  @Input() uppercase: boolean;
  @Input() autoComplete: string = "off";
  @Input() noId: boolean = false;
  @Input() help: string;
  @Output() modelChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() onkeypress: EventEmitter<any> = new EventEmitter<any>();
  @Output() onEnterKeypress: EventEmitter<any> = new EventEmitter<any>();
  @Output() focus: EventEmitter<any> = new EventEmitter<any>();
  @Output() groupButtonClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() blur: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild("input", { static: false }) input: ElementRef;

  constructor() {}

  modelChanged() {
    this.modelChange.emit(this.model);
  }

  ngAfterViewInit() {
    this.input.nativeElement.onkeypress = (evt) => this.keyPressed(evt);
  }

  keyPressed($event) {
    if (this.onkeypress) this.onkeypress.emit($event);
    if (this.onEnterKeypress && $event.keyCode === 13)
      this.onEnterKeypress.emit($event);
  }

  focused($event) {
    this.focus.emit($event);
  }

  lostFocus($event) {
    if (
      this.model != null &&
      this.model != undefined &&
      this.uppercase === true
    ) {
      this.model = this.model.toUpperCase();
      this.modelChange.emit(this.model);
    }
    this.blur.emit($event);
  }

  groupButtonClicked($event) {
    this.groupButtonClick.emit($event);
  }
}
