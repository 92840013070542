import { Component, ViewChild } from "@angular/core";
import { CanComponentDeactivate } from "../../../services/can-deactivate-guard.service";
import { PageContainerService } from "../../../services";
import { NgForm } from "../../../ng";
import {
  DateDto,
  ValueTextDto,
  ValueTextSelectedDto,
  ExampleSearchRow,
} from "../../../data/CommandServiceDtos.generated";
import { ControllerForTabBase } from "../../framework/controller-for-tab-base";
import { SpkLookupFormat } from "../../framework/spk/controls/spk-lookup/spk-lookup.component";

@Component({
  selector: "spk-controls-example",
  templateUrl: "./spk-controls-example.component.html",
  styleUrls: ["./spk-controls-example.component.scss"],
})
export class SpkControlsExampleComponent
  extends ControllerForTabBase
  implements CanComponentDeactivate
{
  @ViewChild("form", { static: true }) form: NgForm;

  someModel: SomeModel = new SomeModel();
  someOptions: ValueTextDto[] = [
    { Text: "option 1", Value: 62 },
    { Text: "option 2", Value: 97 },
    { Text: "option 3", Value: 34 },
    { Text: "option 4", Value: 167 },
  ];
  someValueTextSelectionItems: ValueTextSelectedDto[] = [
    { Text: "value 398- is this checked", Value: 398, Selected: true },
    { Text: "value 5525- this isnt checked", Value: 5525, Selected: false },
    { Text: "value 12- should be checked", Value: 12, Selected: true },
    { Text: "value 7865- NOT", Value: 7865, Selected: false },
    { Text: "value 672- woot", Value: 672, Selected: true },
  ];
  lookupSelectedResult: ExampleSearchRow;
  inputFormat: SpkLookupFormat[] = [
    {
      columnNameElement: null,
      stringLiteralElement: "Display Text: ",
    },
    {
      columnNameElement: "DisplayText",
      stringLiteralElement: null,
    },
    {
      columnNameElement: null,
      stringLiteralElement: " | Value: ",
    },
    {
      columnNameElement: "Value",
      stringLiteralElement: null,
    },
  ];

  constructor(public pcs: PageContainerService) {
    super(pcs);
    this.pcs.tabService.findAndSelectTab("inputs");
  }

  canDeactivate(): Promise<boolean> {
    var promise = Promise.resolve(true);
    if (this.form.dirty) return this.pcs.showUnsavedChangesDialog();
    return promise;
  }
}

class SomeModel {
  someString: string = "test";
  somePasswordString: string = "testPassword";
  someNumber: number = 21.1;
  someOtherNumber: number = 5;
  somePercentFactor: number = 0.15;
  someDateDto: DateDto = { Day: 21, Month: 3, Year: 2020 };
  someBoolean: boolean = true;
  someSelectionId: number = 97;
}
